import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

class EnUserMenu extends Component {
    static displayName = EnUserMenu.name;

    render() {
        const { location } = this.props;
        const pathname = location ? location.pathname : ''; // Handling if location is undefined

        const userData = JSON.parse(sessionStorage.getItem('userData'));
        const userLevel = userData ? userData.rewardLevel : null;
        var css01 = "";
        if (userLevel === "Green") {
            css01 = "profileBanner__links green-bl mt-2";
        }
        else {
            css01 = "profileBanner__links gold-bl mt-2";
        }

        return (
            <div className="container">
                <div className="col-topbar-profile">
                    <div className="profileBanner__container">
                        <ul className={css01}>
                            {userLevel === "Green" && (
                                <li><Link to="/userdashboard" className={pathname === '/userdashboard' ? 'profileBanner--activeLink' : ''}>Dashboard</Link></li>
                            )}
                            {userLevel === "Gold" && (
                                <li><Link to="/userdashboardgold" className={pathname === '/userdashboardgold' ? 'profileBanner--activeLink' : ''}>Dashboard</Link></li>
                            )}

                            <li><Link to="/userstartransaction" className={pathname === '/userstartransaction' ? 'profileBanner--activeLink' : ''}>Your Stars And Rewards History</Link></li>

                            <li><Link to="/usercard" className={pathname.startsWith('/usercard') ? 'profileBanner--activeLink' : ''}>Cards</Link></li>

                            {userLevel === "Green" && (
                                <li><Link to="/userreward" className={pathname === '/userreward' ? 'profileBanner--activeLink' : ''}>Rewards</Link></li>
                            )}
                            {userLevel === "Gold" && (
                                <li><Link to="/userrewardgold" className={pathname === '/userrewardgold' ? 'profileBanner--activeLink' : ''}>Rewards</Link></li>
                            )}

                            <li><Link to="/userprofile" className={pathname.startsWith('/userprofile') ? 'profileBanner--activeLink' : ''}>My Profile</Link></li>
                            <li><Link to="/logout">Sign Out</Link></li>
                        </ul>
                    </div>
                    <div className="msr3_logo msr3_usen_logo" />
                </div>
            </div>
        );
    }
}

export default withRouter(EnUserMenu); // Wrapping with withRouter
