import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { ThNavHeader } from './ThNavHeader';
import { ThNavFooter } from './ThNavFooter';
import ThUserMenu from './ThUserMenu';

import { getClientId } from './utils/analyticsclientIdUtils';
import { encryptData } from './utils/encryptionUtils';
import { API_URL } from './utils/constants'

class ThUserCardTransferClass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: null, // Initialize userData state to null
            parentState: this.props.location.state,
            canSubmitState: true,
            canSubmitMsg: '',
            submitMsgSuccess: '',
            cardList: [],
            cardListRemoveSelected: [],
            amount: 0,
            cardNumber1: this.props.location.state.selectedCard.cardNumber,
            cardNumber2: '',
            selectedCard2: null,
            requireMsg01: ''
        };
    }

    componentDidMount() {
        // Retrieve data from session storage
        const userDataFromStorage = sessionStorage.getItem('userData');

        // Parse the data if it exists and set it to the component state
        if (userDataFromStorage) {
            this.setState({ userData: JSON.parse(userDataFromStorage) });

            const selectedCard = this.state.parentState.selectedCard;
            const cardList = this.state.parentState.cardList;
            if (cardList && Array.isArray(cardList)) {
                const filtered = cardList.filter(item => item.cardNumber !== selectedCard.cardNumber);
                this.setState({ cardListRemoveSelected: filtered, cardNumber2: filtered[0].cardNumber });
            }
        }

        {
            // Google analytic
            const documentPath = "TH Card Transfer"; // Gets the current URL path
            const clientId = getClientId(); // Use the getClientId function

            // Push data to the GTM dataLayer
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'pageview',
                page: documentPath,
                clientId: clientId,
            });
        }
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.cardNumber2 !== this.state.cardNumber2) {
            this.callApiCardBanalce(this.state.cardNumber2, this.state.userData.loyaltyMemberId, "th");
        }
    }

    async callApiCardBanalce(cardNumber, userId, RefLang) {
        // Generate Unix timestamp
        const timestamp = Math.floor(Date.now() / 1000);

        const payload = { Cardno: cardNumber, RefLang: RefLang, UserId: userId, Timestamp: timestamp };
        const encryptedPayload = encryptData(payload);

        const token = this.state.userData.token;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ Data: encryptedPayload })
        };

        try {
            // Add the wait cursor class to the body element
            document.body.classList.add('wait-cursor');

            const response = await fetch(`${API_URL}/api/CheckCardBalance`, requestOptions);
            const data = await response.json();
            if (data) {
                this.setState({
                    selectedCard2: data
                });
            }
        } catch (error) {
            console.error('API call error:', error);
        }
        finally {
            document.body.classList.remove('wait-cursor'); // Remove the wait cursor class
        }
    }

    async callApiCardTransfer(loyaltyMemberId, cardNumber1, cardNumber2, amount, refLang) {
        if (!amount || amount === "") {
            this.setState({ requireMsg01: 'Enter amount.' });
            return;
        }

        // Generate Unix timestamp
        const timestamp = Math.floor(Date.now() / 1000);

        const payload = { UserId: loyaltyMemberId, Cardno: cardNumber1, Cardno2: cardNumber2, Amount: amount, RefLang: refLang, Timestamp: timestamp };
        const encryptedPayload = encryptData(payload);

        const token = this.state.userData.token;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ Data: encryptedPayload })
        };

        try {
            // Add the wait cursor class to the body element
            document.body.classList.add('wait-cursor');

            const response = await fetch(`${API_URL}/api/ActionCardTransfer`, requestOptions);
            const data = await response.json();
            if (data.returnCode === '00') {
                this.setState({
                    canSubmitState: false,
                    submitMsgSuccess: 'โอนเงินระหว่างบัตรสำเร็จ'
                });
            }
            else {
                //some error here
                this.setState({
                    canSubmitState: true,
                    canSubmitMsg: data.statusMsg
                });
            }
        } catch (error) {
            console.error('API call error:', error);
        }
        finally {
            document.body.classList.remove('wait-cursor'); // Remove the wait cursor class
        }
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleCardTransfer(card1, card2, amount) {
        const card1Balance = parseFloat(card1.cardBalance.replace(/,/g, ''));
        const card2Balance = parseFloat(card2.cardBalance.replace(/,/g, ''));
        let reloadAmount = parseFloat(amount);

        if (reloadAmount > card1Balance) {
            reloadAmount = card1Balance;
            this.setState({ amount: reloadAmount });
        }
        else {
            if (card1Balance + card2Balance + reloadAmount > 20000) {
                this.setState({
                    canSubmitMsg: 'Card balance will exceed ฿20,000!!'
                });
            }
            else {
                this.callApiCardTransfer(this.state.userData.loyaltyMemberId, card1.cardNumber, card2.cardNumber, reloadAmount, "th");
            }
        }
    }

    render() {
        const { userData, parentState, canSubmitState, canSubmitMsg, cardNumber2, amount, selectedCard2, cardListRemoveSelected, requireMsg01, submitMsgSuccess } = this.state;
        const selectedCard = parentState.selectedCard;
        const selectedCardFromlist = parentState.selectedCardFromlist;

        let selectedCardNumber, selectedcardBalance, selectedCardNickName, selectedcardImg;
        if (selectedCard) {
            selectedcardBalance = selectedCard.cardBalance;
        }
        if (selectedCardFromlist) {
            selectedCardNumber = selectedCardFromlist.cardNumber;
            selectedCardNickName = selectedCardFromlist.cardNickName;
            selectedcardImg = selectedCardFromlist.cardImg;
        }

        return (
            <>
                <ThNavHeader />

                {/* Render the user data */}
                {userData && parentState && (
                    <main id="main">
                        <section id="profile_section" className="profileBanner keyboardFocus" aria-label="Profile" tabIndex={-1}>
                            <ThUserMenu />
                        </section>
                        <section className="section-dashboard overflow-hidden pb-5">
                            <div className="container mt-4">
                                <div className="row mb-5 px-2">
                                    <div className="col-md-8">
                                        <h2 className="h4 fw-semibold my-4">โอนเงินระหว่างบัตร</h2>
                                        {canSubmitState ? (
                                            <>
                                                <form className="form-sr">
                                                    <div className="row mb-4 g-3 align-items-center">
                                                        <div className="col-md-1">
                                                            <label className="col-form-label">From</label>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="seclect-cards">
                                                                <select name="options-cards" className="form-control select-cards-id">
                                                                    <option value={selectedCardNumber} data-src={`media/rewards/cards/${selectedcardImg}`}>
                                                                        {selectedCardNickName} | ({selectedCardNumber}) | ฿ {selectedcardBalance}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-4 g-3 align-items-center">
                                                        <div className="col-md-1">
                                                            <label className="col-form-label">to</label>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="seclect-cards">
                                                                <select name="options-cards" className="form-control select-cards-id" name="cardNumber2"
                                                                    value={cardNumber2}
                                                                    onChange={this.handleChange}>
                                                                    {cardListRemoveSelected && cardListRemoveSelected.length > 0 && (
                                                                        <>
                                                                            {cardListRemoveSelected.map((item, index) => (
                                                                                <option key={index} value={item.cardNumber} data-src={`media/rewards/cards/${item.cardImg}`}>
                                                                                    {item.cardNickName} | ({item.cardNumber})</option>
                                                                            ))}
                                                                        </>
                                                                    )}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-5 mb-1">
                                                            <label className="form-label">จำนวนเงินที่โอน</label>
                                                            <div className="input-group mb-0 ">
                                                                <span className="input-group-text fw-bold bg-white">฿</span>
                                                                <input type="number" min="10" max="20000" className="form-control ps-1 border-start-0" placeholder="กรุณากรอกจำนวนเงิน" name="amount" value={amount} onChange={this.handleChange} />
                                                            </div>
                                                        </div>
                                                        <div className="text-alert-2">
                                                            {requireMsg01}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-8">
                                                            <p>มูลค่าบัตรไม่ควรเกิน ฿20,000</p>
                                                        </div>
                                                    </div>
                                                    <div className="text-start mt-3">
                                                        <button type="button" className="btn btn-primary btn-slim rounded-pill text-decoration-none" onClick={() => this.handleCardTransfer(selectedCard, selectedCard2, amount)}>
                                                            โอนเงิน
                                                        </button>
                                                    </div>
                                                </form>
                                            </>
                                        ) : (
                                            <>
                                                <span className="text-green-1">{submitMsgSuccess}</span><span className="text-alert-2">{canSubmitMsg}</span>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>

                        </section>
                    </main>

                )}

                <ThNavFooter />
            </>
        );
    }
}

export const ThUserCardTransfer = withRouter(ThUserCardTransferClass);