import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { EnNavHeader } from './EnNavHeader';
import { EnNavFooter } from './EnNavFooter';
import { EnSharedComponentBenefit01 } from './EnSharedComponentBenefit01';

import { getClientId } from './utils/analyticsclientIdUtils';

export class EnHome extends Component {
    static displayName = EnHome.name;

    componentDidMount() {
        // Use jQuery to toggle password visibility
        window.$(".accordion-image img:eq(0)").nextAll().hide();
        window.$(".accordion350tab button").click(function (e) {
            var imageId = window.$(this).attr('data-image');
            window.$(".accordion-image #" + imageId).show().siblings().hide();
        });

        //Idea for encrypt data before call api
        //const secretKey = process.env.REACT_APP_SECRET_KEY;

        {
            // Google analytic
            const documentPath = "Home"; // Gets the current URL path
            const clientId = getClientId(); // Use the getClientId function

            // Push data to the GTM dataLayer
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'pageview',
                page: documentPath,
                clientId: clientId,
            });
        }
    }    

    render() {
        return (
            <>
                <EnNavHeader />

                <main id="main">
                    <section className="top-sr-bg bg-gray1">
                        <div className="container">
                            <div className="row justify-content-between">
                                <div className="col col-md-6">
                                    <h1 className="text-headpage text-white mb-0 pt-3 pb-3 "><span className="d-none">Starbucks &reg; Rewards</span><img src={require("./media/rewards/starbucks-rewards-logo-2.png")} alt="Starbucks&reg; Rewards" className="img-fluid" style={{ maxWidth: '158px' }} /></h1>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="cover-home overflow-hidden">
                        <div className="p-0 p-md-5 mb-0 mb-md-4 bg-star-home">
                            <div className="container py-0 py-md-5">
                                <h2 className="display-3 fw-bold mt-0 mt-md-5 pt-5 d-none d-md-block"><span className="text-style-head-1">REWARDS TO MAKE</span><br />
                                    <span className="text-style-head-2">YOUR DAY SHINE</span></h2>
                                <h2 className="display-5 fw-bold mt-0 mt-md-5 pt-5 d-block d-md-none"><span className="text-style-head-1">REWARDS TO MAKE</span><br />
                                    <span className="text-style-head-2">YOUR DAY SHINE</span></h2>
                                <p className="less-lead">Join now to start earning Rewards.</p>
                                <Link to="/authjoinnow" className="btn btn-primary rounded-pill text-decoration-none mb-3 btn-lg py-1">Join now</Link>
                                <p className="less-lead mb-0 mb-md-5 pb-5">Or<a href="https://www.starbuckscardth.in.th/cards/newhome" target="_blank" alt="Join now" rel="noopener noreferrer"> join in the app</a> for the best experience</p>
                            </div>
                        </div>
                    </section>

                    <EnSharedComponentBenefit01 />

                    <section className="section-start pb-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 mb-0 mb-md-5 pt-3 text-center">
                                    <h2 className="h3 mt-0 mt-md-5 fw-bold text-dark-212121">Getting started is easy</h2>
                                    <p className="mt-3 text-dark-212121">Earn Stars and get rewarded in a few easy steps.</p>
                                </div>
                            </div>
                            <div className="row gx-5 text-dark-000000">
                                <div className="col-sm-12 col-md-4 pb-4 text-center">
                                    <div className="mb-3"><img src={require("./media/rewards/img-box2-Getting-started-is-easy-01-600.png")} alt="Create an account" className="img-fluid" style={{ maxWidth: '150px' }} /></div>
                                    <h4 className="fs-5 fw-bold px-3">Create an account</h4>
                                    <p>To get started, join now. You can also join in the app to get access to the full range of Starbucks&reg; Rewards benefits.</p>
                                </div>
                                <div className="col-sm-12 col-md-4 pb-4 text-center">
                                    <div className="mb-3"><img src={require("./media/rewards/img-box2-Getting-started-is-easy-02-600.png")} alt="Collect Stars based on how much you spend. For every 25B, collect 1 Stars." className="img-fluid" style={{ maxWidth: '150px' }} /></div>
                                    <h4 className="fs-5 fw-bold px-3">Collect Stars based on how much you spend. For every 25B, collect 1 Stars</h4>
                                    <p>As you earn Stars, you can redeem them for Rewards-like upsize, free drink and more. Start redeeming with as little as 20 Stars!</p>
                                </div>
                                <div className="col-sm-12 col-md-4 pb-0 pb-md-4 text-center">
                                    <div className="mb-3"><img src={require("./media/rewards/img-box2-Getting-started-is-easy-03-600.png")} alt="Get the best of Starbucks&reg; Rewards right at your fingertips." className="img-fluid" style={{ maxWidth: '150px' }} /></div>
                                    <h4 className="fs-5 fw-bold px-3">Get the best of Starbucks&reg; Rewards</h4>
                                    <p>Use our mobile app to order ahead and pay, to order at your table and a barista will bring it you, to order Starbucks&reg; delivery or to track the Stars and Rewards you've earned. Find stores, redeem offers and so much more.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section-collect2 pb-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 mb-0 mb-md-5 pt-3 text-center">
                                    <h2 className="h3 mt-5 fw-bold text-dark-212121">Collect 300 Stars and you're <span className="text-gold">Gold</span></h2>
                                    <p className="mt-3 text-dark-212121">Once you collect 300 Stars within 12 months, you will reach Gold.</p>
                                </div>
                            </div>
                            <div className="row justify-content-center gx-5 text-dark-000000">
                                <div className="col-sm-12 col-md-5 pb-4 text-center">
                                    <div className="mb-3"><img src={require("./media/rewards/img-box3-Celebrate-with-a-birthday-treat-big.png")} alt="Celebrate with a birthday treat" className="img-fluid" style={{ maxWidth: '250px' }} /></div>
                                    <h4 className="fs-5 px-3 fw-semibold">Celebrate with a <span className="fw-bold">birthday treat</span>, <br /><span className="small">that's us adding a little sparkle to your day.</span></h4>
                                </div>
                                <div className="col-sm-12 col-md-5 pb-0 pb-md-5 text-center">
                                    <div className="mb-3"><img src={require("./media/rewards/img-box3-Get-a-Starbucks-Rewards-Gift-big.png")} alt="Get a Starbucks Rewards Gift" className="img-fluid" style={{ maxWidth: '250px' }} /></div>
                                    <h4 className="fs-5 px-3 fw-semibold">Get a <span className="fw-bold">Starbucks&reg; Rewards Gift</span> <br /><span className="small">from us, deserve it!</span></h4>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section-banner-slide-bottom p-4 p-md-0">
                        <div className="container-fluid p-0 m-0">
                            <div className="p-0 m-0">
                                <Link to="/promograbsr">
                                    <img src={require("./media/rewards/banner-grab-text.png")} alt="Banner" className="img-fluid" />
                                </Link>
                            </div>
                        </div>
                    </section>
                    <section className="section-faq pb-3">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 mb-4 mb-md-5 pt-3">
                                    <h2 className="h3 mt-5 fw-bold text-dark-000000">Questions?</h2>
                                    <p className="mt-3 mb-5 text-dark-212121">We want to help in any way we can. You can ask your barista anytime or we've answered the most commonly asked questions <Link to="/faq">right over here.</Link></p>
                                    <h4 className="fs-6 fw-bold text-dark-000000">EARNING STARS</h4>
                                    <p className="text-dark-212121 mb-4">You must pay with your registered Starbucks Card (or your registered Card in the mobile app) in order to earn Stars.<br />
                                        Stars cannot be earned on purchases of Starbucks Cards or Starbucks Card reloads.</p>
                                    <h4 className="fs-6 fw-bold text-dark-000000">REDEEMING REWARDS</h4>
                                    <p className="text-dark-212121 mb-4">Rewards may be redeemed at participating stores and Starbucks&reg; TH app.</p>
                                    <h4 className="fs-6 fw-bold text-dark-000000">STAR EXPIRATION</h4>
                                    <p className="text-dark-212121 mb-4">Under the Starbucks&reg; Rewards program, Stars earned to maintain level will expire twelve (12) months from your anniversary date <br />
                                        and Stars earned to redeem rewards will expire six (6) months after the month in which you purchased.</p>
                                    <h4 className="fs-6 fw-bold text-dark-000000">TERMS OF USE</h4>
                                    <p className="text-dark-212121">For full details on Stars and Starbucks&reg; Rewards program, read our terms of use <Link to="/termsofuse">here.</Link></p>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>

                <EnNavFooter />
            </>
        );
    }
}