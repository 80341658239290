import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class ThNavHeader extends Component {
    static displayName = ThNavHeader.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            isAuthenticated: !!sessionStorage.getItem('username') // Check if username exists in session storage
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        const { isAuthenticated } = this.state;

        return (
            <>
                <header className="header-main">
                    <nav id="navbar_top" className="navbar navbar-expand-md bg-white">
                        <div className="container">
                            <a href="https://www.starbucks.co.th/" className="navbar-brand col-2 col-md-1 pe-0 me-0" id="logo"><img src={require("./media/starbucks_corporation_logo.png")} alt="Starbucks" className="img-fluid" /></a>
                            <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon" />
                            </button>
                            <div className="navbar-collapse collapse rounded-bottom" id="navbarCollapse" style={{}}>
                                <ul className="navbar-nav me-auto mb-2 mb-md-0">
                                    <li className="nav-item">
                                        <a className="nav-link" href="https://www.starbucks.co.th/th/coffee-tea-at-home/" rel="noopener noreferrer">
                                            เมล็ดกาแฟและชาสุดพิเศษ
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="https://www.starbucks.co.th/th/menu/" rel="noopener noreferrer">
                                            เมนู
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="https://www.starbucks.co.th/th/merchandise/" rel="noopener noreferrer">
                                            แก้วและทัมเบลอร์
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/ThHome">สตาร์บัคส์ รีวอร์ด</Link>
                                    </li>
                                </ul>
                                <div className="d-flex px-3 px-md-0">
                                    <ul className="header-nav header-nav-main nav nav-right nav-line-bottom nav-spacing-large nav-uppercase mt-4">
                                        <li className="html_topbar_left">
                                            <a href="https://www.starbucks.co.th/find-a-store/" className="link-find-store en-link">
                                                <i className="fa-solid fa-location-dot pe-1" /> <span>ค้นหาร้าน</span></a>
                                            <Link to="/authsigninth" className="btn btn-outline-dark rounded-pill text-decoration-none"><span>เข้าสู่ระบบ</span></Link>
                                            <Link to="/thauthjoinnow" className="btn btn-dark rounded-pill text-decoration-none me-2"><span>ลงทะเบียน</span></Link> |
                                            <Link to="/logout" className="lang-link"><img src={require("./media/us.png")} className="ms-1 me-1" alt="en" width={18} height={12} /> EN</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </nav>
                </header>

            </>
        );
    }
}
