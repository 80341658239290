import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { EnNavHeader } from './EnNavHeader';
import { EnNavFooter } from './EnNavFooter';

import { getClientId } from './utils/analyticsclientIdUtils';

class EnAuthForgotUsernameDetailThxClass extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);

        {
            // Google analytic
            const documentPath = "ForgotUsr - thank"; // Gets the current URL path
            const clientId = getClientId(); // Use the getClientId function

            // Push data to the GTM dataLayer
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'pageview',
                page: documentPath,
                clientId: clientId,
            });
        }
    }

    render() {

    return (
        <>
            <EnNavHeader />

            <main id="main">
                <section className="top-sr-bg bg-gray1">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col col-md-6">
                                <h1 className="text-headpage text-white mb-0 pt-3 pb-3 "><span className="d-none">Starbucks&reg; Rewards</span><img src="media/rewards/starbucks-rewards-logo-2.png" alt="Starbucks&reg; Rewards" className="img-fluid" style={{ maxWidth: '158px' }} /></h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-sign-in overflow-hidden pb-5">
                    <div className="container-fluid p-3 p-md-0">
                        <div className="row">
                            <div className="col-md-12 mb-4 pt-3 text-center">
                                <h2 className="h1 mt-5 fw-bold text-green-1">Thank you!</h2>
                                <h4 className="text-dark-212121 fw-semibold">You username has been changed</h4>
                                <div className="mt-4">
                                    <div className="gap-2 d-md-flex justify-content-center">
                                        <Link to="/" className="btn btn-green-1 rounded-pill text-decoration-none mb-3 mr-3">Home</Link>
                                        <Link to="/authsignin" className="btn btn-outline-green-1 rounded-pill text-decoration-none mb-3">Manage Your Account</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-3 mb-3">
                                <div className="text-center">
                                    <img src="media/rewards/SBX_GLOBAL_MTR_16.png" alt="Please check your email and click on verification link." className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>


            <EnNavFooter />
      </>
    );
  }
}

export const EnAuthForgotUsernameDetailThx = withRouter(EnAuthForgotUsernameDetailThxClass);