import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class UtilDeleteAccountC extends Component {    

    render() {
        return (
            <>
                <div className="mainbox text-center row">
                    <div className="w960 col-12 p-0"><a href="https://www.starbuckscardth.in.th/THHome" target="_blank"><img src="https://www.starbucks.co.th/portal/edm/request-deletion/EDM-request-account-deletion-2022-640_01.png" alt="Starbucks Rewards" width={640} title="Starbucks Rewards" border={0} /></a></div>
                    <div className="w960 col-12 p-0"><img src="https://www.starbucks.co.th/portal/edm/request-deletion/EDM-request-account-deletion-2022-640_02.png" width={640} border={0} className="img-fluid" alt="Your Starbucks® Rewards account has been deleted." title="Your Starbucks® Rewards account has been deleted." /></div>
                    <div className="w960 col-12 p-0"><img src="https://www.starbucks.co.th/portal/edm/request-deletion/EDM-request-account-deletion-2022-640_03.png" width={640} border={0} className="img-fluid" alt="Your Starbucks® Rewards account has been deleted." title="Your Starbucks® Rewards account has been deleted." /></div>
                    <div className="w960 col-12 p-0"><Link to="/DeleteAccountAuthTh"><img src="https://www.starbucks.co.th/portal/edm/request-deletion/EDM-request-account-deletion-2022-640_04.png" width={640} border={0} className="img-fluid" alt="Your Starbucks® Rewards account has been deleted." title="Your Starbucks® Rewards account has been deleted." /></Link></div>
                    <div className="w960 col-12 p-0"><img src="https://www.starbucks.co.th/portal/edm/request-deletion/EDM-request-account-deletion-2022-640_05.png" width={640} border={0} className="img-fluid" alt="Your Starbucks® Rewards account has been deleted." title="Your Starbucks® Rewards account has been deleted." /></div>
                    <div className="w960 col-12 p-0"><Link to="/DeleteAccountAuth"><img src="https://www.starbucks.co.th/portal/edm/request-deletion/EDM-request-account-deletion-2022-640_06.png" width={640} border={0} className="img-fluid" alt="Your Starbucks® Rewards account has been deleted." title="Your Starbucks® Rewards account has been deleted." /></Link></div>
                    <div className="w960 col-12 p-0" style={{ backgroundColor: '#ffffff' }}>
                        <div style={{ height: '30px' }} />
                    </div>
                    <div style={{ textAlign: 'center', fontFamily: 'Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif', fontSize: '0.7rem', lineHeight: '1.3', color: '#4B4B4B', padding: '0 15px' }}>
                        <p>This email is sent from an account we use for sending messages only. So if you want to contact us,<br />
                            don't reply to this email - we won't get your response. Instead, email your comments to<br /><a href="mailto:customercomment@coffee-concepts.co.th">customercomment@coffee-concepts.co.th</a>&nbsp;or contact our Customer Care at 02-339-0996<br />
                            (Mon-Sun 9:00 am - 6:00 pm)<br /><br />
                            Copyright © 2024 Coffee Concepts Retail Co.,Ltd. All rights reserved.</p>
                    </div>
                    <div className="w960 col-12 p-0" style={{ backgroundColor: '#ffffff' }}>
                        <div style={{ height: '30px' }} />
                    </div>
                    <div className="w960 col-12 p-0" style={{ textAlign: 'center', fontFamily: 'Corbel, "Myriad Pro", "Trebuchet MS", "Lucida Sans Unicode", sans-serif', fontSize: '12px', lineHeight: 'normal', color: '#666666', fontStyle: 'normal', fontWeight: 'bold', textDecoration: 'none', height: '18px' }}>
                        <img src="https://www.onlinepowerplus.com/starbucks/edm/summer2-2020/2/footer_sr2.png" width={640} border={0} className="img-fluid" alt="Starbucks Rewards" />
                    </div>
                </div>                
            </>
        );
    }
}