import React, { Component } from 'react';

export class ThSharedComponentBenefit01 extends Component {

    render() {        
        return (
            <>
                <section className="section-benefits pb-5">
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-md-12 mb-5 pt-3 text-center">
                                <h2 className="h3 mt-5 fw-bold text-dark-212121">สิทธิประโยชน์มากมายตั้งแต่เริ่มต้น</h2>
                            </div>
                        </div>
                        <nav>
                            <div className="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
                                <button className="nav-link active" id="nav-20-tab" data-bs-toggle="tab" data-bs-target="#nav-20" type="button" role="tab" aria-controls="nav-20" aria-selected="true">
                                    20 <img src={require("./media/rewards/star-green.png")} alt="Green" className="img-fluid star-green" /><span className="tabs-border-slider" /></button>
                                <button className="nav-link" id="nav-60-tab" data-bs-toggle="tab" data-bs-target="#nav-60" type="button" role="tab" aria-controls="nav-60" aria-selected="false">
                                    60 <img src={require("./media/rewards/star-green.png")} alt="Green" className="img-fluid star-green" /><span className="tabs-border-slider" /></button>
                                <button className="nav-link" id="nav-120-tab" data-bs-toggle="tab" data-bs-target="#nav-120" type="button" role="tab" aria-controls="nav-120" aria-selected="false">
                                    120 <img src={require("./media/rewards/star-gold.png")} alt="Gold" className="img-fluid star-gold" /><span className="tabs-border-slider" /></button>
                                <button className="nav-link" id="nav-160-tab" data-bs-toggle="tab" data-bs-target="#nav-160" type="button" role="tab" aria-controls="nav-160" aria-selected="false">
                                    160 <img src={require("./media/rewards/star-gold.png")} alt="Gold" className="img-fluid star-gold" /><span className="tabs-border-slider" /></button>
                                <button className="nav-link" id="nav-350-tab" data-bs-toggle="tab" data-bs-target="#nav-350" type="button" role="tab" aria-controls="nav-350" aria-selected="false">
                                    350 <img src={require("./media/rewards/star-gold.png")} alt="Gold" className="img-fluid star-gold" /><span className="tabs-border-slider" /></button>
                            </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-20" role="tabpanel" aria-labelledby="nav-20-tab" tabIndex={0}>
                                <div className="container pt-5 pb-4">
                                    <div className="row m-0 gx-5 text-dark-000000">
                                        <div className="col-sm-12 col-md-6 px-4 px-md-0 text-center">
                                            <div className="mb-3"><img src={require("./media/rewards/img-box2-1.png")} alt="Benefits 20" className="img-fluid" /></div>
                                            <div className="my-4"><img src={require("./media/rewards/badge-green-gold.png")} alt="Green Gold" className="img-fluid img-benefits-green-gold" /></div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 px-4 px-md-0">
                                            <h4 className="fs-5 fw-bold mt-4 mb-3">แลกรับเครื่องดื่มที่ชื่นชอบกับขนาดที่ใหญ่ขึ้น<br />
                                                ( 12 ออนซ์/16 ออนซ์ )</h4>
                                            <ul>
                                                <li>สิทธิประโยชน์สำหรับสมาชิกระดับโกลด์และกรีน</li>
                                                <li>เครื่องดื่มขนาด 12 ออนซ์/16 ออนซ์ ยกเว้นเครื่องดื่มที่ทำจากกาแฟ Starbucks Reserve™ ทุกชนิด, น้ำผลไม้, เครื่องดื่มบรรจุขวดทุกประเภท, เครื่องดื่มที่จำหน่ายเพียงขนาดเดียว, เครื่องดื่มไอศกรีมทุกประเภท และเครื่องดื่มแอลกอฮอล์ทุกประเภท</li>
                                                <li>สิทธิประโยชน์นี้สามารถใช้ได้ที่ร้านสตาร์บัคส์, บริการ Drive Thru, Mobile Order- ahead &amp; Pick Up at Store, Mobile Order to Table และ Starbucks Delivery ผ่าน Starbucks&reg; TH app ยกเว้น foodpanda, Grab, LINE MAN, Robinhood และ ShopeeFood</li>
                                                <li>ไม่สามารถใช้ร่วมกับรายการส่งเสริมการขายอื่นๆ</li>
                                                <li>เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="nav-60" role="tabpanel" aria-labelledby="nav-60-tab" tabIndex={0}>
                                <div className="container pt-5 pb-4">
                                    <div className="row m-0 gx-5 text-dark-000000">
                                        <div className="col-sm-12 col-md-6 px-4 px-md-0 text-center">
                                            <div className="mb-3"><img src={require("./media/rewards/img-box2-2.png")} alt="Benefits 60" className="img-fluid" /></div>
                                            <div className="my-4"><img src={require("./media/rewards/badge-green-gold.png")} alt="Green Gold" className="img-fluid img-benefits-green-gold" /></div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 px-4 px-md-0">
                                            <h4 className="fs-5 fw-bold mt-4 mb-3">แลกรับส่วนลด 25% สำหรับเครื่องดื่ม 1 แก้ว หรือขนม 1 ชิ้น</h4>
                                            <ul>
                                                <li>สิทธิประโยชน์สำหรับสมาชิกระดับโกลด์และกรีน</li>
                                                <li>สิทธิประโยชน์นี้สำหรับเครื่องดื่ม 1 แก้ว หรือ ขนม 1 ชิ้น</li>
                                                <li>เครื่องดื่มขนาดใดก็ได้ ยกเว้นเครื่องดื่มบรรจุขวดที่ไม่ใช่ยี่ห้อสตาร์บัคส์, เครื่องดื่มที่ทำจากกาแฟรีเสิร์ฟซูเปอร์พรีเมี่ยม เช่น กาแฟจาไมก้า บลูเมาเท่น, เครื่องดื่มไอศกรีมทุกประเภท,เครื่องดื่ม Coffee Press 8-cup, เครื่องดื่ม Flights และเครื่องดื่มแอลกอฮอล์ทุกประเภท</li>
                                                <li>สิทธิประโยชน์นี้สำหรับขนมประเภทใดก็ได้ ยกเว้น ขนมไหว้พระจันทร์และเค้กปอนด์</li>
                                                <li>สิทธิประโยชน์นี้สามารถใช้ได้ที่ร้านสตาร์บัคส์, บริการ Drive Thru, Mobile Order- ahead & Pick Up at Store, Mobile Order to Table และ Starbucks Delivery ผ่าน Starbucks® TH app ยกเว้น foodpanda, Grab, LINE MAN Robinhood และ ShopeeFood</li>
                                                <li>ไม่สามารถใช้ร่วมกับรายการส่งเสริมการขายอื่นๆ</li>
                                                <li>เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="nav-120" role="tabpanel" aria-labelledby="nav-120-tab" tabIndex={0}>
                                <div className="container pt-5 pb-4">
                                    <div className="row m-0 gx-5 text-dark-000000">
                                        <div className="col-sm-12 col-md-6 px-4 px-md-0 text-center">
                                            <div className="mb-3"><img src={require("./media/rewards/img-box2-3.png")} alt="Benefits 120" className="img-fluid" /></div>
                                            <div className="my-4"><img src={require("./media/rewards/badge-gold.png")} alt="Gold" className="img-fluid img-benefits-gold" /></div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 px-4 px-md-0">
                                            <h4 className="fs-5 fw-bold mt-4 mb-3">แลกรับเครื่องดื่มแก้วโปรดขนาดใดก็ได้ฟรี 1 แก้ว</h4>
                                            <ul>
                                                <li>สิทธิประโยชน์เฉพาะสมาชิกระดับโกลด์</li>
                                                <li>รับเครื่องดื่มขนาดใดก็ได้ฟรี 1 แก้ว พร้อมเลือกส่วนผสมเพื่อเพิ่มรสชาติที่คุณชื่นชอบได้ฟรี 2 รายการ </li>
                                                <li>เครื่องดื่มขนาดใดก็ได้ ยกเว้นเครื่องดื่มบรรจุขวดที่ไม่ใช่ยี่ห้อสตาร์บัคส์, เครื่องดื่มที่ทำจากกาแฟรีเสิร์ฟซูเปอร์พรีเมี่ยม เช่น กาแฟจาไมก้า บลูเมาเท่น, เครื่องดื่มไอศกรีมทุกประเภท,เครื่องดื่ม Coffee Press 8-cup, เครื่องดื่ม Flights และเครื่องดื่มแอลกอฮอล์ทุกประเภท</li>
                                                <li>Customization ทุกประเภท ได้ฟรี 2 อย่าง ยกเว้นช็อต Reserve espresso</li>
                                                <li>สิทธิประโยชน์นี้สามารถใช้ได้ที่ร้านสตาร์บัคส์, บริการ Drive Thru, Mobile Order- ahead &amp; Pick Up at Store, Mobile Order to Table และ Starbucks Delivery ผ่าน Starbucks&reg; TH app ยกเว้น foodpanda, Grab, LINE MAN, Robinhood และ ShopeeFood</li>
                                                <li>ไม่สามารถใช้ร่วมกับรายการส่งเสริมการขายอื่นๆ</li>
                                                <li>เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="nav-160" role="tabpanel" aria-labelledby="nav-160-tab" tabIndex={0}>
                                <div className="container pt-5 pb-4">
                                    <div className="row m-0 gx-5 text-dark-000000">
                                        <div className="col-sm-12 col-md-6 px-4 px-md-0 text-center">
                                            <div className="mb-3"><img src={require("./media/rewards/img-box2-4.png")} alt="Benefits 160" className="img-fluid" /></div>
                                            <div className="my-4"><img src={require("./media/rewards/badge-gold.png")} alt="Gold" className="img-fluid img-benefits-gold" /></div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 px-4 px-md-0">
                                            <h4 className="fs-5 fw-bold mt-4 mb-3">แลกรับส่วนลด 200 บาท เมื่อซื้อสินค้าสตาร์บัคส์ทุกประเภท 500 บาทขึ้นไป/ใบเสร็จ</h4>
                                            <ul>
                                                <li>สิทธิประโยชน์เฉพาะสมาชิกระดับโกลด์</li>
                                                <li>แลกรับได้ 1 สิทธิ์/ใบเสร็จ </li>
                                                <li>สิทธิประโยชน์นี้ใช้ได้กับสินค้าสตาร์บัคส์ทุกประเภท ยกเว้นเครื่องดื่มแอลกอฮอล์ทุกประเภท</li>
                                                <li>สิทธิประโยชน์นี้สามารถใช้ได้ที่ร้านสตาร์บัคส์, บริการ Drive Thru, Mobile Order- ahead &amp; Pick Up at Store, Mobile Order to Table และ Starbucks Delivery ผ่าน Starbucks&reg; TH app ยกเว้น foodpanda, Grab, LINE MAN, Robinhood และ ShopeeFood </li>
                                                <li>ไม่สามารถใช้ร่วมกับสิทธิประโยชน์ที่ใช้ดาว 350 ดวงแลก ในใบเสร็จเดียวกันได้</li>
                                                <li>ไม่สามารถใช้ร่วมกับรายการส่งเสริมการขายอื่นๆ</li>
                                                <li>เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="nav-350" role="tabpanel" aria-labelledby="nav-350-tab" tabIndex={0}>
                                <div className="container pt-5 pb-4">
                                    <div className="row m-0 gx-5 text-dark-000000">
                                        <div className="col-sm-12 col-md-6 px-4 px-md-0 text-center align-self-end">
                                            <div className="mb-3 accordion-image">
                                                <img id="img1" src="media/rewards/img-box2-5.png" alt="Get 4 favorite handcrafted drinks for free/bill" className="img-fluid" />
                                                <img id="img2" src="media/rewards/img-box2-5-2.png" alt="Get 50% off up to 600 Baht/bill" className="img-fluid" />
                                            </div>
                                            <div className="my-4"><img src={require("./media/rewards/badge-gold.png")} alt="Gold" className="img-fluid img-benefits-gold" /></div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 px-4 px-md-0">
                                            <div className="accordion accordion350tab" id="accordion350tab">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" data-image="img1">
                                                            แลกรับเครื่องดื่มขนาดใดก็ได้ฟรี 4 แก้ว/ใบเสร็จ
                                                        </button>
                                                    </h2>
                                                    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordion350tab">
                                                        <div className="accordion-body">
                                                            <ul>
                                                                <li>สิทธิประโยชน์นี้เฉพาะสมาชิกระดับโกลด์</li>
                                                                <li>แลกรับเครื่องดื่มขนาดใดก็ได้ประเภทใดก็ได้ฟรี 4 แก้วเลือกส่วนผสมเพื่อเพิ่มรสชาติ ที่คุณชื่นชอบได้ฟรี 2 รายการ ต่อเครื่องดื่ม ภายในใบเสร็จเดียวกัน</li>
                                                                <li>เครื่องดื่มขนาดใดก็ได้ ยกเว้นเครื่องดื่มบรรจุขวดที่ไม่ใช่ยี่ห้อสตาร์บัคส์, เครื่องดื่มที่ทำจากกาแฟรีเสิร์ฟซูเปอร์พรีเมี่ยม เช่น กาแฟจาไมก้า บลูเมาเท่น, เครื่องดื่มไอศกรีมทุกประเภท, เครื่องดื่ม Coffee Press 8-cup, เครื่องดื่ม Flights และเครื่องดื่มแอลกอฮอล์ทุกประเภท</li>
                                                                <li>Customization ทุกประเภท ได้ฟรี 2 อย่าง ยกเว้นช็อต Reserve espresso</li>
                                                                <li>สิทธิประโยชน์นี้สามารถใช้ได้ที่ร้านสตาร์บัคส์, บริการ Drive Thru, Mobile Order- ahead &amp; Pick Up at Store, Mobile Order to Table และ Starbucks Delivery ผ่าน Starbucks&reg; TH app ยกเว้น foodpanda, Grab, LINE MAN, Robinhood และ ShopeeFood</li>
                                                                <li>ไม่สามารถใช้ร่วมกับสิทธิประโยชน์ที่ใช้ดาว 160 ดวงแลกรับส่วนลด 200 บาท หรือ  350 ดวงแลกรับส่วนลด 50% ในใบเสร็จเดียวกันได้</li>
                                                                <li>ไม่สามารถใช้ร่วมกับรายการส่งเสริมการขายอื่นๆ</li>
                                                                <li>เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" data-image="img2">
                                                            แลกรับส่วนลด 50% (ลดสูงสุด 600 บาท)/ใบเสร็จ
                                                        </button>
                                                    </h2>
                                                    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordion350tab">
                                                        <div className="accordion-body">
                                                            <ul>
                                                                <li>สิทธิประโยชน์เฉพาะสมาชิกระดับโกลด์</li>
                                                                <li>แลกรับได้ 1 สิทธิ์/ใบเสร็จ </li>
                                                                <li>ส่วนลด 50% สูงสุด 600 บาท/ใบเสร็จโดยไม่จำกัดยอดซื้อขั้นต่ำ</li>
                                                                <li>สิทธิประโยชน์นี้ใช้ได้กับสินค้าสตาร์บัคส์ทุกประเภท ยกเว้นเครื่องดื่มแอลกอฮอล์ทุกประเภท</li>
                                                                <li>สิทธิประโยชน์นี้สามารถใช้ได้ที่ร้านสตาร์บัคส์, บริการ Drive Thru, Mobile Order-ahead &amp; Pick Up at Store, Mobile Order to Table และ Starbucks Delivery ผ่าน Starbucks&reg; TH app ยกเว้น foodpanda, Grab, LINE MAN, Robinhood และ ShopeeFood </li>
                                                                <li>ไม่สามารถใช้ร่วมกับสิทธิประโยชน์ที่ใช้ดาว 160 ดวงแลกรับส่วนลด 200 บาท หรือ  350 ดวงแลกรับเครื่องดื่มขนาดใดก็ได้ฟรี 4 แก้ว ในใบเสร็จเดียวกันได้</li>
                                                                <li>ไม่สามารถใช้ร่วมกับรายการส่งเสริมการขายอื่นๆ</li>
                                                                <li>เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}
