import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { ThNavHeader } from './ThNavHeader';
import { ThNavFooter } from './ThNavFooter';

import { getClientId } from './utils/analyticsclientIdUtils';
import { encryptData } from './utils/encryptionUtils';
import { API_URL } from './utils/constants';

class ThAuthForgotDetailClass extends Component {

    constructor(props) {
        super(props);
        this.state = {
            memberInfo: null,
            codeRegister: null,
            password1: '',
            password2: '',
            registerMsg: '',
            registerMsgPwd: '',
            registerMsgPwd2: '',
            showPasswordTips: false,
            submitting: false // Add a state variable to track form submission status
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        const searchParams = new URLSearchParams(this.props.location.search);
        const codeRegister = searchParams.get('codereg');
        if (!codeRegister) {
            const dataToSend = { reason: "No code!!" };
            this.props.history.replace('/forgoterr', dataToSend);
            return;
        }

        // Update state and call API to get member information
        this.setState({ codeRegister }, () => {
            this.callApiRegisterInfo(codeRegister);
        });

        {
            // Google analytic
            const documentPath = "Th ForgotPwd - detail"; // Gets the current URL path
            const clientId = getClientId(); // Use the getClientId function

            // Push data to the GTM dataLayer
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'pageview',
                page: documentPath,
                clientId: clientId,
            });
        }
    }

    async callApiRegisterInfo(codeRegister) {
        // Add the wait cursor class to the body element
        document.body.classList.add('wait-cursor');

        // Generate Unix timestamp
        const timestamp = Math.floor(Date.now() / 1000);

        const payload = { codereg: codeRegister, RefLang: "th", Timestamp: timestamp };
        const encryptedPayload = encryptData(payload);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ Data: encryptedPayload })
        };

        try {
            const response = await fetch(`${API_URL}/api/CrmForgotStepChk`, requestOptions);
            const data = await response.json();

            if (data) {
                document.body.classList.remove('wait-cursor'); // Remove the wait cursor class

                if (data.responseCode == "OK") {
                    this.setState({ memberInfo: data }, () => {
                        // Initialize this java after loaded
                        window.$(".toggle-password").click(function () {
                            window.$(this).toggleClass("fa-eye fa-eye-slash");
                            var input = window.$(window.$(this).attr("toggle"));
                            if (input.attr("type") === "password") {
                                input.attr("type", "text");
                            } else {
                                input.attr("type", "password");
                            }
                        });
                    });
                }
                else {
                    const dataToSend = { reason: data.responseMessage };
                    this.props.history.replace('/thforgoterr', dataToSend);
                }
            }
            else {
                const dataToSend = { reason: "No data!!" };
                this.props.history.replace('/thforgoterr', dataToSend);
            }
        } catch (error) {
            console.error('API call error :', error);
        } finally {
            
        }
    }

    handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        if (type === 'checkbox') {
            this.setState({ [name]: checked });
        } else {
            let errors = '';
            if (name === "password1") {
                errors = this.validatePassword(value);
            }

            this.setState({ [name]: value, registerMsgPwd: errors });
        }
    };

    handleSubmit = async e => {
        e.preventDefault();
        const { password1, password2, codeRegister } = this.state;        
        {
            var errorChk = false;

            //check form condition
            const errors = this.validatePassword(password1);
            if (errors) {
                this.setState({ registerMsgPwd: errors });
                errorChk = true;
            }

            if (password2 === "") {
                this.setState({ registerMsgPwd2: "กรุณากรอกยืนยันรหัสผ่าน" });
                errorChk = true;
            }

            if (password1 !== password2) {
                this.setState({ registerMsgPwd2: "รหัสผ่านที่คุณกรอกไม่ตรงกัน" });
                errorChk = true;
            }

            if (errorChk) {
                return;
            }
        }

        // Generate Unix timestamp
        const timestamp = Math.floor(Date.now() / 1000);

        // Prepare and encrypt the payload
        const payload = { CodeReg: codeRegister, CurrentPassword: password1, RefLang: 'th', Timestamp: timestamp };
        const encryptedPayload = encryptData(payload);

        // Prevent double-clicking by disabling the submit button and changing cursor to 'wait'
        this.setState({ submitting: true });

        // Add the wait cursor class to the body element
        document.body.classList.add('wait-cursor');

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ Data: encryptedPayload })
        };

        try {
            const response = await fetch(`${API_URL}/api/CrmForgotStep02`, requestOptions);
            const data = await response.json();
            document.body.classList.remove('wait-cursor'); // Remove the wait cursor class

            if (data.responseCode === "OK") {
                const dataToSend = { responseData: data };
                this.props.history.replace('/thforgotthx', dataToSend);
            }
            else {
                //const dataToSend = { responseData: data };
                //this.props.history.replace('/thforgoterr', dataToSend);

                // Enable the submit button and change cursor back to 'auto' after form submission completes
                this.setState({ submitting: false });
                document.body.classList.remove('wait-cursor'); // Remove the wait cursor class

                this.setState({ registerMsg: data.responseMessage });
            }
        } catch (error) {
            console.error('Error :', error);
            // Handle error appropriately, e.g., show an error message to the user

            // Enable the submit button and change cursor back to 'auto' after form submission completes
            this.setState({ submitting: false });
            document.body.classList.remove('wait-cursor'); // Remove the wait cursor class
        } finally {
            
        }
    };

    validatePassword = (password) => {
        const minLength = 9;
        const maxLength = 15;
        const numberRegex = /[0-9]/;
        const capitalLetterRegex = /[A-Z]/;
        const lowerCaseLetterRegex = /[a-z]/;
        const specialCharRegex = /[!@#$*;&]/;

        if (password.length === 0) {
            return 'กรุณากรอกรหัสผ่านใหม่';
        }
        if (password.length < minLength || password.length > maxLength) {
            return `ต้องมีความยาวรหัสตั้งแต่ ${minLength} ถึง ${maxLength} ตัวอักษร`;
        }
        if (!numberRegex.test(password)) {
            return 'ต้องประกอบด้วยตัวเลขอย่างน้อยหนึ่งตัว';
        }
        if (!capitalLetterRegex.test(password)) {
            return 'ต้องประกอบด้วยตัวอักษรพิมพ์ใหญ่อย่างน้อยหนึ่งตัวอักษร';
        }
        if (!lowerCaseLetterRegex.test(password)) {
            return 'ต้องประกอบด้วยตัวอักษรพิมพ์เล็กอย่างน้อยหนึ่งตัวอักษร';
        }
        if (!specialCharRegex.test(password)) {
            return 'ต้องประกอบด้วยตัวอักษรพิเศษอย่างน้อยหนึ่งตัวอักษร เช่น (e.g., !,@,#,$,*,;,&). ';
        }

        return ''; // Return an empty string if all checks pass
    };

    handleFocus = () => {
        this.setState({ showPasswordTips: true });
    };

    handleBlur = () => {
        this.setState({ showPasswordTips: false });
    };

    render() {
        const { memberInfo, password1, password2, registerMsg, submitting, showPasswordTips, registerMsgPwd, registerMsgPwd2 } = this.state;
        return (
            <>
                <ThNavHeader />
                {memberInfo && (
                    <main id="main">
                        <section className="top-sr-bg bg-gray1">
                            <div className="container">
                                <div className="row justify-content-between">
                                    <div className="col col-md-6">
                                        <h1 className="text-headpage text-white mb-0 pt-3 pb-3 "><span className="text-black">สตาร์บัคส์ รีวอร์ด</span></h1>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="section-create-account overflow-hidden pb-5">
                            <div className="container p-3 p-md-0">
                                <div className="row">
                                    <div className="col-md-12 mb-5 pt-3 text-start">
                                        <h2 className="h3 mt-5 mb-4 fw-bold text-dark-212121">เปลี่ยนรหัสผ่าน</h2>
                                        <form className="form-sr" onSubmit={this.handleSubmit}>                                            
                                            <div className="row">
                                                <div className="col-md-4 mb-3">
                                                    <label className="form-label">รหัสผ่าน</label>
                                                    <div className="input-group mb-3">
                                                        <input
                                                            id="password-field"
                                                            type="password"
                                                            className="form-control border-end-0"
                                                            placeholder="*รหัสผ่าน"
                                                            name="password1"
                                                            value={password1}
                                                            onChange={this.handleChange}
                                                            onFocus={this.handleFocus}
                                                            onBlur={this.handleBlur}
                                                        />
                                                        <span className="input-group-text"><i toggle="input[name='password1']" className="fa fa-fw fa-eye-slash toggle-password" /></span>
                                                        <div className="invalid-feedback" style={{ display: 'block' }}>{registerMsgPwd}</div>
                                                        {showPasswordTips && (
                                                            <div id="new-password-field-tips" className="passwordtips-box">
                                                                <h4 className="h6 text-decoration-underline">Password Tips</h4>
                                                                <p className="form-text">
                                                                    ความยาวรหัสตั้งแต่ 9 ถึง 15 ตัวอักษร<br />
                                                                    ประกอบด้วยตัวเลขอย่างน้อยหนึ่งตัว<br />
                                                                    ประกอบด้วยตัวอักษรพิมพ์ใหญ่อย่างน้อยหนึ่งตัวอักษร<br />
                                                                    ประกอบด้วยตัวอักษรพิมพ์เล็กอย่างน้อยหนึ่งตัวอักษร<br />
                                                                    ประกอบด้วยตัวอักษรพิเศษอย่างน้อยหนึ่งตัวอักษร เช่น !,@,#,$,*,;,&
                                                                </p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4 mb-3">
                                                    <label className="form-label">ยืนยันรหัสผ่าน</label>
                                                    <div className="input-group mb-3">
                                                        <input id="password-confirm-field" type="password" className="form-control border-end-0" placeholder="*ยืนยันรหัสผ่าน" name="password2" value={password2} onChange={this.handleChange} />
                                                        <span className="input-group-text"><i toggle="input[name='password2']" className="fa fa-fw fa-eye-slash toggle-password" /></span>
                                                        <div className="invalid-feedback" style={{ display: 'block' }}>{registerMsgPwd2}</div>
                                                    </div>
                                                </div>
                                            </div>                                            
                                            <div className="text-start mt-4">
                                                <button type="submit" className="btn btn-primary rounded-pill px-4 text-decoration-none" disabled={submitting}>
                                                    {submitting ? 'ยืนยัน...' : 'ยืนยัน'}
                                                </button>
                                            </div>
                                            <div className="text-start mt-2 text-alert-2">
                                                {registerMsg}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>
                )}
                <ThNavFooter />
            </>
        );
    }
}

export const ThAuthForgotDetail = withRouter(ThAuthForgotDetailClass);