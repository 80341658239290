import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { EnNavHeader } from './EnNavHeader';
import { EnNavFooter } from './EnNavFooter';

import { getClientId } from './utils/analyticsclientIdUtils';
import { encryptData } from './utils/encryptionUtils';
import { API_URL } from './utils/constants';

class EnAuthJoinDetailClass extends Component {

    constructor(props) {
        super(props);
        this.state = {
            memberInfo: null,
            codeRegister: null,
            password1: '',
            password2: '',
            prefix: '',
            firstname: '',
            lastname: '',
            firstnameth: '',
            lastnameth: '',
            mobile: '',
            bdDay: '',
            bdMonth: '',
            agree1: false,
            agree2: false,
            registerMsg: '',
            registerMsgPwd: '',
            registerMsgPwd2: '',
            registerMsgFname: '',
            registerMsgLname: '',
            registerMsgAgree: '',
            registerMsgMobile: '',
            submitting: false, // Add a state variable to track form submission status
            showPasswordTips: false,
            renderCheck: false,
            cssPopupBD: 'popuphide'
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        const searchParams = new URLSearchParams(this.props.location.search);
        const codeRegister = searchParams.get('codereg');
        if (!codeRegister) {
            const dataToSend = { reason: "No code!!" };
            this.props.history.replace('/joinnowerr', dataToSend);
            return;
        }

        // Update state and call API to get member information
        this.setState({ codeRegister }, () => {
            this.callApiRegisterInfo(codeRegister);
        });

        {
            // Google analytic
            const documentPath = "Join - detail"; // Gets the current URL path
            const clientId = getClientId(); // Use the getClientId function

            // Push data to the GTM dataLayer
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'pageview',
                page: documentPath,
                clientId: clientId,
            });
        }
    }

    async callApiRegisterInfo(codeRegister) {
        // Add the wait cursor class to the body element
        document.body.classList.add('wait-cursor');

        // Generate Unix timestamp
        const timestamp = Math.floor(Date.now() / 1000);

        const payload = { codereg: codeRegister, RefLang: "en", Timestamp: timestamp };
        const encryptedPayload = encryptData(payload);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ Data: encryptedPayload })
        };

        try {
            const response = await fetch(`${API_URL}/api/CrmRegisterStep02`, requestOptions);
            const data = await response.json();

            if (data) {
                if (data.responseCode == "OK") {
                    this.setState({ memberInfo: data, renderCheck: true }, () => {
                        // Initialize this java after loaded
                        window.$(".toggle-password").click(function () {
                            window.$(this).toggleClass("fa-eye fa-eye-slash");
                            var input = window.$(window.$(this).attr("toggle"));
                            if (input.attr("type") === "password") {
                                input.attr("type", "text");
                            } else {
                                input.attr("type", "password");
                            }
                        });
                    });
                }
                else {
                    const dataToSend = { reason: data.responseMessage };
                    this.props.history.replace('/joinnowerr', dataToSend);
                }
            }
            else {
                const dataToSend = { reason: "No data!!" };
                this.props.history.replace('/joinnowerr', dataToSend);
            }
        } catch (error) {
            console.error('API call error :', error);
        } finally {
            document.body.classList.remove('wait-cursor'); // Remove the wait cursor class
        }
    }

    handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        if (type === 'checkbox') {
            this.setState({ [name]: checked });
        } else {
            let errors = '';
            if (name === "password1") {
                errors = this.validatePassword(value);
            }
            
            this.setState({ [name]: value, registerMsgPwd:errors });
        }

        if (name === "bdMonth") {
            this.setState({
                bdDay: ''
            });
        }
    };

    handleSubmit = async e => {
        e.preventDefault();
        const { memberInfo, password1, password2, prefix, firstname, lastname, firstnameth, lastnameth, mobile, bdDay, bdMonth, agree1, codeRegister } = this.state;
        {
            let canSubmit = true;

            //check form condition
            const errors = this.validatePassword(password1);
            if (errors) {
                this.setState({ registerMsgPwd: errors });
                canSubmit = false;
            }

            if (password2 === "") {
                this.setState({ registerMsgPwd2: "Please Input Confirm Password" });
                canSubmit = false;
            }

            if (password1 !== password2) {
                this.setState({ registerMsgPwd2: "Confirm Password must match with Password" });
                canSubmit = false;
            }            

            if (firstname === "") {
                this.setState({ registerMsgFname: "Please Input Name" });
                canSubmit = false;
            }

            if (lastname === "") {
                this.setState({ registerMsgLname: "Please Input Lastname" });
                canSubmit = false;
            }

            // Mobile validation
            if (mobile && (!/^0\d{9}$/.test(mobile))) {
                this.setState({ registerMsgMobile: "Please Input valid Mobile number" });
                canSubmit = false;
            }

            if (agree1 !== true) {
                this.setState({ registerMsgAgree: "Please Accept Email Preference" });
                canSubmit = false;
            }

            if (canSubmit == false) {
                return;
            }

            //No BirthdayD show popup
            if (bdDay === "") {
                this.setState({ cssPopupBD: "popup" });
                return;
            }
        }

        // Generate Unix timestamp
        const timestamp = Math.floor(Date.now() / 1000);

        // Prepare and encrypt the payload
        const payload = { Email: memberInfo.responseMessage, CurrentPassword: password1, RefLang: 'en', Salutation: prefix, FirstName: firstname, LastName: lastname, FirstNameTH: firstnameth, LastNameTH: lastnameth, MobilePhone: mobile, BirthdayD: bdDay, BirthdayM: bdMonth, Agree1: agree1, CodeReg: codeRegister, Timestamp: timestamp };
        const encryptedPayload = encryptData(payload);

        // Prevent double-clicking by disabling the submit button and changing cursor to 'wait'
        this.setState({ submitting: true });

        // Add the wait cursor class to the body element
        document.body.classList.add('wait-cursor');

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ Data: encryptedPayload })
        };

        try {
            const response = await fetch(`${API_URL}/api/CrmRegisterStep03`, requestOptions);
            const data = await response.json();

            if (data.responseCode === "OK") {
                document.body.classList.remove('wait-cursor'); // Remove the wait cursor class

                const dataToSend = { responseData: data };
                this.props.history.replace('/joinnowthx', dataToSend);
            }
            else {
                document.body.classList.remove('wait-cursor'); // Remove the wait cursor class

                const dataToSend = { responseData: data };
                this.props.history.replace('/joinnowerr', dataToSend);
            }
        } catch (error) {
            console.error('Error during api:', error);
            // Handle error appropriately, e.g., show an error message to the user
            // Enable the submit button and change cursor back to 'auto' after form submission completes
            this.setState({ submitting: false });
            document.body.classList.remove('wait-cursor'); // Remove the wait cursor class
        } finally {
            
        }
    };

    handleSubmit2 = async e => {
        ////No Birthday check fo this
        e.preventDefault();
        const { memberInfo, password1, password2, prefix, firstname, lastname, firstnameth, lastnameth, mobile, bdDay, bdMonth, agree1, codeRegister } = this.state;
        {
            let canSubmit = true;

            //check form condition
            const errors = this.validatePassword(password1);
            if (errors) {
                this.setState({ registerMsgPwd: errors });
                canSubmit = false;
            }

            if (password2 === "") {
                this.setState({ registerMsgPwd2: "Please Input Confirm Password" });
                canSubmit = false;
            }

            if (password1 !== password2) {
                this.setState({ registerMsgPwd2: "Confirm Password must match with Password" });
                canSubmit = false;
            }

            if (firstname === "") {
                this.setState({ registerMsgFname: "Please Input Name" });
                canSubmit = false;
            }

            if (lastname === "") {
                this.setState({ registerMsgLname: "Please Input Lastname" });
                canSubmit = false;
            }

            // Mobile validation
            if (mobile && (!/^0\d{9}$/.test(mobile))) {
                this.setState({ registerMsgMobile: "Please Input valid Mobile number" });
                canSubmit = false;
            }

            if (agree1 !== true) {
                this.setState({ registerMsgAgree: "Please Accept Email Preference" });
                canSubmit = false;
            }

            if (canSubmit == false) {
                return;
            }
        }

        // Generate Unix timestamp
        const timestamp = Math.floor(Date.now() / 1000);

        // Prepare and encrypt the payload
        const payload = { Email: memberInfo.responseMessage, CurrentPassword: password1, RefLang: 'en', Salutation: prefix, FirstName: firstname, LastName: lastname, FirstNameTH: firstnameth, LastNameTH: lastnameth, MobilePhone: mobile, BirthdayD: bdDay, BirthdayM: bdMonth, Agree1: agree1, CodeReg: codeRegister, Timestamp: timestamp };
        const encryptedPayload = encryptData(payload);

        // Prevent double-clicking by disabling the submit button and changing cursor to 'wait'
        this.setState({ submitting: true });

        // Add the wait cursor class to the body element
        document.body.classList.add('wait-cursor');

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ Data: encryptedPayload })
        };

        try {
            const response = await fetch(`${API_URL}/api/CrmRegisterStep03`, requestOptions);
            const data = await response.json();

            if (data.responseCode === "OK") {
                document.body.classList.remove('wait-cursor'); // Remove the wait cursor class

                const dataToSend = { responseData: data };
                this.props.history.replace('/joinnowthx', dataToSend);
            }
            else {
                document.body.classList.remove('wait-cursor'); // Remove the wait cursor class

                const dataToSend = { responseData: data };
                this.props.history.replace('/joinnowerr', dataToSend);
            }
        } catch (error) {
            console.error('Error during api:', error);
            // Handle error appropriately, e.g., show an error message to the user
            // Enable the submit button and change cursor back to 'auto' after form submission completes
            this.setState({ submitting: false });
            document.body.classList.remove('wait-cursor'); // Remove the wait cursor class
        } finally {

        }
    };

    cancelSubmit = () => {
        this.setState({
            cssPopupBD: 'popuphide'
        });
    };

    validatePassword = (password) => {
        const minLength = 9;
        const maxLength = 15;
        const numberRegex = /[0-9]/;
        const capitalLetterRegex = /[A-Z]/;
        const lowerCaseLetterRegex = /[a-z]/;
        const specialCharRegex = /[!@#$*;&]/;

        if (password.length === 0) {
            return 'Please Input Password.';
        }
        if (password.length < minLength || password.length > maxLength) {
            return `Password must be between ${minLength} and ${maxLength} characters long. `;
        }
        if (!numberRegex.test(password)) {
            return 'Password must contain at least one number. ';
        }
        if (!capitalLetterRegex.test(password)) {
            return 'Password must contain at least one capital letter. ';
        }
        if (!lowerCaseLetterRegex.test(password)) {
            return 'Password must contain at least one lower case letter. ';
        }
        if (!specialCharRegex.test(password)) {
            return 'Password must contain at least one special character (e.g., !,@,#,$,*,;,&). ';
        }

        return ''; // Return an empty string if all checks pass
    };

    handleFocus = () => {
        this.setState({ showPasswordTips: true });
    };

    handleBlur = () => {
        this.setState({ showPasswordTips: false });
    };

    render() {
        const { memberInfo, password1, password2, prefix, firstname, lastname, firstnameth, lastnameth, mobile, bdDay, bdMonth, agree1, agree2, registerMsg, registerMsgPwd, registerMsgPwd2, registerMsgFname, registerMsgLname, submitting, renderCheck, showPasswordTips, registerMsgAgree, cssPopupBD, registerMsgMobile } = this.state;

        const getDaysInMonth = (month) => {
            const daysInMonth = {
                '01': 31, // January
                '02': 29, // February
                '03': 31, // March
                '04': 30, // April
                '05': 31, // May
                '06': 30, // June
                '07': 31, // July
                '08': 31, // August
                '09': 30, // September
                '10': 31, // October
                '11': 30, // November
                '12': 31, // December
            };

            return daysInMonth[month] || 31; // Default to 31 if month is not selected
        };

        const days = Array.from({ length: getDaysInMonth(bdMonth) }, (_, i) => i + 1);

        return (
            <>
                <EnNavHeader />
                {memberInfo && (
                    <main id="main">
                        <section className="top-sr-bg bg-gray1">
                            <div className="container">
                                <div className="row justify-content-between">
                                    <div className="col col-md-6">
                                        <h1 className="text-headpage text-white mb-0 pt-3 pb-3 "><span className="d-none">Starbucks&reg; Rewards</span><img src="media/rewards/starbucks-rewards-logo-2.png" alt="Starbucks&reg; Rewards" className="img-fluid" style={{ maxWidth: '158px' }} /></h1>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {renderCheck ? (
                            <>
                                <section className="section-create-account overflow-hidden pb-5">
                                    <div className="container p-3 p-md-0">
                                        <div className="row">
                                            <div className="col-md-12 mb-5 pt-3 text-start">
                                                <h2 className="h3 mt-5 mb-4 fw-bold text-dark-212121">Create an account</h2>
                                                <form className="form-sr" onSubmit={this.handleSubmit}>
                                                    <div className="row">
                                                        <div className="col-md-4 mb-4">
                                                            <label className="form-label d-none">Username.</label>
                                                            <h5 className="fw-semibold text-green-1">{memberInfo.responseMessage}</h5>
                                                            <div className="form-text">This will be your username.</div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4 mb-3">
                                                            <label className="form-label">Password</label>
                                                            <div className="input-group mb-3">
                                                                <input
                                                                    id="password-field"
                                                                    type="password"
                                                                    className="form-control border-end-0"
                                                                    placeholder="*Password" name="password1"
                                                                    value={password1}
                                                                    onChange={this.handleChange}
                                                                    onFocus={this.handleFocus}
                                                                    onBlur={this.handleBlur}
                                                                />
                                                                <span className="input-group-text"><i toggle="input[name='password1']" className="fa fa-fw fa-eye-slash toggle-password" /></span>
                                                                <div className="invalid-feedback" style={{ display: 'block' }}>{registerMsgPwd}</div>
                                                                {showPasswordTips && (
                                                                    <div id="new-password-field-tips" className="passwordtips-box">
                                                                        <h4 className="h6 text-decoration-underline">Password Tips</h4>
                                                                        <p className="form-text">
                                                                            From 9 to 15 characters<br />
                                                                            At least one number<br />
                                                                            At least one capital letter<br />
                                                                            At least one lower case letter<br />
                                                                            At least one Special characters eg. !,@,#$*,&
                                                                        </p>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4 mb-3">
                                                            <label className="form-label">Confirm Password</label>
                                                            <div className="input-group mb-3">
                                                                <input id="password-confirm-field" type="password" className="form-control border-end-0" placeholder="*Confirm Password" name="password2" value={password2} onChange={this.handleChange} />
                                                                <span className="input-group-text"><i toggle="input[name='password2']" className="fa fa-fw fa-eye-slash toggle-password" /></span>
                                                                <div className="invalid-feedback" style={{ display: 'block' }}>{registerMsgPwd2}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="text-start mt-1 text-alert-2">
                                                            
                                                        </div>
                                                    </div>
                                                    <h4 className="h4 mt-4 mb-3"><strong>Personal Particular</strong></h4>
                                                    <div className="row">
                                                        <div className="col-md-4 mb-3">
                                                            <label className="form-label">Prefix (optional)</label>
                                                            <select className="form-select" name="prefix" value={prefix} onChange={this.handleChange}>
                                                                <option value="">Please select</option>
                                                                <option value="Mr.">Mr. </option>
                                                                <option value="Mrs.">Mrs. </option>
                                                                <option value="Ms">Ms </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4 mb-3">
                                                            <label className="form-label">Name (English)</label>
                                                            <input type="text" className="form-control" placeholder="Name (English)" name="firstname" value={firstname} onChange={this.handleChange} />
                                                            <div className="text-start mt-1 text-alert-2">
                                                                {registerMsgFname}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 mb-3">
                                                            <label className="form-label">Last Name (English)</label>
                                                            <input type="text" className="form-control" placeholder="Last Name (English)" name="lastname" value={lastname} onChange={this.handleChange} />
                                                            <div className="text-start mt-1 text-alert-2">
                                                                {registerMsgLname}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4 mb-3">
                                                            <label className="form-label">ชื่อ ภาษาไทย (ไม่จำเป็นต้องระบุ)</label>
                                                            <input type="text" className="form-control" placeholder="ชื่อภาษาไทย (ไม่จำเป็นต้องระบุ)" name="firstnameth" value={firstnameth} onChange={this.handleChange} />
                                                        </div>
                                                        <div className="col-md-4 mb-3">
                                                            <label className="form-label">นามสกุล ภาษาไทย (ไม่จำเป็นต้องระบุ)</label>
                                                            <input type="text" className="form-control" placeholder="นามสกุลภาษาไทย (ไม่จำเป็นต้องระบุ)" name="lastnameth" value={lastnameth} onChange={this.handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4 mb-3">
                                                            <label className="form-label">Mobile Number (optional)</label>
                                                            <input type="number" className="form-control" placeholder="Mobile Number (optional)" name="mobile" value={mobile} onChange={this.handleChange} />
                                                            <div className="text-start mt-1 text-alert-2">
                                                                {registerMsgMobile}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12 mb-3">
                                                            <hr className="my-3" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6 mb-3">
                                                            <p className="h6"><strong>Birthday (optional)</strong><br />
                                                                <span className="small">Make your birthday special once you reach Gold level with a reward on us.</span></p>
                                                            <div className="row g-3">                                                                
                                                                <div className="col-md-3 mb-3">
                                                                    <select className="form-select" name="bdMonth" value={bdMonth} onChange={this.handleChange}>
                                                                        <option value="">Month</option>
                                                                        <option value="01">Jan</option>
                                                                        <option value="02">Feb</option>
                                                                        <option value="03">Mar</option>
                                                                        <option value="04">Apr</option>
                                                                        <option value="05">May</option>
                                                                        <option value="06">Jun</option>
                                                                        <option value="07">Jul</option>
                                                                        <option value="08">Aug</option>
                                                                        <option value="09">Sep</option>
                                                                        <option value="10">Oct</option>
                                                                        <option value="11">Nov</option>
                                                                        <option value="12">Dec</option>
                                                                    </select>
                                                                </div>
                                                                <div className="col-md-3 mb-3">
                                                                    <select className="form-select" name="bdDay" value={bdDay} onChange={this.handleChange}>
                                                                        <option value="">Day</option>
                                                                        {days.map((day) => (
                                                                            <option key={day} value={day}>
                                                                                {day}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12 mb-3">
                                                            <hr className="mt-2 mb-3" />
                                                        </div>
                                                    </div>
                                                    <div className="row form-check-term">
                                                        <div className="col-md-12 mb-3">
                                                            <p className="h6"><strong>Email Preference</strong></p>
                                                            <div className="form-check">
                                                                <input type="checkbox" className="form-check-input" name="agree1" checked={agree1}
                                                                    onChange={this.handleChange} />
                                                                <label className="form-check-label ms-3 d-block"><span className="pb-3" style={{ fontWeight: 600, fontSize: '15px' }}>I wish to receive any direct marketing communications from Starbucks Thailand.</span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className="font-15px">By clicking “Create an account” you agree to our <a href="https://www.starbucks.co.th/online-policies/terms-of-use/" target="_blank">Terms of Use</a> and <a href="https://www.starbucks.co.th/online-policies/privacy-notice/" target="_blank">Privacy Policy</a>.</p>
                                                    <div className="text-start mt-4">                                                        
                                                        <button type="submit" className="btn btn-primary rounded-pill px-4 text-decoration-none" disabled={submitting}>
                                                            {submitting ? 'Create an account...' : 'Create an account'}
                                                        </button>

                                                        <div className={cssPopupBD} id="BirthdayModal" tabIndex={-1}>
                                                            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h1 className="modal-title fs-5" id="BirthdayModalLabel">You’ll miss birthday reward?</h1>
                                                                        <button type="button" className="btn-close d-none" data-bs-dismiss="modal" aria-label="Close" onClick={this.cancelSubmit} />
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <p>Birthday rewards are one of the perks for Gold level members. Are you sure you want to leave your birthday blank?</p>
                                                                    </div>
                                                                    <div className="modal-footer">
                                                                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={this.handleSubmit2}>Yes</button>
                                                                        &nbsp;&nbsp;
                                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={this.cancelSubmit}>No</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="text-start mt-2 text-alert-2">
                                                        {registerMsg}
                                                        {registerMsgAgree}
                                                    </div>                                                    
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </>
                        ) : (
                            <>
                                {/*Loading*/}
                                <div className="col-md-5">
                                    <div className="text-center" style={{ height: '160px' }} />
                                    <div className="text-center mt-5 pt-5 mb-5 pb-5">
                                        <h3 className="text-white">LOADING ...</h3>
                                        <img src="media/loading-gif.gif" alt="Loading..." width={80} className="img-fluid" />
                                    </div>
                                    <div style={{ height: '100px' }} />
                                </div>
                            </>
                        )}
                    </main>
                )}
                <EnNavFooter />
            </>
        );
    }
}

export const EnAuthJoinDetail = withRouter(EnAuthJoinDetailClass);