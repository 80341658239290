import React, { Component } from 'react';

import { ThNavHeader } from './ThNavHeader';
import { ThNavFooter } from './ThNavFooter';
import ThUserMenu from './ThUserMenu';

import { getClientId } from './utils/analyticsclientIdUtils';
import { encryptData } from './utils/encryptionUtils';
import { API_URL } from './utils/constants'

export class ThUserCardAdd extends Component {
    static displayName = ThUserCardAdd.name;

    constructor(props) {
        super(props);
        this.state = {
            userData: null, // Initialize userData state to null
            cardNickname: '',
            cardNumber: '',
            cardPin: '',
            submitting: false, // Add a state variable to track form submission status
            submitMsg: '',
            submitMsgSuccess: '',
            requireMsg01: '',
            requireMsg02: '',
            requireMsg03: ''
        };
    }

    componentDidMount() {
        // Retrieve data from session storage
        const userDataFromStorage = sessionStorage.getItem('userData');

        // Parse the data if it exists and set it to the component state
        if (userDataFromStorage) {
            this.setState({ userData: JSON.parse(userDataFromStorage) });
        }

        {
            // Google analytic
            const documentPath = "TH Card add"; // Gets the current URL path
            const clientId = getClientId(); // Use the getClientId function

            // Push data to the GTM dataLayer
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'pageview',
                page: documentPath,
                clientId: clientId,
            });
        }
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleSubmit = async e => {
        e.preventDefault();
        const { userData, cardNickname, cardNumber, cardPin } = this.state;

        let canSubmit = true;

        if (!cardNumber) {
            this.setState({
                requireMsg02: 'กรุณากรอกหมายเลขบัตร'
            });
            canSubmit = false;
        }
        else {
            if (cardNumber.length !== 16) {
                this.setState({
                    requireMsg02: 'กรุณากรอกหมายเลขเลขบัตรให้ถูกต้อง'
                });
                canSubmit = false;
            }
        }

        if (!cardPin) {
            this.setState({
                requireMsg03: 'กรุณากรอกรหัสบัตร (CSC) บนหลังบัตร'
            });
            canSubmit = false;
        }
        else {
            if (cardPin.length !== 8) {
                this.setState({
                    requireMsg03: 'รหัสบัตร (CSC) ไม่ถูกต้อง'
                });
                canSubmit = false;
            }
        }

        if (canSubmit == false) {
            return;
        }

        // Generate Unix timestamp
        const timestamp = Math.floor(Date.now() / 1000);

        // Prepare and encrypt the payload
        const payload = { LoyaltyMemberId: userData.loyaltyMemberId, CardNickName: cardNickname, CardNumber: cardNumber, CardPin: cardPin, RefLang: 'th', Timestamp: timestamp };
        const encryptedPayload = encryptData(payload);

        // Prevent double-clicking by disabling the submit button and changing cursor to 'wait'
        this.setState({ submitting: true });

        // Add the wait cursor class to the body element
        document.body.classList.add('wait-cursor');

        const token = this.state.userData.token;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ Data: encryptedPayload })
        };

        try {
            const response = await fetch(`${API_URL}/api/CrmMemberAddCard`, requestOptions);
            const data = await response.json();

            if (data.responseCode === "OK") {
                this.setState({ submitMsgSuccess: "เพิ่มบัตรสตาร์บัคส์สำเร็จ" });
            }
            else {
                this.setState({ submitMsg: data.responseMessage });
            }
        } catch (error) {
            console.error('Error during api:', error);
            // Handle error appropriately, e.g., show an error message to the user
        } finally {
            // Enable the submit button and change cursor back to 'auto' after form submission completes
            this.setState({ submitting: false });
            document.body.classList.remove('wait-cursor'); // Remove the wait cursor class
        }
    };

    render() {
        const { userData, cardNickname, cardNumber, cardPin, submitting, submitMsg, requireMsg01, requireMsg02, requireMsg03, submitMsgSuccess } = this.state;

        return (
            <>
                <ThNavHeader />

                {/* Render the user data */}
                {userData && (
                    <main id="main">
                        <section id="profile_section" className="profileBanner keyboardFocus" aria-label="Profile" tabIndex={-1}>
                            <ThUserMenu />
                        </section>
                        <section className="section-dashboard overflow-hidden pb-5">
                            <div className="container mt-4">
                                <div className="row mb-5 px-2">
                                    <div className="col-md-8">
                                        <h2 className="h4 fw-semibold mt-4">เพิ่มบัตรสตาร์บัคส์</h2>
                                        <p className="mb-4">เพิ่มบัตรสตาร์บัคส์ เพื่อสั่งเมนูโปรดและแลกรับสิทธิประโยชน์</p>
                                        <form className="form-sr" onSubmit={this.handleSubmit}>
                                            <div className="row">
                                                <div className="col-md-4 mb-3">
                                                    <label className="form-label">ชื่อบัตรสตาร์บัคส์ (ไม่จำเป็นต้องระบุ)</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder=""
                                                        name="cardNickname"
                                                        value={cardNickname}
                                                        onChange={this.handleChange}
                                                    />
                                                    <div class="text-alert-2">{requireMsg01}</div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4 mb-3">
                                                    <label className="form-label">หมายเลขบัตร *</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder=""
                                                        name="cardNumber"
                                                        value={cardNumber}
                                                        onChange={this.handleChange}
                                                    />
                                                    <div class="text-alert-2">{requireMsg02}</div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4 mb-3">
                                                    <label className="form-label">รหัสบัตร (CSC)*</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder=""
                                                        name="cardPin"
                                                        value={cardPin}
                                                        onChange={this.handleChange}
                                                    />
                                                    <div class="text-alert-2">{requireMsg03}</div>
                                                </div>
                                            </div>
                                            <div className="text-start mt-4">
                                                <button type="submit" className="btn btn-primary rounded-pill px-4 text-decoration-none" disabled={submitting}>
                                                    {submitting ? 'เพิ่มบัตร...' : 'เพิ่มบัตร'}
                                                </button>
                                            </div>
                                            <div className="text-start">
                                                <span className="text-green-1">{submitMsgSuccess}</span><span className="text-alert-2">{submitMsg}</span>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>
                )}

                <ThNavFooter />
            </>
        );
    }
}
