import React, { Component } from 'react';

import { EnNavHeader } from './EnNavHeader';
import { EnNavFooter } from './EnNavFooter';
import { EnSharedComponentBenefit01 } from './EnSharedComponentBenefit01';
import EnUserMenu from './EnUserMenu';

import { getClientId } from './utils/analyticsclientIdUtils';
import { encryptData } from './utils/encryptionUtils';
import { API_URL } from './utils/constants';

import imgStar from './media/rewards/SR_Stars_gold_large.svg'

export class EnUserRewardGold extends Component {
    static displayName = EnUserRewardGold.name;

    constructor(props) {
        super(props);
        this.state = {
            userData: null, // Initialize userData state to null
            barWidth: '1%', // Initial width value
            barwidth2: '5%', // Default width as a string
            bar2Label: '',
            transactionThismonth: [],
            transactionExpire: [],
            cssMtr01: false, // Default css hide/show for MTR promo info
            cssMtr02: false,
            cssMtr03: false,
            cssMtr04: false,
            cssMtr05: false,
            circleMeterColor01: 'circle-gray',
            circleMeterColor02: 'circle-gray',
            circleMeterColor03: 'circle-gray',
            circleMeterColor04: 'circle-gray',
            circleMeterColor05: 'circle-gray',
            promotions: [],
            rewards: []
        };
    }

    componentDidMount() {
        // Retrieve data from session storage
        const userDataFromStorage = sessionStorage.getItem('userData');

        // Parse the data if it exists and set it to the component state
        if (userDataFromStorage) {
            this.setState({ userData: JSON.parse(userDataFromStorage) }, () => {
                // fetch CrmAuthGetRewardLevel             
                this.callApiGetRewardLevel(this.state.userData.loyaltyMemberId);                

                // After setting the state, make the API call if userData is available
                if (this.state.userData && this.state.userData.loyaltyMemberId) {
                    this.callApiWithUserId(this.state.userData.loyaltyMemberId);
                    this.callApiWithUserId2(this.state.userData.loyaltyMemberId);
                }
            });
        }

        {
            // Google analytic
            const documentPath = "RewardGold"; // Gets the current URL path
            const clientId = getClientId(); // Use the getClientId function

            // Push data to the GTM dataLayer
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'pageview',
                page: documentPath,
                clientId: clientId,
            });
        }
    }

    async callApiWithUserId(userId) {
        // Generate Unix timestamp
        const timestamp = Math.floor(Date.now() / 1000);

        // Prepare and encrypt the payload
        const payload = { UserId: userId, RefLang: "en", Timestamp: timestamp };
        const encryptedPayload = encryptData(payload);

        const token = this.state.userData.token;
        
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ Data: encryptedPayload })
        };

        try {
            const response = await fetch(`${API_URL}/api/CrmMemberStarRewardExpiration`, requestOptions);
            const data = await response.json();

            if (data) {
                if (data && Array.isArray(data)) {
                    //filter starRewardExpirations to separate This month and Other month
                    const currentMonth = new Date().getMonth();
                    const filteredThisMonth = data.filter(item => {
                        // Ensure expiryDate is a Date object
                        const expiryDate = new Date(item.expiryDate);
                        return expiryDate.getMonth() === currentMonth;
                    });
                    const sortedThismonthList = filteredThisMonth.sort((a, b) => new Date(a.transactionDate) - new Date(b.transactionDate));

                    const filteredNotThismonth = data.filter(item => {
                        // Ensure expiryDate is a Date object
                        const expiryDate = new Date(item.expiryDate);
                        return expiryDate.getMonth() !== currentMonth;
                    });
                    const sortedExpireList = filteredNotThismonth.sort((a, b) => new Date(a.expiryDate) - new Date(b.expiryDate));

                    this.setState({ transactionThismonth: sortedThismonthList, transactionExpire: sortedExpireList });
                }
                else
                {
                    console.error('Error data:none');
                }
            }
            else {
                console.error('Error data:');
            }
        } catch (error) {
            console.error('API call error :', error);
        } finally {

        }
    }

    async callApiWithUserId2(userId) {
        // Generate Unix timestamp
        const timestamp = Math.floor(Date.now() / 1000);

        const payload = { LoyaltyMemberId: userId, RefLang: "en", Timestamp: timestamp };
        const encryptedPayload = encryptData(payload);

        const token = this.state.userData.token;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ Data: encryptedPayload })
        };

        try {
            const response = await fetch(`${API_URL}/api/CrmMemberGetAllBenefit`, requestOptions);
            const data = await response.json();

            if (data.responseCode === "00") {
                if (Array.isArray(data.promotionLists.promotions)) {
                    this.setState({ promotions: data.promotionLists.promotions });
                }

                if (Array.isArray(data.promotionLists.rewards)) {
                    this.setState({ rewards: data.promotionLists.rewards });
                }
            }
            else {
                console.error('Error data:');
            }
        } catch (error) {
            console.error('API call error :', error);
        }
    }

    async callApiGetRewardLevel(userId) {
        // Generate Unix timestamp
        const timestamp = Math.floor(Date.now() / 1000);

        const payload = { LoyaltyMemberId: userId, RefLang: "en", Timestamp: timestamp };
        const encryptedPayload = encryptData(payload);

        const token = this.state.userData.token;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ Data: encryptedPayload })
        };

        try {
            const response = await fetch(`${API_URL}/api/CrmAuthGetRewardLevel`, requestOptions);
            const data = await response.json();

            if (data) {
                data.firstName = this.state.userData.firstName;
                this.setState({ userData: data });

                const starRewardBalance = parseFloat(data.starRewardBalance.replace(/,/g, ''));

                //Determine which MTR pro to show
                let cssMtr01, cssMtr02, cssMtr03, cssMtr04, cssMtr05;
                switch (true) {
                    case starRewardBalance >= 350:
                        cssMtr01 = true; cssMtr02 = true; cssMtr03 = true; cssMtr04 = true; cssMtr05 = true;
                        break;
                    case starRewardBalance >= 160:
                        cssMtr01 = true; cssMtr02 = true; cssMtr03 = true; cssMtr04 = true; cssMtr05 = false;
                        break;
                    case starRewardBalance >= 120:
                        cssMtr01 = true; cssMtr02 = true; cssMtr03 = true; cssMtr04 = false; cssMtr05 = false;
                        break;
                    case starRewardBalance >= 60:
                        cssMtr01 = true; cssMtr02 = true; cssMtr03 = false; cssMtr04 = false; cssMtr05 = false;
                        break;
                    case starRewardBalance >= 20:
                        cssMtr01 = true; cssMtr02 = false; cssMtr03 = false; cssMtr04 = false; cssMtr05 = false;
                        break;
                    default:
                        cssMtr01 = false; cssMtr02 = false; cssMtr03 = false; cssMtr04 = false; cssMtr05 = false;
                        break;
                }

                // Determine data-width based on varValue using switch
                let dataWidth, circleMeterColor01, circleMeterColor02, circleMeterColor03, circleMeterColor04, circleMeterColor05;
                circleMeterColor01 = 'circle-gray';
                circleMeterColor02 = 'circle-gray';
                circleMeterColor03 = 'circle-gray';
                circleMeterColor04 = 'circle-gray';
                circleMeterColor05 = 'circle-gray';

                circleMeterColor01 = starRewardBalance >= 20 ? 'circle-gold' : circleMeterColor01;
                circleMeterColor02 = starRewardBalance >= 60 ? 'circle-gold' : circleMeterColor02;
                circleMeterColor03 = starRewardBalance >= 120 ? 'circle-gold' : circleMeterColor03;
                circleMeterColor04 = starRewardBalance >= 160 ? 'circle-gold' : circleMeterColor04;
                circleMeterColor05 = starRewardBalance >= 350 ? 'circle-gold' : circleMeterColor05;

                switch (true) {
                    case starRewardBalance <= 5:
                        dataWidth = "1%";
                        break;
                    case starRewardBalance <= 10:
                        dataWidth = "4%";
                        break;
                    case starRewardBalance <= 20:
                        dataWidth = "12%";
                        break;
                    case starRewardBalance <= 30:
                        dataWidth = "13%";
                        break;
                    case starRewardBalance <= 40:
                        dataWidth = "15%";
                        break;
                    case starRewardBalance <= 50:
                        dataWidth = "20%";
                        break;
                    case starRewardBalance <= 60:
                        dataWidth = "31%";
                        break;
                    case starRewardBalance <= 70:
                        dataWidth = "34%";
                        break;
                    case starRewardBalance <= 80:
                        dataWidth = "37%";
                        break;
                    case starRewardBalance <= 90:
                        dataWidth = "40%";
                        break;
                    case starRewardBalance <= 100:
                        dataWidth = "43%";
                        break;
                    case starRewardBalance <= 110:
                        dataWidth = "44%";
                        break;
                    case starRewardBalance <= 120:
                        dataWidth = "51%";
                        break;
                    case starRewardBalance <= 140:
                        dataWidth = "52%";
                        break;
                    case starRewardBalance <= 150:
                        dataWidth = "59%";
                        break;
                    case starRewardBalance <= 160:
                        dataWidth = "71%";
                        break;
                    case starRewardBalance <= 190:
                        dataWidth = "74%";
                        break;
                    case starRewardBalance <= 250:
                        dataWidth = "82%";
                        break;
                    case starRewardBalance <= 270:
                        dataWidth = "84%";
                        break;
                    case starRewardBalance <= 300:
                        dataWidth = "86%";
                        break;
                    case starRewardBalance <= 320:
                        dataWidth = "90%";
                        break;
                    case starRewardBalance <= 350:
                        dataWidth = "93%";
                        break;
                    case starRewardBalance <= 400:
                        dataWidth = "97%";
                        break;
                    case starRewardBalance > 400:
                        dataWidth = "100%";
                        break;
                    default:
                        dataWidth = "1%";
                        break;
                }

                // Set data-width attribute
                window.$(".bar").attr("data-width", dataWidth);

                // Use jQuery directly as it's globally available
                window.$(function () {
                    window.$(".accordion-image img:eq(0)").nextAll().hide();
                    window.$(".accordion350tab button").click(function (e) {
                        var imageId = window.$(this).attr('data-image');
                        window.$(".accordion-image #" + imageId).show().siblings().hide();
                    });

                    window.$(".bar").each(function () {
                        window.$(this).find(".bar-inner").animate({
                            width: window.$(this).attr("data-width")
                        }, 2000);
                    });
                });

                let dataWidth2;
                const starBalance = parseFloat(data.starBalance);
                switch (true) {
                    case starBalance <= 5:
                        dataWidth2 = "1%";
                        break;
                    case starBalance <= 20:
                        dataWidth2 = "7%";
                        break;
                    case starBalance <= 30:
                        dataWidth2 = "13%";
                        break;
                    case starBalance <= 50:
                        dataWidth2 = "25%";
                        break;
                    case starBalance <= 100:
                        dataWidth2 = "40%";
                        break;
                    case starBalance <= 150:
                        dataWidth2 = "50%";
                        break;
                    case starBalance <= 200:
                        dataWidth2 = "70%";
                        break;
                    case starBalance <= 250:
                        dataWidth2 = "85%";
                        break;
                    case starBalance <= 280:
                        dataWidth2 = "95%";
                        break;
                    case starBalance >= 300:
                        dataWidth2 = "100%";
                        break;
                    default:
                        dataWidth2 = "1%";
                        break;
                }

                this.setState({ barwidth2: `${dataWidth2}`, bar2Label: starBalance, circleMeterColor01: circleMeterColor01, circleMeterColor02: circleMeterColor02, circleMeterColor03: circleMeterColor03, circleMeterColor04: circleMeterColor04, circleMeterColor05: circleMeterColor05, cssMtr01: cssMtr01, cssMtr02: cssMtr02, cssMtr03: cssMtr03, cssMtr04: cssMtr04, cssMtr05: cssMtr05 });
            }
            else {
                console.error('API call with no data return');
            }
        } catch (error) {
            console.error('API call error :', error);
        }
    }

    render() {
        const { userData, barWidth, barwidth2, bar2Label, transactionThismonth, transactionExpire, cssMtr01, cssMtr02, cssMtr03, cssMtr04, cssMtr05, promotions, rewards, circleMeterColor01, circleMeterColor02, circleMeterColor03, circleMeterColor04, circleMeterColor05 } = this.state;
        let starsToRetain = 0; // Default value if userData or starsToRetain is undefined

        if (userData && userData.starsToRetain !== undefined) {
            starsToRetain = parseFloat(userData.starsToRetain);
        }

        return (
            <>
                <EnNavHeader />

                {/* Render the user data */}
                {userData && (
                    <main id="main">
                        <section id="profile_section" className="profileBanner keyboardFocus" aria-label="Profile" tabIndex={-1}>
                            <EnUserMenu />
                        </section>
                        <section className="section-dashboard overflow-hidden pb-5">
                            <div className="container mt-4">
                                <div className="row mb-5 px-2">
                                    <div className="col-md-6">
                                        <div className="row mb-5 px-2">
                                            <div className="col-md-12">
                                                <h3 className="fw-semibold">Rewards Status</h3>
                                                <div className="card mt-3 p-3 p-md-4">
                                                    <div className="rewards level_gold">
                                                        <div className="rewards__container pe-4">
                                                            <div className="position-relative">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div>
                                                                            <span className="rewards_details--icon_container fs-1">
                                                                                <strong>{userData.starRewardBalance}</strong> <img src="media/rewards/SR_Stars_gold_large.svg" alt="Gold" style={{ width: '24px' }} />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <h4 className="mt-3">Stars earned to redeem Rewards</h4>
                                                            </div>
                                                            <div className="star-earned-bar star-gold">
                                                                <div className="bar" data-width={barWidth}>
                                                                    <div className="bar-inner" />
                                                                    <div className="text-num t20"><div className={circleMeterColor01} />20</div>
                                                                    <div className="text-num t60"><div className={circleMeterColor02} />60</div>
                                                                    <div className="text-num t120"><div className={circleMeterColor03} />120</div>
                                                                    <div className="text-num t160"><div className={circleMeterColor04} />160</div>
                                                                    <div className="text-num t350"><div className={circleMeterColor05} />350</div>
                                                                </div>
                                                            </div>
                                                            <p className="d-none">*{userData.starRewardBalance} Star(s) will be expired on {userData.anniversaryDate}</p>
                                                            <div className="mb-3">
                                                                <a href="#" className="btn btn-outline-rewardsGold rounded-pill" data-bs-toggle="modal" data-bs-target="#Rewards_details">Rewards details</a>
                                                                {/* The Modal */}
                                                                <div className="modal" id="Rewards_details">
                                                                    <div className="modal-dialog modal-xl modal-dialog-centered">
                                                                        <div className="modal-content">
                                                                            {/* Modal Header */}
                                                                            <div className="modal-header">
                                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" />
                                                                            </div>
                                                                            {/* Modal body */}
                                                                            <div className="modal-body">
                                                                                <EnSharedComponentBenefit01 />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-5 px-2">
                                            <div className="col-md-12">
                                                <h3 className="fw-semibold">Membership Status</h3>
                                                <div className="card mt-3 p-3 p-md-4">
                                                    <div className="rewards level_gold">
                                                        <div className="rewards__container">
                                                            <div className="position-relative">
                                                                <h4 className="text-gold mt-3 mb-3 fw-semibold">Gold Level</h4>
                                                            </div>
                                                            {starsToRetain === 0 ? (
                                                                <>
                                                                    <h4 className="mb-1">Congratulations! </h4>
                                                                    <p className="mb-1">You're Gold member for another year.</p>
                                                                    <p className="text-gold">Gold level until {userData.anniversaryDate}</p>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className="row g-4 align-items-center">
                                                                        <div className="col-9 col-md-10">
                                                                            <div className="membership-status mb-3">
                                                                                <div className="skills star-status status-gold" style={{ width: barwidth2 }}><img src={imgStar} alt="Gold" className="img-star-status d-none" /></div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-3 col-md-2 mt-3 pe-0">
                                                                            <span className="text-black">{bar2Label}/300</span>
                                                                        </div>
                                                                    </div>
                                                                        <p className="mb-3">Earn {300 - userData.starBalance} Star(s) by {userData.anniversaryDate} for another year of Gold</p>
                                                                </>
                                                            )}
                                                            <div className="mb-3">
                                                                <a href="#" className="btn btn-outline-rewardsGold rounded-pill" data-bs-toggle="modal" data-bs-target="#Member_Benefits">Member Benefits</a>
                                                                {/* The Modal */}
                                                                <div className="modal" id="Member_Benefits">
                                                                    <div className="modal-dialog modal-lg modal-dialog-centered">
                                                                        <div className="modal-content">
                                                                            {/* Modal Header */}
                                                                            <div className="modal-header">
                                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" />
                                                                            </div>
                                                                            {/* Modal body */}
                                                                            <div className="modal-body">
                                                                                <section className="section-collect2 pb-5 bg-white">
                                                                                    <div className="container">
                                                                                        <div className="row justify-content-center">
                                                                                            <div className="col-md-8 mb-2 p-0">
                                                                                                <h2 className="h3 fw-bold text-dark-212121">Member Benefits</h2>
                                                                                                <h4 className="text-gold mt-4 mb-1 fw-semibold">Gold Level</h4>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row justify-content-center gx-5 text-dark-000000">
                                                                                            <div className="col-md-8 border-top p-0">
                                                                                                <div className="row">
                                                                                                    <div className="col-3">
                                                                                                        <div className="p-2"><img src="media/rewards/img-box3-Celebrate-with-a-birthday-treat.png" alt="Celebrate with a birthday treat" className="img-fluid" /></div>
                                                                                                    </div>
                                                                                                    <div className="col-9 py-3 pb-0">
                                                                                                        <h4 className="fs-5 fw-bold">Celebrate with a birthday treat</h4>
                                                                                                        <p className="text-gray-2"><span className="small">Free Slice of cake with drink in birthday month.</span></p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-8 border-top border-bottom p-0">
                                                                                                <div className="row">
                                                                                                    <div className="col-3">
                                                                                                        <div className="p-2"><img src="media/rewards/img-box3-Get-a-Starbucks-Rewards-Gift.png" alt="Get a Starbucks Rewards Gift" className="img-fluid" /></div>
                                                                                                    </div>
                                                                                                    <div className="col-9 py-3 pb-0">
                                                                                                        <h4 className="fs-5 fw-bold">Starbucks&reg; Rewards Gift</h4>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </section>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mb-5 px-2">
                                            <div className="col-md-12">
                                                <h3 className="fw-semibold">Your Rewards</h3>
                                                {cssMtr01 && (
                                                    <div className="card mt-3 p-3">
                                                        <div className="row">
                                                            <div className="col-3 col-md-2 text-center p-0">
                                                                <div className="m-0"><img src="media/rewards/mtr/ReReward-for-MTR-20-Stars.png" alt="Free upsize" className="img-fluid" /></div>
                                                            </div>
                                                            <div className="col-9 col-md-10">
                                                                <p className="mb-1 text-green-1"><strong>A Free Upsize on Your Drink</strong></p>
                                                                <p className="text-black small mb-1">Get a free upsize on your favorite drink (Tall/Grande size).</p>
                                                                <div>
                                                                    <p className="mt-2 mb-0 text-gray-2">
                                                                        <span>20</span> <img src="media/rewards/SR_Stars_gold_large.svg" alt="Gold" className="ms-1 star-after-point" style={{ width: '18px', marginTop: '-7px' }} />
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                {cssMtr02 && (
                                                    <div className="card mt-3 p-3">
                                                        <div className="row">
                                                            <div className="col-3 col-md-2 text-center p-0">
                                                                <div className="m-0"><img src="media/rewards/mtr/ReReward-for-MTR-60-Stars.png" alt="25% off drink/ food per item" className="img-fluid" /></div>
                                                            </div>
                                                            <div className="col-9 col-md-10">
                                                                <p className="mb-1 text-green-1"><strong>25% Off on Food/Drink</strong></p>
                                                                <p className="text-black small mb-1">Get 25% off on a regular-priced handcrafted drink or food item.</p>
                                                                <div>
                                                                    <p className="mt-2 mb-0 text-gray-2">
                                                                        <span>60</span> <img src="media/rewards/SR_Stars_gold_large.svg" alt="Gold" className="ms-1 star-after-point" style={{ width: '18px', marginTop: '-7px' }} />
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                {cssMtr03 && (
                                                    <div className="card mt-3 p-3">
                                                        <div className="row">
                                                            <div className="col-3 col-md-2 text-center p-0">
                                                                <div className="m-0"><img src="media/rewards/mtr/ReReward-for-MTR-120-Stars.png" alt="Free drink with 2 customizations" className="img-fluid" /></div>
                                                            </div>
                                                            <div className="col-9 col-md-10">
                                                                <p className="mb-1 text-green-1"><strong>A Free Drink on Us</strong></p>
                                                                <p className="text-black small mb-1">Get a free favorite handcrafted drink with 2 customizations.</p>
                                                                <div>
                                                                    <p className="mt-2 mb-0 text-gray-2">
                                                                        <span>120</span> <img src="media/rewards/SR_Stars_gold_large.svg" alt="Gold" className="ms-1 star-after-point" style={{ width: '18px', marginTop: '-7px' }} />
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                {cssMtr04 && (
                                                    <div className="card mt-3 p-3">
                                                        <div className="row">
                                                            <div className="col-3 col-md-2 text-center p-0">
                                                                <div className="m-0"><img src="media/rewards/mtr/ReReward-for-MTR-160-Stars.png" alt="200B off" className="img-fluid" /></div>
                                                            </div>
                                                            <div className="col-9 col-md-10">
                                                                <p className="mb-1 text-green-1"><strong>200 Baht Off</strong></p>
                                                                <p className="text-black small mb-1">Get 200 Baht off with a minimum purchase of 500 Baht/bill.</p>
                                                                <div>
                                                                    <p className="mt-2 mb-0 text-gray-2">
                                                                        <span>160</span> <img src="media/rewards/SR_Stars_gold_large.svg" alt="Gold" className="ms-1 star-after-point" style={{ width: '18px', marginTop: '-7px' }} />
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                {cssMtr05 && (
                                                    <>
                                                        <div className="card mt-3 p-3">
                                                            <div className="row">
                                                                <div className="col-3 col-md-2 text-center p-0">
                                                                    <div className="m-0"><img src="media/rewards/mtr/ReReward-for-MTR-350-Stars-50-Off.png" alt="50% off drink/ food" className="img-fluid" /></div>
                                                                </div>
                                                                <div className="col-9 col-md-10">
                                                                    <p className="mb-1 text-green-1"><strong>50% Off on Your Purchase</strong></p>
                                                                    <p className="text-black small mb-1">Get 50% off up to 600 Baht/bill.</p>
                                                                    <div>
                                                                        <p className="mt-2 mb-0 text-gray-2">
                                                                            <span>350</span> <img src="media/rewards/SR_Stars_gold_large.svg" alt="Gold" className="ms-1 star-after-point" style={{ width: '18px', marginTop: '-7px' }} />
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="card mt-3 p-3">
                                                            <div className="row">
                                                                <div className="col-3 col-md-2 text-center p-0">
                                                                    <div className="m-0"><img src="media/rewards/mtr/ReReward-for-MTR-350-Stars-Free-4-Drinks.png" alt="Free 4 Drinks" className="img-fluid" /></div>
                                                                </div>
                                                                <div className="col-9 col-md-10">
                                                                    <p className="mb-1 text-green-1"><strong>4 Free Drinks on Us</strong></p>
                                                                    <p className="text-black small mb-1">Get 4 free handcrafted drinks with 2 customizations per drink/bill.</p>
                                                                    <div>
                                                                        <p className="mt-2 mb-0 text-gray-2">
                                                                            <span>350</span> <img src="media/rewards/SR_Stars_gold_large.svg" alt="Gold" className="ms-1 star-after-point" style={{ width: '18px', marginTop: '-7px' }} />
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}

                                                {cssMtr01 === false && cssMtr02 === false && cssMtr03 === false && cssMtr04 === false && cssMtr05 === false && (
                                                    <div className="card your-promotion mt-3 p-3 p-md-4">
                                                        <div className="row">
                                                            You have no Rewards available
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row mb-5 px-2">
                                            <div className="col-md-12">
                                                <h3 className="fw-semibold">Your Benefits</h3>
                                                {rewards.length > 0 ? (
                                                    rewards.map((item, index) => (
                                                        <div className="card mt-3 p-3" key={index}>
                                                            <div className="row">
                                                                <div className="col-3 col-md-2 text-center p-0">
                                                                    <div className="m-0"><img src={`media/rewards/mtr/${item.displayImg01}`} alt="benefit" className="img-fluid" /></div>
                                                                </div>
                                                                <div className="col-9 col-md-10">
                                                                    <p className="mb-1 text-green-1"><strong>{item.promotionName}</strong></p>
                                                                    <p className="text-black small mb-1">{item.rewardDescriptionEnglish}</p>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className="row">
                                                                <div className="col-3 col-md-3 text-center px-0">
                                                                    <p className="mb-1 text-gray-2 small">Status</p>
                                                                    <p className="fw-semibold">{item.status}</p>
                                                                </div>
                                                                <div className="col-3 col-md-3 text-center px-0">
                                                                    <p className="mb-1 text-gray-2 small">Start</p>
                                                                    <p className="fw-semibold">{item.startDate}</p>
                                                                </div>
                                                                <div className="col-3 col-md-3 text-center px-0">
                                                                    <p className="mb-1 text-gray-2 small">Expires</p>
                                                                    <p className="fw-semibold">{item.expiryDate}</p>
                                                                </div>
                                                                <div className="col-3 col-md-3 text-center px-0">
                                                                    <p className="mb-1 text-gray-2 small">QTY</p>
                                                                    <p className="fw-semibold">{item.frequencyAvailable}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div className="card your-promotion mt-3 p-3 p-md-4">
                                                        <div className="row">
                                                            You have no Benefit available
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row mb-5 px-2">
                                            <div className="col-md-12">
                                                <h3 className="fw-semibold">Your Promotion</h3>
                                                {promotions.length > 0 ? (
                                                    promotions.map((promotion, index) => (
                                                        <div key={index} className="card your-promotion mt-3 p-3 p-md-4">
                                                            <div className="row">
                                                                <div className="col-12 col-md-8">
                                                                    <p className="mb-1"><strong>{promotion.name}</strong></p>
                                                                    <p className="text-gray-2 small">
                                                                        {promotion.englishText1} {promotion.englishText2} {promotion.englishText3} {promotion.englishText4} {promotion.englishText5} {promotion.englishText6}
                                                                    </p>
                                                                </div>
                                                                <div className="col-6 col-md-2 text-center px-0">
                                                                    <p className="mb-1 text-gray-2 small">Start</p>
                                                                    <p className="fw-semibold">{promotion.displayPromotionStartDate}</p>
                                                                </div>
                                                                <div className="col-6 col-md-2 text-center px-0">
                                                                    <p className="mb-1 text-gray-2 small">End</p>
                                                                    <p className="fw-semibold">{promotion.displayPromotionEndDate}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div className="card your-promotion mt-3 p-3 p-md-4">
                                                        <div className="row">
                                                            You have no Promotion available
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row mb-5 px-2">
                                            <div className="col-md-8 offset-md-1">
                                                <h3 className="fw-semibold">Star Expiration</h3>
                                                <div className="card mt-3 p-3 p-md-4">

                                                    <div className="row">
                                                        <div className="col-10"><p className="mb-1 small"><strong>Expiring in This month</strong></p></div>
                                                        <div className="col-2 text-center mb-2"><img src="media/rewards/SR_Stars_gold_large.svg" alt="Expiring in This month" className="img-fluid" style={{ width: '15px' }} /></div>
                                                    </div>
                                                    {transactionThismonth.length > 0 ? (
                                                        <>
                                                            {transactionThismonth.map((item, index) => (
                                                                <div className="row" key={index}>
                                                                    <div className="col-10"><p className="mb-1"><strong>{item.expiryDateLabel}</strong></p></div>
                                                                    <div className="col-2 text-center"><p className="mb-3 fw-semibold">{item.starRewardExpiration}</p></div>
                                                                </div>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <p>No star expiring.</p>
                                                    )}
                                                    <hr className="mt-2 mb-4" />

                                                    <div className="row">
                                                        <div className="col-10"><p className="mb-1 small"><strong>Expiring Soon</strong></p></div>
                                                        <div className="col-2 text-center mb-2"><img src="media/rewards/SR_Stars_gold_large.svg" alt="Expiring Soon" className="img-fluid" style={{ width: '15px' }} /></div>
                                                    </div>
                                                    {transactionExpire.length > 0 ? (
                                                        <>
                                                            {transactionExpire.map((item, index) => (
                                                                <div className="row" key={index}>
                                                                    <div className="col-10"><p className="mb-1"><strong>{item.expiryDateLabel}</strong></p></div>
                                                                    <div className="col-2 text-center"><p className="mb-3 fw-semibold">{item.starRewardExpiration}</p></div>
                                                                </div>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <p>No star expiring.</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>
                )}

                <EnNavFooter />
            </>
        );
    }
}
