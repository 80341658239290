import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { ThNavHeader } from './ThNavHeader';
import { ThNavFooter } from './ThNavFooter';

import { getClientId } from './utils/analyticsclientIdUtils';

class ThAuthForgotThxClass extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);

        {
            // Google analytic
            const documentPath = "TH ForgotPwd - thank"; // Gets the current URL path
            const clientId = getClientId(); // Use the getClientId function

            // Push data to the GTM dataLayer
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'pageview',
                page: documentPath,
                clientId: clientId,
            });
        }
    }

    render() {

    return (
        <>
            <ThNavHeader />

            <main id="main">
                <section className="top-sr-bg bg-gray1">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col col-md-6">
                                <h1 className="text-headpage text-white mb-0 pt-3 pb-3 "><span className="text-black">สตาร์บัคส์ รีวอร์ด</span></h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-sign-in overflow-hidden pb-5">
                    <div className="container-fluid p-3 p-md-0">
                        <div className="row">
                            <div className="col-md-12 mb-4 pt-3 text-center">
                                <h2 className="h3 mt-5 mb-3 fw-bold text-dark-212121">โปรดตรวจสอบอีเมลของคุณและคลิกลิงก์ยืนยัน</h2>
                                <p className="text-gray">โปรดตรวจสอบที่สแปมหรืออีเมลขยะเพื่อให้แน่ใจว่าข้อความไม่ได้ถูกกรอง</p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>            

            <ThNavFooter />
      </>
    );
  }
}

export const ThAuthForgotThx = withRouter(ThAuthForgotThxClass);