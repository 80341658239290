import CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_SECRET_KEY2;

export const encryptData = (data) => {
    const iv = CryptoJS.enc.Utf8.parse('0000000000000000'); // 16 bytes IV
    const key = CryptoJS.enc.Base64.parse(secretKey); // Base64 decode the secret key

    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
        iv: iv,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
    });

    return encrypted.toString();
};
