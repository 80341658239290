import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { EnNavHeader } from './EnNavHeader';
import { EnNavFooter } from './EnNavFooter';

import { getClientId } from './utils/analyticsclientIdUtils';
import { encryptData } from './utils/encryptionUtils';
import { API_URL } from './utils/constants';

export class EnAuthSignin extends Component {
    static displayName = EnAuthSignin.name;
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            loginstatus: '',
            requireMsgEmail: '',
            requireMsgPwd: '',
            submitting: false // Add a state variable to track form submission status
        };
    }

    componentDidMount() {
        // Use jQuery to toggle password visibility
        window.$(".toggle-password").click(function () {
            window.$(this).toggleClass("fa-eye fa-eye-slash");
            var input = window.$(window.$(this).attr("toggle"));
            if (input.attr("type") === "password") {
                input.attr("type", "text");
            } else {
                input.attr("type", "password");
            }
        });

        {
            // Google analytic
            const documentPath = "Signin"; // Gets the current URL path
            const clientId = getClientId(); // Use the getClientId function

            // Push data to the GTM dataLayer
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'pageview',
                page: documentPath,
                clientId: clientId,
            });
        }

    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleSubmit = async e => {
        e.preventDefault();
        const { username, password } = this.state;

        let canSubmit = true;

        const validateEmail = (email) => {
            return email.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        };

        if (!username) {
            this.setState({ requireMsgEmail: 'Enter an email' });
            canSubmit = false;
        } else if (!validateEmail(username)) {
            this.setState({ requireMsgEmail: 'Please enter valid Email' });
            canSubmit = false;
        }

        if (!password) {
            this.setState({ requireMsgPwd: 'Enter a password' });
            canSubmit = false;
        }

        if (canSubmit == false) {
            return;
        }

        // Generate Unix timestamp
        const timestamp = Math.floor(Date.now() / 1000);

        // Prepare and encrypt the payload
        const payload = { Email: username, Password: password, RefLang: 'en', Timestamp: timestamp };
        const encryptedPayload = encryptData(payload);

        // Prevent double-clicking by disabling the submit button and changing cursor to 'wait'
        this.setState({ submitting: true });

        // Add the wait cursor class to the body element
        document.body.classList.add('wait-cursor');

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ Data: encryptedPayload })
        };

        try {
            const response = await fetch(`${API_URL}/api/CrmAuthSignin`, requestOptions);
            const data = await response.json();
            if (data.responseCode === "00") {
                // Fetch additional data using data.loyaltyMemberId
                const srId = data.loyaltyMemberId;
                const token = data.token;

                // Get Member Info -> start
                const payloadCrmMemberGetInfo = { UserId: srId, RefLang: 'en', Timestamp: timestamp };
                const encryptedPayloadCrmMemberGetInfo = encryptData(payloadCrmMemberGetInfo);

                const requestOptionsCrmMemberGetInfo = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({ Data: encryptedPayloadCrmMemberGetInfo })
                };
                const dataResponseCrmMemberGetInfo = await fetch(`${API_URL}/api/CrmMemberGetInfo`, requestOptionsCrmMemberGetInfo);
                const additionalDataCrmMemberGetInfo = await dataResponseCrmMemberGetInfo.json();
                // Get Member Info -> end

                // CrmAuthGetRewardLevel -> start
                const payload2 = { LoyaltyMemberId: srId, RefLang: 'en', Timestamp: timestamp };
                const encryptedPayload2 = encryptData(payload2);
                const requestOptions2 = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({ Data: encryptedPayload2 })
                };

                const additionalDataResponse = await fetch(`${API_URL}/api/CrmAuthGetRewardLevel`, requestOptions2);
                const additionalData = await additionalDataResponse.json();
                // CrmAuthGetRewardLevel -> end

                additionalData.email = username;
                additionalData.firstName = additionalDataCrmMemberGetInfo.firstName;
                
                document.body.classList.remove('wait-cursor'); // Remove the wait cursor class

                data.rewardLevel = additionalData.rewardLevel;
                data.firstName = additionalDataCrmMemberGetInfo.firstName;
                data.email = username;
                sessionStorage.setItem('userData', JSON.stringify(data));
                //sessionStorage.setItem('userData', JSON.stringify(additionalData));

                if (additionalData.rewardLevel === "Green") {
                    window.location.replace("/userdashboard");
                }
                else {
                    window.location.replace("/userdashboardgold");
                }
            }
            else {
                let t01 = data.responseMessage                    
                    .replace("1 hours","1 hour.");

                this.setState({ loginstatus: t01 });

                document.body.classList.remove('wait-cursor'); // Remove the wait cursor class
                // Enable the submit button and change cursor back to 'auto' after form submission completes
                this.setState({ submitting: false });                
            }
        } catch (error) {
            console.error('Error during api:', error);
            // Handle error appropriately, e.g., show an error message to the user

            document.body.classList.remove('wait-cursor'); // Remove the wait cursor class
            // Enable the submit button and change cursor back to 'auto' after form submission completes
            this.setState({ submitting: false });
        } finally {            

        }
    };

    render() {
        const { username, password, submitting, loginstatus, requireMsgEmail, requireMsgPwd } = this.state;

        return (
            <>
                <EnNavHeader />

                <main id="main">
                    <section className="top-sr-bg bg-gray1">
                        <div className="container">
                            <div className="row justify-content-between">
                                <div className="col col-md-6">
                                    <h1 className="text-headpage text-white mb-0 pt-3 pb-3 "><span className="d-none">Starbucks &reg; Rewards</span><img src={require("./media/rewards/starbucks-rewards-logo-2.png")} alt="Starbucks &reg; Rewards" className="img-fluid" style={{ maxWidth: '158px' }} /></h1>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section-sign-in overflow-hidden pb-5">
                        <div className="container p-1 p-md-0">
                            <div className="row">
                                <div className="col-md-12 mb-5 pt-3 text-center">
                                    <h2 className="h3 mt-5 fw-bold text-dark-212121">Sign in an account</h2>
                                </div>
                            </div>
                            <div className="row justify-content-center px-1 px-md-5">
                                <div className="col-md-6 mb-3 pt-3">
                                    <div className="p-5 box-sign-in">
                                        <form className="form-sr px-1 px-md-4" onSubmit={this.handleSubmit}>
                                            <p><span className="text-alert">*</span> indicates required fied</p>
                                            <div className="mb-4">
                                                <label htmlFor="username" className="form-label d-none">Email address</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="*Email address"
                                                    name="username"
                                                    value={username}
                                                    onChange={this.handleChange}
                                                />
                                                <div className="invalid-feedback" style={{ display: 'block' }}>{requireMsgEmail}</div>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="password" className="form-label d-none">Password</label>
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="password"
                                                        className="form-control border-end-0"
                                                        placeholder="*Password"
                                                        name="password"
                                                        value={password}
                                                        onChange={this.handleChange}
                                                    />
                                                    <span className="input-group-text">
                                                        <i toggle="input[name='password']" className="fa fa-fw fa-eye-slash toggle-password" />
                                                    </span>
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>{requireMsgPwd}</div>
                                                </div>
                                            </div>
                                            <div className="mb-5">
                                                <p>Forgot your <Link to="/forgotpwd">password?</Link></p>
                                            </div>
                                            <div className="text-end">
                                                <button type="submit" className="btn btn-primary rounded-pill px-4" disabled={submitting}>
                                                    {submitting ? 'Sign in...' : 'Sign in'}
                                                </button>
                                            </div>
                                            <div className="text-alert-2">
                                                {loginstatus}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-2 pt-3 text-center">
                                    <h2 className="h3 mt-5 fw-bold text-green-1">Create an account and bring on the Rewards!</h2>
                                    <p className="mt-3 text-gray">Join&nbsp;Starbucks &reg; Rewards&nbsp;to earn free drinks, pay and order with your phone, and more.</p>
                                    <Link to="/authjoinnow" className="btn btn-outline-green-1 btn-lg rounded-pill">Join now</Link>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>

                <EnNavFooter />
            </>
        );
    }
}