import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { EnNavHeader } from './EnNavHeader';
import { EnNavFooter } from './EnNavFooter';
import EnUserMenu from './EnUserMenu';

import { getClientId } from './utils/analyticsclientIdUtils';
import { encryptData } from './utils/encryptionUtils';
import { API_URL } from './utils/constants';
export class EnUserProfile extends Component {
    static displayName = EnUserProfile.name;

    constructor(props) {
        super(props);
        this.state = {
            userData: null, // Initialize userData state to null
            userInfo: null,
            birthdayd: '',
            birthdaym: '',
            submitting: false,
            salutation: '',
            firstname: '',
            lastname: '',
            firstnameth: '',
            lastnameth: '',
            mobilephone: '',
            submittingProfile: false,
            successMsg: '',
            failMsg: '',
            failMsg2: '',
            registerMsgFname: '',
            registerMsgLname: '',
            actionSuccess: false
        };
    }

    componentDidMount() {
        // Retrieve data from session storage
        const userDataFromStorage = sessionStorage.getItem('userData');

        // Parse the data if it exists and set it to the component state
        if (userDataFromStorage) {
            const parsedUserData = JSON.parse(userDataFromStorage);
            this.setState({ userData: parsedUserData }, () => {
                // After setting the state, make the API call if userData is available
                if (this.state.userData && this.state.userData.loyaltyMemberId) {
                    this.callApiWithUserId(this.state.userData.loyaltyMemberId);
                }
            });
        }

        {
            // Google analytic
            const documentPath = "Profile"; // Gets the current URL path
            const clientId = getClientId(); // Use the getClientId function

            // Push data to the GTM dataLayer
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'pageview',
                page: documentPath,
                clientId: clientId,
            });
        }
    }

    async callApiWithUserId(userId) {
        // Add the wait cursor class to the body element
        //document.body.classList.add('wait-cursor');

        // Generate Unix timestamp
        const timestamp = Math.floor(Date.now() / 1000);

        // Prepare and encrypt the payload
        const payload = { UserId: userId, RefLang: "en", Timestamp: timestamp };
        const encryptedPayload = encryptData(payload);

        const token = this.state.userData.token;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ Data: encryptedPayload })
        };

        try {
            const response = await fetch(`${API_URL}/api/CrmMemberGetInfo`, requestOptions);
            const data = await response.json();
            if (data) {
                data.salutation = data.salutation == null ? "" : data.salutation;
                this.setState({ userInfo: data, salutation: data.salutation, firstname: data.firstName, lastname: data.lastName, firstnameth: data.firstNameTH, lastnameth: data.lastNameTH, mobilephone: data.mobilePhone });
            }
            else {
                console.error('Error data:');
            }
        } catch (error) {
            console.error('API call error :', error);
        } finally {
            //document.body.classList.remove('wait-cursor'); // Remove the wait cursor class
        }
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });

        if (e.target.name === "birthdaym") {
            this.setState({
                birthdayd: ''
            });
        }
    };

    handleSubmitProfile = async e => {
        e.preventDefault();
        const { salutation, firstname, lastname, firstnameth, lastnameth, mobilephone, userInfo } = this.state;
        const birthday = userInfo.birthday;

        let canSubmit = true;

        if (firstname === "") {
            this.setState({ registerMsgFname: "Please Input Name" });
            canSubmit = false;
        }

        if (lastname === "") {
            this.setState({ registerMsgLname: "Please Input Last Name" });
            canSubmit = false;
        }

        if (canSubmit == false) {
            return;
        }

        // Generate Unix timestamp
        const timestamp = Math.floor(Date.now() / 1000);

        // Prepare and encrypt the payload
        const payload = {
            LoyaltyMemberId: userInfo.loyaltyMemberId,
            Salutation: salutation,
            FirstName: firstname,
            LastName: lastname,
            FirstNameTH: firstnameth,
            LastNameTH: lastnameth,
            THNationalID: userInfo.thNationalID,
            Passport: userInfo.passport,
            MobilePhone: mobilephone,
            Birthday: birthday,
            Address1: userInfo.address1,
            Address2: userInfo.address2,
            Address3: userInfo.address3,
            City: userInfo.city,
            State: userInfo.state,
            PostalCode: userInfo.postalCode,
            Country: userInfo.country,
            OptInEmail: userInfo.optInEmail,
            RefLang: 'en',
            Timestamp: timestamp
        };
        const encryptedPayload = encryptData(payload);

        // Prevent double-clicking by disabling the submit button and changing cursor to 'wait'
        this.setState({ submittingProfile: true });

        // Add the wait cursor class to the body element
        document.body.classList.add('wait-cursor');

        const token = this.state.userData.token;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ Data: encryptedPayload })
        };

        try {
            const response = await fetch(`${API_URL}/api/CrmMemberUpdateProfile`, requestOptions);
            const data = await response.json();
            if (data && data.statusCode === 'OK') {
                this.setState({
                    successMsg: 'Personal Info update Success.',
                    actionSuccess: true
                });
            }
            else {
                //some error here
                this.setState({
                    failMsg: data.statusMsg,
                    actionSuccess: false
                });

                // Enable the submit button and change cursor back to 'auto' after form submission completes
                this.setState({ submittingProfile: false });
            }
            document.body.classList.remove('wait-cursor'); // Remove the wait cursor class
        } catch (error) {
            console.error('Error during update profile:', error);
            // Handle error appropriately, e.g., show an error message to the user
        } finally {
            //document.body.classList.remove('wait-cursor'); // Remove the wait cursor class
            this.setState({ submittingProfile: false });
            document.body.classList.remove('wait-cursor'); // Remove the wait cursor class
        }
    };

    handleSubmit = async e => {
        e.preventDefault();
        const { birthdayd, birthdaym, userInfo } = this.state;

        if (!birthdaym || birthdaym === "") {
            this.setState({
                failMsg2: 'Please input valid Birthday'
            });
            return;
        }

        if (!birthdayd || birthdayd === "") {
            this.setState({
                failMsg2: 'Please input valid Birthday'
            });
            return;
        }

        const birthday = "1900-" + birthdaym + "-" + birthdayd;

        // Generate Unix timestamp
        const timestamp = Math.floor(Date.now() / 1000);

        // Prepare and encrypt the payload
        const payload = {
            LoyaltyMemberId: userInfo.loyaltyMemberId,
            Salutation: userInfo.salutation,
            FirstName: userInfo.firstName,
            LastName: userInfo.lastName,
            FirstNameTH: userInfo.firstNameTH,
            LastNameTH: userInfo.lastNameTH,
            THNationalID: userInfo.thNationalID,
            Passport: userInfo.passport,
            MobilePhone: userInfo.mobilePhone,
            Birthday: birthday,
            Address1: userInfo.address1,
            Address2: userInfo.address2,
            Address3: userInfo.address3,
            City: userInfo.city,
            State: userInfo.state,
            PostalCode: userInfo.postalCode,
            Country: userInfo.country,
            OptInEmail: userInfo.optInEmail,
            RefLang: 'en',
            Timestamp: timestamp
        };
        const encryptedPayload = encryptData(payload);

        // Prevent double-clicking by disabling the submit button and changing cursor to 'wait'
        this.setState({ submitting: true });

        // Add the wait cursor class to the body element
        document.body.classList.add('wait-cursor');

        const token = this.state.userData.token;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ Data: encryptedPayload })
        };

        try {
            const response = await fetch(`${API_URL}/api/CrmMemberUpdateProfile`, requestOptions);
            const data = await response.json();
            if (data) {
                document.body.classList.remove('wait-cursor'); // Remove the wait cursor class

                window.location.replace("/userprofile");
            }
            else {
                // Enable the submit button and change cursor back to 'auto' after form submission completes
                this.setState({ submitting: false });
                document.body.classList.remove('wait-cursor'); // Remove the wait cursor class
            }
        } catch (error) {
            console.error('Error during update profile:', error);
            // Handle error appropriately, e.g., show an error message to the user
        } finally {

        }
    };

    formatDate(dateString) {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric'
        });
    }

    render() {
        const { userInfo, submitting, birthdayd, birthdaym, submittingProfile, salutation, firstname, lastname, firstnameth, lastnameth, mobilephone, successMsg, failMsg, registerMsgFname, registerMsgLname, failMsg2 } = this.state;

        const getDaysInMonth = (month) => {
            const daysInMonth = {
                '01': 31, // January
                '02': 29, // February
                '03': 31, // March
                '04': 30, // April
                '05': 31, // May
                '06': 30, // June
                '07': 31, // July
                '08': 31, // August
                '09': 30, // September
                '10': 31, // October
                '11': 30, // November
                '12': 31, // December
            };

            return daysInMonth[month] || 31; // Default to 31 if month is not selected
        };

        const days = Array.from({ length: getDaysInMonth(birthdaym) }, (_, i) => i + 1);

        return (
            <>
                <EnNavHeader />

                {/* Render the user data */}
                <main id="main">
                    <section id="profile_section" className="profileBanner keyboardFocus" aria-label="Profile" tabIndex={-1}>
                        <EnUserMenu />
                    </section>
                    {userInfo ? (
                        <section className="section-dashboard overflow-hidden pb-5">
                            <div className="container mt-4">
                                <div className="row mb-5 px-2">
                                    <div className="col-md-12">
                                        <h3 className="fw-semibold mt-4">Personal Info</h3>
                                        <form className="form-sr">
                                            <div className="row">
                                                <div className="col-md-4 mt-3 mb-3">
                                                    <label className="form-label">Prefix (optional)</label>
                                                    <select className="form-select" name="salutation"
                                                        onChange={this.handleChange}
                                                        value={salutation}>
                                                        <option value="">Please select</option>
                                                        <option value="Mr.">Mr. </option>
                                                        <option value="Mrs.">Mrs. </option>
                                                        <option value="Ms">Ms </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4 mb-3">
                                                    <label className="form-label">Name</label>
                                                    <input type="text" className="form-control" placeholder="Name" name="firstname" onChange={this.handleChange} defaultValue={firstname} />
                                                    <div className="text-start mt-1 text-alert-2">
                                                        {registerMsgFname}
                                                    </div>
                                                </div>
                                                <div className="col-md-4 mb-3">
                                                    <label className="form-label">Last Name</label>
                                                    <input type="text" className="form-control" placeholder="Last Name" name="lastname" onChange={this.handleChange} defaultValue={lastname} />
                                                    <div className="text-start mt-1 text-alert-2">
                                                        {registerMsgLname}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4 mb-3">
                                                    <label className="form-label">ชื่อ (optional)</label>
                                                    <input type="text" className="form-control" placeholder="ชื่อ (optional)" name="firstnameth" onChange={this.handleChange} defaultValue={firstnameth} />
                                                </div>
                                                <div className="col-md-4 mb-3">
                                                    <label className="form-label">นามสกุล (optional)</label>
                                                    <input type="text" className="form-control" placeholder="นามสกุล (optional)" name="lastnameth" onChange={this.handleChange} defaultValue={lastnameth} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4 mb-3">
                                                    <label className="form-label">Mobile Number (optional)</label>
                                                    <input type="text" className="form-control" placeholder="Mobile Number (optional)" name="mobilephone" onChange={this.handleChange} defaultValue={mobilephone} />
                                                </div>
                                            </div>
                                            <div>
                                                <button type="button" className="btn btn-primary rounded-pill px-3" disabled={submittingProfile} onClick={this.handleSubmitProfile}>
                                                    {submittingProfile ? 'Update...' : 'Update'}
                                                </button>
                                                <div className="text-start text-green-1">
                                                    {successMsg}
                                                </div>
                                                <div className="text-start text-alert-2">
                                                    {failMsg}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 mb-3">
                                                    <hr className="my-3" />
                                                </div>
                                            </div>
                                            <p className="text-black">This is your current username and password. You can change either of them any time you like.</p>
                                            <div className="row">
                                                <div className="col-md-8 mb-3">
                                                    <label className="form-label">Username</label>
                                                    <div className="row">
                                                        <div className="col-md-7 mb-2">
                                                            <input type="text" className="form-control" placeholder="Username" defaultValue={userInfo.email} disabled />
                                                            <Link to="/userprofileemail" className="btn btn-outline-green rounded-pill px-4 text-decoration-none mt-3">Change Username</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-8 mb-3">
                                                    <label className="form-label">Password</label>
                                                    <div className="row">
                                                        <div className="col-md-7 mb-2">
                                                            <Link to="/userprofilepwd" className="btn btn-outline-green rounded-pill px-4 text-decoration-none">Change Password</Link>
                                                        </div>
                                                        <div className="col-md-4 mb-2">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <p>One of many benefits to registering a Starbucks Card: birthday rewards once you reach Gold level!</p>
                                            <p className="h6"><strong>Birthday (optional)</strong></p>
                                            <div className="row g-3">
                                                {userInfo.displayBirthday ? (
                                                    <div className="col-md-3 mb-3">
                                                        {this.formatDate(userInfo.displayBirthday).slice(0, -5)}
                                                    </div>
                                                ) : (
                                                    <>
                                                        <div className="col-md-3 mb-3">
                                                            <select name="birthdaym" value={birthdaym} onChange={this.handleChange}>
                                                                <option value="">Month</option>
                                                                <option value="01">Jan</option>
                                                                <option value="02">Feb</option>
                                                                <option value="03">Mar</option>
                                                                <option value="04">Apr</option>
                                                                <option value="05">May</option>
                                                                <option value="06">Jun</option>
                                                                <option value="07">Jul</option>
                                                                <option value="08">Aug</option>
                                                                <option value="09">Sep</option>
                                                                <option value="10">Oct</option>
                                                                <option value="11">Nov</option>
                                                                <option value="12">Dec</option>
                                                            </select>&nbsp;
                                                            <select name="birthdayd" value={birthdayd} onChange={this.handleChange}>
                                                                <option value="">Day</option>
                                                                {days.map((day) => (
                                                                    <option key={day} value={day}>
                                                                        {day}
                                                                    </option>
                                                                ))}
                                                            </select>&nbsp;
                                                            <button type="button" className="btn btn-primary rounded-pill px-3" disabled={submitting} onClick={this.handleSubmit}>
                                                                {submitting ? 'Update...' : 'Update'}
                                                            </button>                                                            
                                                            <div className="text-start text-alert-2">
                                                                {failMsg2}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            <p className="small text-black">Your privacy is important to us; we work hard to keep your trust. If you've got questions about this serious matter, please check out our Privacy Statement</p>
                                            <div className="row">
                                                <div className="col-md-12 mb-3">
                                                    <hr className="mt-2 mb-3" />
                                                </div>
                                            </div>
                                            <div className="row form-check-term">
                                                <div className="col-md-12 mb-3">
                                                    <div className="text-start mt-4"><Link to="/userprofiledelete" className="btn btn-outline-green rounded-pill px-4 text-decoration-none">Request Delete Account</Link></div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </section>
                    ) : (
                        <>
                            {/*Loading*/}
                            <div className="col-md-5">
                                <div className="text-center" style={{ height: '160px' }} />
                                <div className="text-center mt-5 pt-5 mb-5 pb-5">
                                    <h3 className="text-white">LOADING ...</h3>
                                    <img src="media/loading-gif.gif" alt="Loading..." width={80} className="img-fluid" />
                                </div>
                                <div style={{ height: '100px' }} />
                            </div>
                        </>
                    )}
                </main>

                <EnNavFooter />
            </>
        );
    }
}
