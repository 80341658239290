import React, { useState, useEffect } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { Layout } from './components/Layout';
import { API_URL } from './components/utils/constants';

// #region En
import { EnHome } from './components/EnHome';
import { EnFaq } from './components/EnFaq';
import { EnTerm } from './components/EnTerm';
import { EnTermOfUse } from './components/EnTermOfUse';
import { EnPromoGrabSr } from './components/EnPromoGrabSr';

import { EnAuthJoin } from './components/EnAuthJoin';
import { EnAuthJoinThx } from './components/EnAuthJoinThx';
import { EnAuthJoinDetail } from './components/EnAuthJoinDetail';
import { EnAuthJoinDetailErr } from './components/EnAuthJoinDetailErr';
import { EnAuthJoinDetailThx } from './components/EnAuthJoinDetailThx';
import { EnAuthSignin } from './components/EnAuthSignin';
import { EnUserDashboard } from './components/EnUserDashboard';
import { EnUserDashboardGold } from './components/EnUserDashboardGold';
import { EnUserStarTransaction } from './components/EnUserStarTransaction';
import { EnUserCard } from './components/EnUserCard';
import { EnUserCardLost } from './components/EnUserCardLost';
import { EnUserCardRemove } from './components/EnUserCardRemove';
import { EnUserCardTransfer } from './components/EnUserCardTransfer';
import { EnUserCardAdd } from './components/EnUserCardAdd';
import { EnUserCardReload } from './components/EnUserCardReload';
import { EnUserReward } from './components/EnUserReward';
import { EnUserRewardGold } from './components/EnUserRewardGold';
import { EnUserProfile } from './components/EnUserProfile';
import { EnUserProfilePwd } from './components/EnUserProfilePwd';
import { EnUserProfilePwdThx } from './components/EnUserProfilePwdThx';
import { EnUserProfileDelete } from './components/EnUserProfileDelete';
import { EnUserProfileDeleteThx } from './components/EnUserProfileDeleteThx';
import { EnUserCardReloadCancel } from './components/EnUserCardReloadCancel';
import { EnUserCardReloadFail } from './components/EnUserCardReloadFail';
import { EnUserCardReloadSuccess } from './components/EnUserCardReloadSuccess';

import { EnAuthForgot } from './components/EnAuthForgot';
import { EnAuthForgotThx } from './components/EnAuthForgotThx';
import { EnAuthForgotDetail } from './components/EnAuthForgotDetail';
import { EnAuthForgotDetailErr } from './components/EnAuthForgotDetailErr';
import { EnAuthForgotDetailThx } from './components/EnAuthForgotDetailThx';

import { EnUserProfileEmail } from './components/EnUserProfileEmail';
import { EnUserProfileEmailThx } from './components/EnUserProfileEmailThx';
import { EnAuthForgotUsernameDetail } from './components/EnAuthForgotUsernameDetail';
import { EnAuthForgotUsernameDetailErr } from './components/EnAuthForgotUsernameDetailErr';
import { EnAuthForgotUsernameDetailThx } from './components/EnAuthForgotUsernameDetailThx';

// #endregion

// #region Th
import { ThHome } from './components/ThHome';
import { ThFaq } from './components/ThFaq';
import { ThTerm } from './components/ThTerm';
import { ThTermOfUse } from './components/ThTermOfUse';
import { ThPromoGrabSr } from './components/ThPromoGrabSr';

import { ThAuthJoin } from './components/ThAuthJoin';
import { ThAuthJoinThx } from './components/ThAuthJoinThx';
import { ThAuthJoinDetail } from './components/ThAuthJoinDetail';
import { ThAuthJoinDetailErr } from './components/ThAuthJoinDetailErr';
import { ThAuthJoinDetailThx } from './components/ThAuthJoinDetailThx';

import { ThAuthSignin } from './components/ThAuthSignin';
import { ThUserDashboard } from './components/ThUserDashboard';
import { ThUserDashboardGold } from './components/ThUserDashboardGold';
import { ThUserStarTransaction } from './components/ThUserStarTransaction';
import { ThUserCard } from './components/ThUserCard';
import { ThUserCardLost } from './components/ThUserCardLost';
import { ThUserCardRemove } from './components/ThUserCardRemove';
import { ThUserCardTransfer } from './components/ThUserCardTransfer';
import { ThUserCardAdd } from './components/ThUserCardAdd';
import { ThUserCardReload } from './components/ThUserCardReload';
import { ThUserReward } from './components/ThUserReward';
import { ThUserRewardGold } from './components/ThUserRewardGold';
import { ThUserProfile } from './components/ThUserProfile';
import { ThUserProfilePwd } from './components/ThUserProfilePwd';
import { ThUserProfilePwdThx } from './components/ThUserProfilePwdThx';
import { ThUserProfileDelete } from './components/ThUserProfileDelete';
import { ThUserProfileDeleteThx } from './components/ThUserProfileDeleteThx';
import { ThUserCardReloadCancel } from './components/ThUserCardReloadCancel';
import { ThUserCardReloadFail } from './components/ThUserCardReloadFail';
import { ThUserCardReloadSuccess } from './components/ThUserCardReloadSuccess';

import { ThAuthForgot } from './components/ThAuthForgot';
import { ThAuthForgotThx } from './components/ThAuthForgotThx';
import { ThAuthForgotDetail } from './components/ThAuthForgotDetail';
import { ThAuthForgotDetailErr } from './components/ThAuthForgotDetailErr';
import { ThAuthForgotDetailThx } from './components/ThAuthForgotDetailThx';

import { ThUserProfileEmail } from './components/ThUserProfileEmail';
import { ThUserProfileEmailThx } from './components/ThUserProfileEmailThx';
import { ThAuthForgotUsernameDetail } from './components/ThAuthForgotUsernameDetail';
import { ThAuthForgotUsernameDetailErr } from './components/ThAuthForgotUsernameDetailErr';
import { ThAuthForgotUsernameDetailThx } from './components/ThAuthForgotUsernameDetailThx';

// #endregion

// #region Utility page
import { UtilDeleteAccountW } from './components/UtilDeleteAccountW';
import { UtilDeleteAccountM } from './components/UtilDeleteAccountM';
import { UtilDeleteAccountC } from './components/UtilDeleteAccountC';
import { UtilDeleteSignin } from './components/UtilDeleteSignin';
import { UtilDeleteSigninTh } from './components/UtilDeleteSigninTh';
// #endregion
const App = () => {
    // Check if user is authenticated based on session storage
    //const userData = JSON.parse(sessionStorage.getItem('userData'));
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const storedUserData = JSON.parse(sessionStorage.getItem('userData'));
        if (storedUserData) {
            setUserData(storedUserData);
            setLoading(false);// Set loading is false when loaded userData

            let idleTimeout;
            const resetIdleTimeout = () => {
                clearTimeout(idleTimeout);
                startIdleTimeout();
            };
            const startIdleTimeout = () => {
                idleTimeout = setTimeout(() => {
                    handleLogout();
                }, 20 * 60 * 1000); // 20 minutes in milliseconds
            };
            startIdleTimeout();
            const handleUserActivity = () => {
                resetIdleTimeout();
            };
            window.addEventListener('mousemove', handleUserActivity);
            window.addEventListener('keypress', handleUserActivity);
            return () => {
                clearTimeout(idleTimeout);
                window.removeEventListener('mousemove', handleUserActivity);
                window.removeEventListener('keypress', handleUserActivity);
            };
        }
        setLoading(false);// This ensures loading is set to false whether or not there's user data
    }, []);

    // Function to handle logout
    const handleLogout = async () => {
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        const token = userData?.token;
        if (!token) {
            await fetch(`${API_URL}/api/CrmAuthSignOut`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
        }        
        // Remove userData from session storage upon logout
        sessionStorage.removeItem('userData');
        // Redirect to the home page after logout
        window.location.replace("/");
    };

    const handleLogoutTh = async () => {
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        const token = userData?.token;
        if (!token) {
            await fetch(`${API_URL}/api/CrmAuthSignOut`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
        }      
        // Remove userData from session storage upon logout
        sessionStorage.removeItem('userData');
        // Redirect to the home page after logout
        window.location.replace("/ThHome");
    };

    if (loading) {
        return <div>...</div>;
    }

    return (
        <Layout>
            <Switch>
                <Route exact path='/' component={EnHome} />

                <Route exact path='/DeleteAccountW' component={UtilDeleteAccountW} />
                <Route exact path='/DeleteAccountM' component={UtilDeleteAccountM} />
                <Route exact path='/DeleteAccountC' component={UtilDeleteAccountC} />
                <Route exact path='/DeleteAccountAuth' component={UtilDeleteSignin} />
                <Route exact path='/DeleteAccountAuthTh' component={UtilDeleteSigninTh} />

                <Route exact path='/faq' component={EnFaq} />
                <Route exact path='/terms' component={EnTerm} />
                <Route exact path='/termsofuse' component={EnTermOfUse} />
                <Route exact path='/promograbsr' component={EnPromoGrabSr} />

                <Route exact path='/authsignin'>
                    {userData ? (
                        userData.rewardLevel === "Green" ? <Redirect to="/userdashboard" /> :
                            userData.rewardLevel === "Gold" ? <Redirect to="/userdashboardgold" /> :
                                <Redirect to="/" />
                    ) : (
                        <EnAuthSignin />
                    )}
                </Route>

                <Route exact path='/authjoinnow'>
                    {userData ? (
                        userData.rewardLevel === "Green" ? <Redirect to="/userdashboard" /> :
                            userData.rewardLevel === "Gold" ? <Redirect to="/userdashboardgold" /> :
                                <Redirect to="/" />
                    ) : (
                        <EnAuthJoin />
                    )}
                </Route>

                <Route exact path='/authjoinnowthx' component={EnAuthJoinThx} />
                <Route exact path='/joinnow' component={EnAuthJoinDetail} />
                <Route exact path='/joinnowerr' component={EnAuthJoinDetailErr} />
                <Route exact path='/joinnowthx' component={EnAuthJoinDetailThx} />
                <Route exact path='/forgotpwd' component={EnAuthForgot} />
                <Route exact path='/forgotpwdthx' component={EnAuthForgotThx} />
                <Route exact path='/forgotpwddetail' component={EnAuthForgotDetail} />
                <Route exact path='/forgoterr' component={EnAuthForgotDetailErr} />
                <Route exact path='/forgotthx' component={EnAuthForgotDetailThx} />                

                <Route exact path='/enauthsignin'>
                    {userData ? (
                        userData.rewardLevel === "Green" ? <Redirect to="/userdashboard" /> :
                            userData.rewardLevel === "Gold" ? <Redirect to="/userdashboardgold" /> :
                                <EnAuthSignin />
                    ) : (
                        <EnAuthSignin />
                    )}
                </Route>
                <Route exact path='/userdashboard'>
                    {userData && userData.rewardLevel === "Green" ? <EnUserDashboard /> : <Redirect to="/enauthsignin" />}
                </Route>
                <Route exact path='/userdashboardgold'>
                    {userData && userData.rewardLevel === "Gold" ? <EnUserDashboardGold /> : <Redirect to="/enauthsignin" />}
                </Route>
                <Route exact path='/userstartransaction'>
                    {userData ? <EnUserStarTransaction /> : <Redirect to="/enauthsignin" />}
                </Route>
                <Route exact path='/usercard'>
                    {userData ? <EnUserCard /> : <Redirect to="/enauthsignin" />}
                </Route>
                <Route exact path='/usercardlost'>
                    {userData ? <EnUserCardLost /> : <Redirect to="/enauthsignin" />}
                </Route>                
                <Route exact path='/usercardremove'>
                    {userData ? <EnUserCardRemove /> : <Redirect to="/enauthsignin" />}
                </Route>
                <Route exact path='/usercardtransfer'>
                    {userData ? <EnUserCardTransfer /> : <Redirect to="/enauthsignin" />}
                </Route>
                <Route exact path='/usercardadd'>
                    {userData ? <EnUserCardAdd /> : <Redirect to="/enauthsignin" />}
                </Route>
                <Route exact path='/usercardreload'>
                    {userData ? <EnUserCardReload /> : <Redirect to="/enauthsignin" />}
                </Route>
                <Route exact path='/usercardreload_cancel'>
                    {userData ? <EnUserCardReloadCancel /> : <Redirect to="/enauthsignin" />}
                </Route>
                <Route exact path='/usercardreload_fail'>
                    {userData ? <EnUserCardReloadFail /> : <Redirect to="/enauthsignin" />}
                </Route>
                <Route exact path='/usercardreload_success'>
                    {userData ? <EnUserCardReloadSuccess /> : <Redirect to="/enauthsignin" />}
                </Route>

                <Route exact path='/userreward'>
                    {userData && userData.rewardLevel === "Green" ? <EnUserReward /> : <Redirect to="/enauthsignin" />}
                </Route>
                <Route exact path='/userrewardgold'>
                    {userData && userData.rewardLevel === "Gold" ? <EnUserRewardGold /> : <Redirect to="/enauthsignin" />}
                </Route>
                <Route exact path='/userprofile'>
                    {userData ? <EnUserProfile /> : <Redirect to="/enauthsignin" />}
                </Route>
                <Route exact path='/userprofilepwd'>
                    {userData ? <EnUserProfilePwd /> : <Redirect to="/enauthsignin" />}
                </Route>
                <Route exact path='/userprofilepwdthx'>
                    {userData ? <EnUserProfilePwdThx /> : <Redirect to="/enauthsignin" />}
                </Route>
                <Route exact path='/userprofiledelete'>
                    {userData ? <EnUserProfileDelete /> : <Redirect to="/enauthsignin" />}
                </Route>
                <Route exact path='/userprofiledeletethx'>
                    {userData ? <EnUserProfileDeleteThx /> : <Redirect to="/enauthsignin" />}
                </Route>

                <Route exact path='/userprofileemail'>
                    {userData ? <EnUserProfileEmail /> : <Redirect to="/enauthsignin" />}
                </Route>
                <Route exact path='/userprofileemailthx'>
                    {userData ? <EnUserProfileEmailThx /> : <Redirect to="/enauthsignin" />}
                </Route>
                <Route exact path='/forgotusernamedetail' component={EnAuthForgotUsernameDetail} />
                <Route exact path='/forgotusernameerr' component={EnAuthForgotUsernameDetailErr} />
                <Route exact path='/forgotusernamethx' component={EnAuthForgotUsernameDetailThx} />


                <Route exact path='/authsigninth'>
                    {userData ? (
                        userData.rewardLevel === "Green" ? <Redirect to="/thuserdashboard" /> :
                            userData.rewardLevel === "Gold" ? <Redirect to="/thuserdashboardgold" /> :
                                <Redirect to="/" />
                    ) : (
                        <ThAuthSignin />
                    )}
                </Route>

                <Route exact path='/thauthsignin'>
                    {userData ? (
                        userData.rewardLevel === "Green" ? <Redirect to="/thuserdashboard" /> :
                            userData.rewardLevel === "Gold" ? <Redirect to="/thuserdashboardgold" /> :
                                <ThAuthSignin />
                    ) : (
                            <ThAuthSignin />
                    )}
                </Route>

                <Route exact path='/thhome' component={ThHome} />       
                <Route exact path='/thfaq' component={ThFaq} />
                <Route exact path='/thterms' component={ThTerm} />
                <Route exact path='/thtermsofuse' component={ThTermOfUse} />
                <Route exact path='/thpromograbsr' component={ThPromoGrabSr} />
                <Route exact path='/thforgotpwd' component={ThAuthForgot} />
                <Route exact path='/thforgotpwdthx' component={ThAuthForgotThx} />
                <Route exact path='/thforgotpwddetail' component={ThAuthForgotDetail} />
                <Route exact path='/thforgoterr' component={ThAuthForgotDetailErr} />
                <Route exact path='/thforgotthx' component={ThAuthForgotDetailThx} />

                <Route exact path='/thauthjoinnow'>
                    {userData ? (
                        userData.rewardLevel === "Green" ? <Redirect to="/thuserdashboard" /> :
                            userData.rewardLevel === "Gold" ? <Redirect to="/thuserdashboardgold" /> :
                                <Redirect to="/" />
                    ) : (
                        <ThAuthJoin />
                    )}
                </Route>

                <Route exact path='/thauthjoinnowthx' component={ThAuthJoinThx} />
                <Route exact path='/thjoinnow' component={ThAuthJoinDetail} />
                <Route exact path='/thjoinnowerr' component={ThAuthJoinDetailErr} />
                <Route exact path='/thjoinnowthx' component={ThAuthJoinDetailThx} />

                <Route exact path='/thuserdashboard'>
                    {userData && userData.rewardLevel === "Green" ? <ThUserDashboard /> : <Redirect to="/thauthsignin" />}
                </Route>
                <Route exact path='/thuserdashboardgold'>
                    {userData && userData.rewardLevel === "Gold" ? <ThUserDashboardGold /> : <Redirect to="/thauthsignin" />}
                </Route>
                <Route exact path='/thuserstartransaction'>
                    {userData ? <ThUserStarTransaction /> : <Redirect to="/thauthsignin" />}
                </Route>
                <Route exact path='/thusercard'>
                    {userData ? <ThUserCard /> : <Redirect to="/thauthsignin" />}
                </Route>
                <Route exact path='/thusercardlost'>
                    {userData ? <ThUserCardLost /> : <Redirect to="/thauthsignin" />}
                </Route>
                <Route exact path='/thusercardremove'>
                    {userData ? <ThUserCardRemove /> : <Redirect to="/thauthsignin" />}
                </Route>
                <Route exact path='/thusercardtransfer'>
                    {userData ? <ThUserCardTransfer /> : <Redirect to="/thauthsignin" />}
                </Route>
                <Route exact path='/thusercardadd'>
                    {userData ? <ThUserCardAdd /> : <Redirect to="/thauthsignin" />}
                </Route>
                <Route exact path='/thusercardreload'>
                    {userData ? <ThUserCardReload /> : <Redirect to="/thauthsignin" />}
                </Route>
                <Route exact path='/thusercardreload_cancel'>
                    {userData ? <ThUserCardReloadCancel /> : <Redirect to="/thauthsignin" />}
                </Route>
                <Route exact path='/thusercardreload_fail'>
                    {userData ? <ThUserCardReloadFail /> : <Redirect to="/thauthsignin" />}
                </Route>
                <Route exact path='/thusercardreload_success'>
                    {userData ? <ThUserCardReloadSuccess /> : <Redirect to="/thauthsignin" />}
                </Route>

                <Route exact path='/thuserreward'>
                    {userData && userData.rewardLevel === "Green" ? <ThUserReward /> : <Redirect to="/thauthsignin" />}
                </Route>
                <Route exact path='/thuserrewardgold'>
                    {userData && userData.rewardLevel === "Gold" ? <ThUserRewardGold /> : <Redirect to="/thauthsignin" />}
                </Route>
                <Route exact path='/thuserprofile'>
                    {userData ? <ThUserProfile /> : <Redirect to="/thauthsignin" />}
                </Route>
                <Route exact path='/thuserprofilepwd'>
                    {userData ? <ThUserProfilePwd /> : <Redirect to="/thauthsignin" />}
                </Route>
                <Route exact path='/thuserprofilepwdthx'>
                    {userData ? <ThUserProfilePwdThx /> : <Redirect to="/thauthsignin" />}
                </Route>
                <Route exact path='/thuserprofiledelete'>
                    {userData ? <ThUserProfileDelete /> : <Redirect to="/thauthsignin" />}
                </Route>
                <Route exact path='/thuserprofiledeletethx'>
                    {userData ? <ThUserProfileDeleteThx /> : <Redirect to="/thauthsignin" />}
                </Route>

                <Route exact path='/thuserprofileemail'>
                    {userData ? <ThUserProfileEmail /> : <Redirect to="/thauthsignin" />}
                </Route>
                <Route exact path='/thuserprofileemailthx'>
                    {userData ? <ThUserProfileEmailThx /> : <Redirect to="/thauthsignin" />}
                </Route>
                <Route exact path='/thforgotusernamedetail' component={ThAuthForgotUsernameDetail} />
                <Route exact path='/thforgotusernameerr' component={ThAuthForgotUsernameDetailErr} />
                <Route exact path='/thforgotusernamethx' component={ThAuthForgotUsernameDetailThx} />

                <Route exact path='/logout' render={() => { handleLogout(); return null; }} />
                <Route exact path='/logoutth' render={() => { handleLogoutTh(); return null; }} />

                {/* Default Route */}
                <Redirect to="/" />
            </Switch>            
        </Layout>
    );
};

export default App;