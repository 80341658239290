import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { EnNavHeader } from './EnNavHeader';
import { EnNavFooter } from './EnNavFooter';

import { getClientId } from './utils/analyticsclientIdUtils';
import { encryptData } from './utils/encryptionUtils';
import { API_URL } from './utils/constants';

class EnAuthForgotUsernameDetailClass extends Component {

    constructor(props) {
        super(props);
        this.state = {
            memberInfo: null,
            codeRegister: null,
            oldEmail: '',
            newEmail: '',
            password: '',
            registerMsg0: '',
            registerMsg1:'',
            registerMsg2: '',
            registerMsgSum: '',
            submitting: false // Add a state variable to track form submission status
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        const searchParams = new URLSearchParams(this.props.location.search);
        const codeRegister = searchParams.get('codereg');
        if (!codeRegister) {
            const dataToSend = { reason: "No code!!" };
            this.props.history.replace('/forgotusernameerr', dataToSend);
            return;
        }

        // Update state and call API to get member information
        this.setState({ codeRegister }, () => {
            this.callApiRegisterInfo(codeRegister);
        });

        {
            // Google analytic
            const documentPath = "ForgotUsr - detail"; // Gets the current URL path
            const clientId = getClientId(); // Use the getClientId function

            // Push data to the GTM dataLayer
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'pageview',
                page: documentPath,
                clientId: clientId,
            });
        }
    }

    async callApiRegisterInfo(codeRegister) {
        // Add the wait cursor class to the body element
        document.body.classList.add('wait-cursor');

        // Generate Unix timestamp
        const timestamp = Math.floor(Date.now() / 1000);

        const payload = { codereg: codeRegister, RefLang: "en", Timestamp: timestamp };
        const encryptedPayload = encryptData(payload);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ Data: encryptedPayload })
        };

        try {
            const response = await fetch(`${API_URL}/api/CrmMemberUpdateUsernameStepChk`, requestOptions);
            const data = await response.json();

            if (data) {
                if (data.responseCode == "OK") {
                    this.setState({ memberInfo: data, oldEmail: data.returnObj.email, newEmail: data.returnObj.newEmail }, () => {
                        // Initialize this java after loaded
                        window.$(".toggle-password").click(function () {
                            window.$(this).toggleClass("fa-eye fa-eye-slash");
                            var input = window.$(window.$(this).attr("toggle"));
                            if (input.attr("type") === "password") {
                                input.attr("type", "text");
                            } else {
                                input.attr("type", "password");
                            }
                        });
                    });
                }
                else {
                    const dataToSend = { reason: data.responseMessage };
                    this.props.history.replace('/forgotusernameerr', dataToSend);
                }
            }
            else {
                const dataToSend = { reason: "No data!!" };
                this.props.history.replace('/forgotusernameerr', dataToSend);
            }
        } catch (error) {
            console.error('API call error :', error);
        } finally {
            document.body.classList.remove('wait-cursor'); // Remove the wait cursor class
        }
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleSubmit = async e => {
        e.preventDefault();
        const { oldEmail, newEmail, password, codeRegister } = this.state;        
        {
            var errorChk = false;
            //check form condition
            if (password === "") {
                this.setState({ registerMsg2: "Please Input Password" });
                errorChk = true;
            }

            if (errorChk) {
                return;
            }
        }

        // Generate Unix timestamp
        const timestamp = Math.floor(Date.now() / 1000);

        // Prepare and encrypt the payload
        const payload = { CodeReg: codeRegister, Email: oldEmail, NewEmail: newEmail, CurrentPassword: password, RefLang: 'en', Timestamp: timestamp };
        const encryptedPayload = encryptData(payload);

        // Prevent double-clicking by disabling the submit button and changing cursor to 'wait'
        this.setState({ submitting: true });

        // Add the wait cursor class to the body element
        document.body.classList.add('wait-cursor');

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ Data: encryptedPayload })
        };

        try {
            const response = await fetch(`${API_URL}/api/CrmMemberUpdateUsernameStep02`, requestOptions);
            const data = await response.json();
            document.body.classList.remove('wait-cursor'); // Remove the wait cursor class

            if (data.responseCode === "OK") {
                const dataToSend = { responseData: data };
                this.props.history.replace('/forgotusernamethx', dataToSend);
            }
            else {
                //const dataToSend = { responseData: data };
                //this.props.history.replace('/forgoterr', dataToSend);

                // Enable the submit button and change cursor back to 'auto' after form submission completes
                this.setState({ submitting: false });
                document.body.classList.remove('wait-cursor'); // Remove the wait cursor class

                let t01 = data.responseMessage
                    .replace("1 hours", "1 hour.");

                this.setState({ registerMsg2: t01 });
            }
        } catch (error) {
            // Enable the submit button and change cursor back to 'auto' after form submission completes
            this.setState({ submitting: false });
            document.body.classList.remove('wait-cursor'); // Remove the wait cursor class

            console.error('Error :', error);
            // Handle error appropriately, e.g., show an error message to the user
        } finally {
           
        }
    };

    render() {
        const { memberInfo, oldEmail, newEmail, password, submitting, registerMsg0, registerMsg1, registerMsg2, registerMsgSum } = this.state;
        return (
            <>
                <EnNavHeader />
                {memberInfo && (
                    <main id="main">
                        <section className="top-sr-bg bg-gray1">
                            <div className="container">
                                <div className="row justify-content-between">
                                    <div className="col col-md-6">
                                        <h1 className="text-headpage text-white mb-0 pt-3 pb-3 "><span className="d-none">Starbucks&reg; Rewards</span><img src="media/rewards/starbucks-rewards-logo-2.png" alt="Starbucks&reg; Rewards" className="img-fluid" style={{ maxWidth: '158px' }} /></h1>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="section-create-account overflow-hidden pb-5">
                            <div className="container p-3 p-md-0">
                                <div className="row">
                                    <div className="col-md-12 mb-5 pt-3 text-start">
                                        <h2 className="h3 mt-5 mb-4 fw-bold text-dark-212121">Change My Username</h2>
                                        <form className="form-sr" onSubmit={this.handleSubmit}>                                            
                                            <div className="row">
                                                <div className="col-md-6 mb-3">
                                                    <label className="form-label">Email</label>
                                                    <div className="input-group mb-3">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="*Password"
                                                            name="oldEmail"
                                                            value={oldEmail}
                                                            onChange={this.handleChange}
                                                            readOnly
                                                        />
                                                        <div className="invalid-feedback" style={{ display: 'block' }}>{registerMsg0}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 mb-3">
                                                    <label className="form-label">Change to</label>
                                                    <div className="input-group mb-3">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="*Confirm Password"
                                                            name="newEmail"
                                                            value={newEmail}
                                                            onChange={this.handleChange}
                                                            readOnly
                                                        />            
                                                        <div className="invalid-feedback" style={{ display: 'block' }}>{registerMsg1}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 mb-3">
                                                    <label className="form-label">Password</label>
                                                    <div className="input-group mb-3">
                                                        <input
                                                            id="password-field"
                                                            type="password"
                                                            className="form-control"
                                                            placeholder="*Password"
                                                            name="password"
                                                            value={password}
                                                            onChange={this.handleChange}
                                                        />
                                                        <span className="input-group-text rounded-end-3"><i toggle="input[name='password']" className="fa fa-fw fa-eye-slash toggle-password" /></span>
                                                        <div className="invalid-feedback" style={{ display: 'block' }}>{registerMsg2}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-start mt-4">
                                                <button type="submit" className="btn btn-primary rounded-pill px-4 text-decoration-none" disabled={submitting}>
                                                    {submitting ? 'Submit...' : 'Submit'}
                                                </button>
                                            </div>
                                            <div className="text-start mt-2 text-alert-2">
                                                {registerMsgSum}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>
                )}
                <EnNavFooter />
            </>
        );
    }
}

export const EnAuthForgotUsernameDetail = withRouter(EnAuthForgotUsernameDetailClass);