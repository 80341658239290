import React, { Component } from 'react';

import { EnNavHeader } from './EnNavHeader';
import { EnNavFooter } from './EnNavFooter';
import EnUserMenu from './EnUserMenu';

import { getClientId } from './utils/analyticsclientIdUtils';
import { encryptData } from './utils/encryptionUtils';
import { API_URL } from './utils/constants';

export class EnUserProfileEmail extends Component {
    static displayName = EnUserProfileEmail.name;

    constructor(props) {
        super(props);
        this.state = {
            userData: null, // Initialize userData state to null
            newemail1: '',
            newemail2: '',
            statusText: '',
            statusClass: '',
            registerMsgPwd0: '',
            registerMsgPwd1: '',
            submittingProfile: false
        };
    }

    componentDidMount() {
        // Retrieve data from session storage
        const userDataFromStorage = sessionStorage.getItem('userData');

        // Parse the data if it exists and set it to the component state
        if (userDataFromStorage) {
            this.setState({ userData: JSON.parse(userDataFromStorage) });
        }

        {
            // Google analytic
            const documentPath = "ProfileEmail"; // Gets the current URL path
            const clientId = getClientId(); // Use the getClientId function

            // Push data to the GTM dataLayer
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'pageview',
                page: documentPath,
                clientId: clientId,
            });
        }

        window.scrollTo(0, 0);
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleSubmitPwd = async e => {
        e.preventDefault();
        const { newemail1, newemail2, userData } = this.state;
        this.setState({ registerMsgPwd0: '', registerMsgPwd1: '' });

        let canSubmit = true;

        const validateEmail = (email) => {
            return email.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        };

        if (newemail1 === "") {
            this.setState({ registerMsgPwd0: "Please Input Current Username" });
            canSubmit = false;
        }

        if (!validateEmail(newemail1)) {
            this.setState({ registerMsgPwd0: 'Please enter valid Email' });
            canSubmit = false;
        }

        if (newemail2 !== newemail1) {
            this.setState({ registerMsgPwd1: "Confirm Username must match with Username" });
            canSubmit = false;
        }

        if (newemail1 === userData.email) {
            this.setState({ registerMsgPwd1: "New 'Username' must not match with 'Current Username'" });
            canSubmit = false;
        }

        if (canSubmit == false) {
            return;
        }

        // Generate Unix timestamp
        const timestamp = Math.floor(Date.now() / 1000);

        // Prepare and encrypt the payload
        const payload = {
            LoyaltyMemberId: userData.loyaltyMemberId,
            Email: userData.email,
            NewEmail: newemail1,
            RefLang: 'en',
            Timestamp: timestamp
        };
        const encryptedPayload = encryptData(payload);

        // Prevent double-clicking by disabling the submit button and changing cursor to 'wait'
        this.setState({ submittingProfile: true });

        // Add the wait cursor class to the body element
        document.body.classList.add('wait-cursor');

        const token = userData.token;
        
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ Data: encryptedPayload })
        };

        try {
            const response = await fetch(`${API_URL}/api/CrmMemberUpdateUsernameStep01`, requestOptions);
            const data = await response.json();

            if (data && data.responseCode == "OK") {
                window.location.replace("/userprofileemailthx");
            }
            else {
                this.setState({ statusText: data.statusMsg, statusClass: 'text-alert-2' });
            }
        } catch (error) {
            console.error('Error during update profile:', error);
            // Handle error appropriately, e.g., show an error message to the user
        } finally {
            // Enable the submit button and change cursor back to 'auto' after form submission completes
            this.setState({ submittingProfile: false });
            document.body.classList.remove('wait-cursor'); // Remove the wait cursor class
        }
    };

    render() {
        const { userData, newemail1, newemail2, statusText, statusClass, submittingProfile, registerMsgPwd0, registerMsgPwd1 } = this.state;

        return (
            <>
                <EnNavHeader />

                {/* Render the user data */}
                <main id="main">
                    <section id="profile_section" className="profileBanner keyboardFocus" aria-label="Profile" tabIndex={-1}>
                        <EnUserMenu />
                    </section>
                    {userData ? (
                        <section className="section-dashboard overflow-hidden pb-5">
                            <div className="container mt-4">
                                <div className="row mb-5 px-2">
                                    <div className="col-md-8">
                                        <h2 className="h4 fw-semibold mt-4">Change My Username</h2>
                                        <form className="form-sr">
                                            <div className="row">
                                                <div className="col-md-7 mb-3">
                                                    <label className="form-label">New Username *</label>
                                                    <div className="input-group mb-3">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="newemail1"
                                                            onChange={this.handleChange}
                                                            defaultValue={newemail1}
                                                            required />
                                                    </div>                                                    
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>{registerMsgPwd0}</div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-7 mb-3">
                                                    <label className="form-label">Confirm New Username *</label>
                                                    <div className="input-group mb-3">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="newemail2"
                                                            onChange={this.handleChange}
                                                            defaultValue={newemail2}
                                                            required />
                                                    </div>                                                    
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>{registerMsgPwd1}</div>
                                                </div>                                                
                                            </div>                                            
                                            <div className="text-start mt-4">
                                                <button type="button" className="btn btn-primary btn-slim rounded-pill text-decoration-none" disabled={submittingProfile} onClick={this.handleSubmitPwd}>
                                                    {submittingProfile ? 'Submit...' : 'Submit'}
                                                </button>
                                            </div>
                                            <div className={`text-start mt-1 ${statusClass}`}>
                                                {statusText}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </section>
                    ) : (
                        <>
                            {/*Loading*/}
                            <div className="col-md-5">
                                <div className="text-center" style={{ height: '160px' }} />
                                <div className="text-center mt-5 pt-5 mb-5 pb-5">
                                    <h3 className="text-white">LOADING ...</h3>
                                    <img src="media/loading-gif.gif" alt="Loading..." width={80} className="img-fluid" />
                                </div>
                                <div style={{ height: '100px' }} />
                            </div>
                        </>
                    )}
                </main>

                <EnNavFooter />
            </>
        );
    }
}
