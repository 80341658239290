import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { EnNavHeader } from './EnNavHeader';
import { EnNavFooter } from './EnNavFooter';
import EnUserMenu from './EnUserMenu';

import { getClientId } from './utils/analyticsclientIdUtils';
import { encryptData } from './utils/encryptionUtils';
import { API_URL } from './utils/constants'

export class EnUserProfileDelete extends Component {
    static displayName = EnUserProfileDelete.name;

    constructor(props) {
        super(props);
        this.state = {
            userData: null, // Initialize userData state to null
            userInfo: null,
            contactname: '',
            contactmobile: '',
            submitstatus: '',
            requireMsg01: '',
            requireMsg02: '',
            submitting: false // Add a state variable to track form submission status
        };
    }

    componentDidMount() {
        // Retrieve data from session storage
        const userDataFromStorage = sessionStorage.getItem('userData');

        // Parse the data if it exists and set it to the component state
        if (userDataFromStorage) {
            const parsedUserData = JSON.parse(userDataFromStorage);
            this.setState({ userData: parsedUserData }, () => {
                // After setting the state, make the API call if userData is available
                if (this.state.userData && this.state.userData.loyaltyMemberId) {
                    this.callApiWithUserId(this.state.userData.loyaltyMemberId);
                }
            });
        }

        {
            // Google analytic
            const documentPath = "Profile request delete"; // Gets the current URL path
            const clientId = getClientId(); // Use the getClientId function

            // Push data to the GTM dataLayer
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'pageview',
                page: documentPath,
                clientId: clientId,
            });
        }
    }

    async callApiWithUserId(userId) {
        // Add the wait cursor class to the body element
        //document.body.classList.add('wait-cursor');

        // Generate Unix timestamp
        const timestamp = Math.floor(Date.now() / 1000);

        // Prepare and encrypt the payload
        const payload = { UserId: userId, RefLang: "en", Timestamp: timestamp };
        const encryptedPayload = encryptData(payload);

        const token = this.state.userData.token;        
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ Data: encryptedPayload })
        };

        try {
            const response = await fetch(`${API_URL}/api/CrmMemberGetInfo`, requestOptions);
            const data = await response.json();
            if (data) {
                data.salutation = data.salutation == null ? "" : data.salutation;
                this.setState({ userInfo: data, salutation: data.salutation, firstname: data.firstName, lastname: data.lastName, firstnameth: data.firstNameTH, lastnameth: data.lastNameTH, mobilephone: data.mobilePhone });
            }
            else {
                console.error('Error data:');
            }
        } catch (error) {
            console.error('API call error :', error);
        } finally {
            //document.body.classList.remove('wait-cursor'); // Remove the wait cursor class
        }
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleSubmit = async e => {
        e.preventDefault();
        const { userInfo, contactname, contactmobile } = this.state;
        var errorChk = false;

        if (!contactname) {
            this.setState({
                requireMsg01: 'First Name - Last Name Required'
            });
            errorChk = true;
        }

        if (!contactmobile) {
            this.setState({
                requireMsg02: 'Mobile Number Required'
            });
            errorChk = true;
        }

        if (errorChk) {
            return;
        }

        // Generate Unix timestamp
        const timestamp = Math.floor(Date.now() / 1000);

        // Prepare and encrypt the payload
        const payload = { LoyaltyMemberId: userInfo.loyaltyMemberId, Salutation: userInfo.salutation, FirstName: contactname, LastName: '', FirstNameTH: '', LastNameTH: '', MobilePhone: contactmobile, Email: userInfo.email, Source: 'Website', RefLang: 'en', Timestamp: timestamp };
        const encryptedPayload = encryptData(payload);

        // Prevent double-clicking by disabling the submit button and changing cursor to 'wait'
        this.setState({ submitting: true });

        // Add the wait cursor class to the body element
        //document.body.classList.add('wait-cursor');

        const token = this.state.userData.token;
        
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ Data: encryptedPayload })
        };

        try {
            const response = await fetch(`${API_URL}/api/CrmMemberRequestDetele`, requestOptions);
            const data = await response.json();

            if (data.responseCode === "OK") {
                window.location.replace("/userprofiledeletethx");
            }
            else {
                this.setState({ submitstatus: data.responseMessage });

                // Enable the submit button and change cursor back to 'auto' after form submission completes
                this.setState({ submitting: false });
            }
        } catch (error) {
            console.error('Error during api:', error);
            // Handle error appropriately, e.g., show an error message to the user

            // Enable the submit button and change cursor back to 'auto' after form submission completes
            this.setState({ submitting: false });
        } finally {            
            //document.body.classList.remove('wait-cursor'); // Remove the wait cursor class
        }
    };

    render() {
        const { userInfo, contactname, contactmobile, submitting, submitstatus, requireMsg01, requireMsg02 } = this.state;

    return (
        <>
            <EnNavHeader />

            {/* Render the user data */}
            {userInfo && (
                <main id="main">
                    <section id="profile_section" className="profileBanner keyboardFocus" aria-label="Profile" tabIndex={-1}>
                        <EnUserMenu />
                    </section>
                    <section className="section-dashboard overflow-hidden pb-5">
                        <div className="container mt-4">
                            <div className="row mb-5 px-2">
                                <div className="col-md-12">
                                    <h3 className="fw-semibold mt-4">Request Delete Account</h3>
                                    <form className="form-sr" onSubmit={this.handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-4 mt-4 mb-3">
                                                <label className="form-label">First Name - Last Name</label>
                                                <input type="text" className="form-control" name="contactname" value={contactname} onChange={this.handleChange} />
                                                <div className="text-alert-2 mt-2">
                                                    {requireMsg01}
                                                </div>
                                            </div>                                            
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4 mb-3">
                                                <label className="form-label">Mobile Number</label>
                                                <input type="text" className="form-control" name="contactmobile" value={contactmobile} onChange={this.handleChange} />
                                                <div className="text-alert-2 mt-2">
                                                    {requireMsg02}
                                                </div>
                                            </div>                                            
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4 mb-3">
                                                <label className="form-label">Account request to delete</label>
                                                <input type="text" className="form-control" defaultValue={userInfo.email} disabled />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 mt-4 mb-3">
                                                <p><strong>The Right(s) Requested by the Data Subject: </strong></p>
                                                <p>Right to erasure: you can require the deletion of your Starbucks&reg; Reward account and personal data, to the extent permitted by law. Note that this is not a blanket right to require all your personal data to be deleted. We will consider each request carefully in accordance with the requirements of any laws relating to the processing of your personal data. </p>
                                                <p className="mt-4"><strong>Disclaimer </strong></p>
                                                <p>The applicant should be noted that the Company may need to reject the request of the applicant in accordance with the relevant law in case of the following events: <br />
                                                    (1) the applicant fails to indicate that it is the data subject or is authorised to submit the request <br />
                                                    (2) the request is unreasonable, e.g., there is no such personal data retained by the Company or the data subject is not entitled to the requested right, etc. <br />
                                                    (3) the said request is repetitive, e.g., the request is of the same nature or has the similar or repetitive content without any reasonable grounds <br />
                                                    (4) the Company has legitimate ground to reject the request of the data subject; in this regard, the relevant details are contained in the Privacy Notice which is available at the Company’s website click here [or downloadable from the QR code below]</p>
                                                <p className="text-center"><img src="media/rewards/qr-code-en.png" alt="QR Code" className="img-fluid" /></p>
                                                <p>(5) If you submit a request to exercise the right to delete your member account and personal information and your member account still has monetary value, the Company will transfer the money back to your bank account only in the event that the amount in your member account is more than the transfer fee charged by the bank. </p>
                                                <p>Generally, no cost will be charged by the Company in proceeding with the right exercise request of data subject. However, if the request is unreasonable or excessively repetitive, the Company may charge the cost in respect of the proceeding as appropriate.</p>
                                                <p> After completing the consideration of the requested right of the data subject, the Company will notify the outcome to the applicant and the data subject and will take the relevant proceeding within 30 days from the receipt date of the request. Occasionally it may take us longer than 30 days if the request is particularly complex or the applicant has made a number of requests. In this case, we will notify and keep the applicant updated.</p>
                                                <p className="mt-4"><strong>Acknowledgement </strong></p>
                                                <p>The Company will collect from this data subject request the personal data of the data subject and/or the applicant acting on behalf of the data subject, and will use such personal data solely for the purpose of proceeding with the request of the data subject as prescribed under the Personal Data Protection Act B.E. 2562 (2019) (the “PDPA”). The information contained in this request may be disclosed to subsidiaries or affiliates of the Company or a member under the same group to the extent necessary for the above purpose, and will be retained until the proceeding with the request of the data subject is completed, or until the process of objection or rejection in respect of the request is settled in case where the Company is unable to proceed with the request of the data subject on reasonable grounds as prescribed by the PDPA.</p>
                                                <p> The applicant has read and understand the content of this request in whole and hereby confirms that the information which has been notified to the Company is correct and true. The applicant understands that the verification to confirm the authority and identity of the applicant and the data subject is necessary for the consideration and proceeding with the request. Should the applicant intentionally give any fault information, the Company may take legal actions against the applicant. The Company may request additional information from the applicant for the purpose of such verification to ensure that request to exercise the rights of the data subject is properly handled.</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6 mb-3 text-start">
                                                <Link to="/userprofile" className="btn btn-outline-green rounded-pill px-4">Cancel</Link>
                                            </div>
                                            <div className="col-6 mb-3 text-end">
                                                <button type="submit" className="btn btn-outline-green rounded-pill px-4" disabled={submitting}>
                                                    Delete Account
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {submitstatus}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            )}

            <EnNavFooter />
      </>
    );
  }
}
