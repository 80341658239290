import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class EnNavFooter extends Component {
    static displayName = EnNavFooter.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            isAuthenticated: !!sessionStorage.getItem('username') // Check if username exists in session storage
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        const { isAuthenticated } = this.state;

        return (
            <>
                <footer id="footer" className="text-muted py-5 pb-4 bg-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 mb-4"><span className="footer-menu-title"><a href="https://www.starbucks.co.th/about-us/#About-Us" rel="noopener noreferrer">ABOUT US</a></span>
                                <div>
                                    <ul>
                                        <li><a href="https://www.starbucks.co.th/about-us/#About-Us" rel="noopener noreferrer">Our Heritage</a></li>
                                        <li><a href="https://www.starbucks.co.th/about-us/our-company/" rel="noopener noreferrer">Our Company</a></li>
                                        <li><a href="https://www.starbucks.co.th/responsibility/" rel="noopener noreferrer">Responsibility</a></li>
                                        <li><a href="https://www.starbucks.co.th/career/" rel="noopener noreferrer">Career Center</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-3 mb-4"><span className="footer-menu-title"><a href="https://www.starbucks.co.th/coffee/">Coffee &amp; Tea at Home</a></span>
                                <div>
                                    <ul>
                                        <li><a href="https://www.starbucks.co.th/coffee-quality/" rel="noopener noreferrer">Coffee Story</a></li>
                                        <li><a href="https://www.starbucks.co.th/coffee/" rel="noopener noreferrer">Coffee &amp; Tea at Home</a></li>
                                        <li><a href="https://www.starbucks.co.th/coffee/" rel="noopener noreferrer">How to Brew</a></li>
                                        <li><a href="https://www.starbucks.co.th/coffee-workshop/" rel="noopener noreferrer">Coffee Workshop</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-3 mb-4">
                                <ul>
                                    <li><Link to="/">STARBUCKS REWARDS</Link></li>
                                    <li><a href="https://www.starbucks.co.th/promotions/" rel="noopener noreferrer">PROMOTION</a></li>
                                    <li><a href="https://www.starbucks.co.th/starbucks-card-corporate-sale/" rel="noopener noreferrer">STARBUCKS CARD CORPORATE SALE</a></li>
                                    <li><a href="https://www.starbucks.co.th/cashless-store/" rel="noopener noreferrer">ADDITIONAL INFORMATION</a></li>
                                    <li><a href="https://www.starbucks.co.th/customer-service/" rel="noopener noreferrer">CONTACT US</a></li>
                                </ul>
                            </div>
                            <div className="col-md-3 mb-4">
                                <ul className="footer-lang text-start text-md-end">
                                    <li><Link to="/" ><img src={require("./media/us.png")} alt="English" />English</Link></li>
                                    <li><Link to="/ThHome" ><img src={require("./media/th.png")} alt="ไทย" />ไทย</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>
                <div className="footer-widgets footer footer-2">
                    <div className="row m-0 p-0">
                        <div className="col col-lg-12 m-0 p-0">
                            <div className="container">
                                <section className="section-footer-2">
                                    <div className="section-content relative">
                                        <div className="row row-collapse py-4">
                                            <div className="col-md-6 px-0 mb-4 mb-md-0">
                                                <div className="col-inner">
                                                    <div className="box-footer-social text-center text-md-start">
                                                        <a href="https://www.facebook.com/StarbucksThailand/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={require("./media/rewards/icon-facebook@2x.png")} alt="facebook" width={100} height={100} /></a>
                                                        <a href="https://www.instagram.com/starbucksthailand/" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={require("./media/rewards/icon-ig@2x.png")} alt="Instagram" width={100} height={100} /></a>
                                                        <a href="https://page.line.me/853tzvmu?openQrModal=true" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={require("./media/rewards/icon-line@2x.png")} alt="LINE APP" width={100} height={100} /></a>
                                                        <a href="https://www.tiktok.com/@starbucksthailand" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={require("./media/rewards/icon-tiktok@2x.png")} alt="Tiktok" width={100} height={100} /></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 px-0 mb-4 mb-md-0">
                                                <div className="col-inner">
                                                    <div className="box-mobile-app-icons text-center text-md-end">
                                                        <a href="https://apps.apple.com/th/app/starbucks-thailand/id898062370" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={require("./media/rewards/btn-app-store.png")} alt="" width={148} height={48} /></a>
                                                        <a href="https://play.google.com/store/apps/details?id=com.starbucks.thailand" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={require("./media/rewards/btn-google-play.png")} alt="" width={148} height={48} /></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="absolute-footer dark">
                    <div className="container clearfix">
                        <p className="float-none float-md-end mb-1 text-center text-md-end">©2024 Coffee Concepts Retail Co.,Ltd. All rights reserved.</p>
                        <ul id="menu-footer-bottom-menu" className="mb-1 text-center text-md-start">
                            <li><a href="https://www.starbucks.co.th/online-policies/web-accessibility/" rel="noopener noreferrer">WEB ACCESSIBILITY</a></li>
                            <li><a href="https://www.starbucks.co.th/online-policies/privacy-notice/" rel="noopener noreferrer">PRIVACY NOTICE</a></li>
                            <li><a href="https://www.starbucks.co.th/online-policies/terms-of-use/" rel="noopener noreferrer">TERMS OF USE</a></li>
                            <li><a href="https://www.starbucks.co.th/site-map/" rel="noopener noreferrer">SITE MAP</a></li>
                        </ul>
                    </div>
                </div>
                <div className="d-none">
                    <img src="./media/rewards/xl-hero-desktop.webp"></img>
                </div>
            </>
        );
    }
}