import React, { Component } from 'react';
import { Container } from 'reactstrap';


export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <>                
                <Container className="container-w-100">
                    {this.props.children}
                </Container>                
            </>
        );
    }
}
