import React, { Component } from 'react';

import { EnNavHeader } from './EnNavHeader';
import { EnNavFooter } from './EnNavFooter';

import EnUserMenu from './EnUserMenu';

import { getClientId } from './utils/analyticsclientIdUtils';
import { encryptData } from './utils/encryptionUtils';
import { API_URL } from './utils/constants';

import imgStar from './media/rewards/SR_Stars_gold_large.svg'

export class EnUserStarTransaction extends Component {
    static displayName = EnUserStarTransaction.name;

    constructor(props) {
        super(props);
        this.state = {
            userData: null, // Initialize userData state to null
            transactionPlus: [],
            transactionMinus: [],
            selectedRedeemed: null,
            selectedIndex: null, // Add selectedData to state            
            groupedTransactionsPlus: [],
            groupedTransactionsMinus: [],
            renderCheck: false
        };
    }

    handleButtonClick = (uniqueKey) => {
        // Split the uniqueKey to get index and idx
        const [index, idx] = uniqueKey.split('-').map(Number);

        // Retrieve the specific monthYear key using the index
        const monthYear = Object.keys(this.state.groupedTransactionsMinus)[index];

        // Access the specific item using monthYear and idx
        const item = this.state.groupedTransactionsMinus[monthYear][idx];

        this.setState({ selectedIndex: index, selectedRedeemed: item });
    };

    componentDidMount() {
        // Retrieve data from session storage
        const userDataFromStorage = sessionStorage.getItem('userData');

        // Parse the data if it exists and set it to the component state
        if (userDataFromStorage) {
            const parsedUserData = JSON.parse(userDataFromStorage);
            this.setState({ userData: parsedUserData }, () => {
                // After setting the state, make the API call if userData is available
                if (this.state.userData && this.state.userData.loyaltyMemberId) {
                    this.callApiWithUserId(this.state.userData.loyaltyMemberId);
                }
            });
        }

        {
            // Google analytic
            const documentPath = "StarTransaction"; // Gets the current URL path
            const clientId = getClientId(); // Use the getClientId function

            // Push data to the GTM dataLayer
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'pageview',
                page: documentPath,
                clientId: clientId,
            });
        }
    }

    // Helper function to get the month and year from the transaction date
    getMonthYear = (dateStr) => {
        const date = new Date(dateStr);
        if (isNaN(date.getTime())) {
            console.error(`Invalid date string: ${dateStr}`);
            return 'Invalid Date';
        }

        // Array of month names
        const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];

        const monthName = monthNames[date.getMonth()];
        const year = date.getFullYear();

        return `${monthName} ${year}`; // e.g., "June 2024"
    };

    async callApiWithUserId(userId) {
        // Add the wait cursor class to the body element
        //document.body.classList.add('wait-cursor');

        // Generate Unix timestamp
        const timestamp = Math.floor(Date.now() / 1000);

        // Prepare and encrypt the payload
        const payload = { UserId: userId, RefLang: "en", Timestamp: timestamp };
        const encryptedPayload = encryptData(payload);

        const token = this.state.userData.token;
        
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ Data: encryptedPayload })
        };

        try {
            const response = await fetch(`${API_URL}/api/CrmMemberGerStartransactions`, requestOptions);
            const data = await response.json();
            
            if (data) {
                // Check if starTransactions exists and has data
                if (data.starTransactions && Array.isArray(data.starTransactions)) {
                    //prepare var
                    let selectedRedeemed = null;
                    let groupedTransactionsPlus = [];
                    let groupedTransactionsMinus = [];

                    //filter starTransactions to separate Plus and Minus
                    const filteredPlus = data.starTransactions.filter(item =>
                        item.stars > 0 && // Filter by stars greater than 0
                        item.transactionType !== 3 // Filter transactionType
                    );
                    if (filteredPlus.length > 0) {
                        const sortedPlusList = filteredPlus.sort((a, b) => new Date(b.transactionDate) - new Date(a.transactionDate));

                        // Group sortedPlusList by month
                        groupedTransactionsPlus = sortedPlusList.reduce((acc, transaction) => {
                            const monthYear = this.getMonthYear(transaction.transactionDate);
                            if (!acc[monthYear]) {
                                acc[monthYear] = [];
                            }
                            acc[monthYear].push(transaction);
                            return acc;
                        }, {});
                    }
                    

                    const filteredMinus = data.starTransactions.filter(item =>
                        item.stars < 0 && // Filter by stars less than 0
                        item.transactionType !== 3 // Filter transactionType
                    );
                    if (filteredMinus.length > 0) {
                        const sortedMinusList = filteredMinus.sort((a, b) => new Date(b.transactionDate) - new Date(a.transactionDate));

                        selectedRedeemed = sortedMinusList.length > 0 ? sortedMinusList[0] : null;

                        // Group sortedMinusList by month
                        groupedTransactionsMinus = sortedMinusList.reduce((acc, transaction) => {
                            const monthYear = this.getMonthYear(transaction.transactionDate);
                            if (!acc[monthYear]) {
                                acc[monthYear] = [];
                            }
                            acc[monthYear].push(transaction);
                            return acc;
                        }, {});
                    }                                                                               

                    this.setState({ selectedRedeemed: selectedRedeemed, renderCheck: true, groupedTransactionsMinus: groupedTransactionsMinus, groupedTransactionsPlus: groupedTransactionsPlus });
                }
            }
            else {
                console.error('Error data:');
            }
        } catch (error) {
            console.error('API call error :', error);
        } finally {
            //document.body.classList.remove('wait-cursor'); // Remove the wait cursor class
        }
    }

    render() {
        const { userData, selectedRedeemed, selectedIndex, renderCheck, groupedTransactionsMinus, groupedTransactionsPlus } = this.state;        
        return (
            <>
                <EnNavHeader />

                {/* Render the user data */}
                {userData && (
                    <main id="main">
                        <section id="profile_section" className="profileBanner keyboardFocus" aria-label="Profile" tabIndex={-1}>
                            <EnUserMenu />
                        </section>

                        {renderCheck ? (
                            <>
                                <section className="section-transaction overflow-hidden pb-5">
                                    <div className="container mt-4">
                                        <div className="row mb-5 px-2">
                                            <div className="col-md-10">
                                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Your Stars Transaction</button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Rewards Redemption</button>
                                                    </li>
                                                </ul>
                                                <div className="tab-content pt-4" id="myTabContent">
                                                    <div className="tab-pane tab-transaction fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex={0}>
                                                        <div className="row">
                                                            <div className="col-md-5">                                                                
                                                                {/* Render transactionPlus data list */}
                                                                {Object.keys(groupedTransactionsPlus).map((monthYear, index) => (
                                                                    <div key={index}>
                                                                        <div className="row mt-3 mb-3">
                                                                            <div className="col-10"><p className="mb-1 text-green-1"><strong>{monthYear}</strong></p></div>
                                                                            <div className="col-2 text-end mb-2"><img src={imgStar} alt="star" className="img-fluid" style={{ width: '15px' }} /></div>
                                                                        </div>
                                                                        {groupedTransactionsPlus[monthYear].map((item, idx) => (
                                                                            <span key={idx}>
                                                                                <div className="row me-0">
                                                                                    <div className="col-10">
                                                                                        <p className="mb-1"><strong>{item.service_EN}</strong></p>
                                                                                        <p className="mb-1 text-gray-2 small">{item.displayTransactionDate}</p>
                                                                                        <p className="mb-1 text-gray-2 small">{item.orderNumber}</p>
                                                                                    </div>
                                                                                    <div className="col-2 text-end px-0">
                                                                                        <p className="mb-3 fw-semibold">+{item.displayStars}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <hr className="mt-2 mb-3" />
                                                                            </span>
                                                                        ))}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        {groupedTransactionsPlus.length == 0 && (
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-md-5"> 
                                                                        There is no Stars Transaction.
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                    <div className="tab-pane tab-redemption fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex={0}>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="d-flex align-items-start">
                                                                    <div className="nav flex-column nav-pills w-50 me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                                                        {/* Render transactionMinus data list */}
                                                                        {Object.keys(groupedTransactionsMinus).map((monthYear, index) => (
                                                                            <div key={index}>
                                                                                <div className="row mt-3 mb-3">
                                                                                    <div className="col-10"><p className="mb-4 text-green-1"><strong>{monthYear}</strong></p></div>
                                                                                    <div className="col-2 text-end mb-2"><img src={imgStar} alt="star" className="img-fluid" style={{ width: '15px' }} /></div>
                                                                                </div>
                                                                                {groupedTransactionsMinus[monthYear].map((item, idx) => {
                                                                                    const uniqueKey = `${index}-${idx}`;
                                                                                    return (
                                                                                        <button
                                                                                            key={uniqueKey}
                                                                                            className={`nav-link ${selectedIndex === uniqueKey ? 'active' : ''}`}
                                                                                            id="v-pills-tab1"
                                                                                            data-bs-toggle="pill"
                                                                                            data-bs-target="#v-pills-con1"
                                                                                            type="button"
                                                                                            role="tab"
                                                                                            aria-controls="v-pills-con1"
                                                                                            aria-selected="true"
                                                                                            onClick={() => this.handleButtonClick(uniqueKey)}>
                                                                                            <div className="row me-0">
                                                                                                <div className="col-md-2 text-center p-0"><img src={`media/rewards/${item.displayImg01}`} alt="Free Beverage" className="img-fluid" /></div>
                                                                                                <div className="col-md-8 pe-0">
                                                                                                    <p className="mt-2 mb-1 text-black fw-semibold">{item.displayPronameEN}</p>
                                                                                                    <p className="small-75 mb-2 text-gray-2">Redeemed via {item.service_EN} on {item.displayTransactionDate}</p>
                                                                                                </div>
                                                                                                <div className="col-2 text-end px-0"><p className="mt-2 text-black fw-semibold">{item.displayStars}</p></div>
                                                                                            </div>
                                                                                        </button>
                                                                                    );
                                                                                })}
                                                                            </div>
                                                                        ))}
                                                                        {groupedTransactionsMinus.length == 0 && (
                                                                            <>
                                                                                <div className="row">
                                                                                    <div className="col-10">
                                                                                        There is no Rewards Redemption.
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    <div className="tab-content w-50 border-top-0" id="v-pills-tabContent">
                                                                        <div className="tab-pane fade show active" id="v-pills-con1" role="tabpanel" aria-labelledby="v-pills-tab1" tabIndex={0}>
                                                                            {/* Render the first reward item */}
                                                                            {selectedRedeemed && (
                                                                                <>
                                                                                    <div className="row m-0">
                                                                                        <div className="col-12 text-center p-0"><img src={`media/rewards/${selectedRedeemed.displayImg02}`} alt="Free Beverage" className="img-fluid" /></div>
                                                                                        <div className="col-8 col-md-9 mt-4 pe-0">
                                                                                            <p className="mt-2 mb-1 text-black fw-semibold">{selectedRedeemed.promotionName}</p>
                                                                                            <p className="small-75 mb-2 text-gray-2">Redeemed on {selectedRedeemed.displayTransactionDate}</p>
                                                                                        </div>
                                                                                        <div className="col-4 col-md-3 mt-4 p-0 text-center"><p className="mt-2 text-black fw-semibold">{selectedRedeemed.displayStars} <img src={imgStar} alt="Gold Star" className="img-fluid" style={{ width: '15px' }} /></p></div>
                                                                                        <hr className="py-1" />
                                                                                    </div>

                                                                                    {selectedRedeemed.starRewardRedeemeds && selectedRedeemed.starRewardRedeemeds.length > 0 && (
                                                                                        <div>
                                                                                            <strong className="text-green-1">Expiring Stars</strong>
                                                                                            {selectedRedeemed.starRewardRedeemeds.map((item, index) => (
                                                                                                <div className="row m-0" key={index}>
                                                                                                    <div className="col-8 col-md-9 pe-0">
                                                                                                        <p className="mb-1 text-black small">{item.displayExpiryDate}</p>
                                                                                                    </div>
                                                                                                    <div className="col-4 col-md-3 p-0 text-center">
                                                                                                        <p className="mb-1 text-black"><img src={imgStar} alt="Gold Star" className="img-fluid me-2" style={{ width: '15px' }} /> {item.starRewardRedeemed}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ))}
                                                                                        </div>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </>
                        ) : (
                            <>
                                {/*Loading*/}
                                <div className="col-md-12">
                                    <div className="text-center" style={{ height: '160px' }} />
                                    <div className="text-center mt-5 pt-5 mb-5 pb-5">
                                        <h3 className="text-white">LOADING ...</h3>
                                        <img src="media/loading-gif.gif" alt="Loading..." width={80} className="img-fluid" />
                                    </div>
                                    <div style={{ height: '100px' }} />
                                </div>
                            </>
                        )}


                    </main>
                )}

                <EnNavFooter />
            </>
        );
    }
}
