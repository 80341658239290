import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { EnNavHeader } from './EnNavHeader';
import { EnNavFooter } from './EnNavFooter';
import EnUserMenu from './EnUserMenu';

import { getClientId } from './utils/analyticsclientIdUtils';
import { encryptData } from './utils/encryptionUtils';
import { API_URL } from './utils/constants'

class EnUserCardLostClass extends Component {    
    constructor(props) {
        super(props);
        this.state = {
            userData: null, // Initialize userData state to null
            parentState: this.props.location.state,
            canSubmitState: true,
            canSubmitMsg: '',
            reason: 'Lost',
            actionSuccess:false
        };
    }

    componentDidMount() {
        // Retrieve data from session storage
        const userDataFromStorage = sessionStorage.getItem('userData');

        // Parse the data if it exists and set it to the component state
        if (userDataFromStorage) {
            this.setState({ userData: JSON.parse(userDataFromStorage) });
        }

        {
            // Google analytic
            const documentPath = "Card Lost"; // Gets the current URL path
            const clientId = getClientId(); // Use the getClientId function

            // Push data to the GTM dataLayer
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'pageview',
                page: documentPath,
                clientId: clientId,
            });
        }
        window.scrollTo(0, 0);
    }

    async callApiCardInfoUpdate(loyaltyMemberId, cardNumber, cardStatus, cardNickName, defaultCard, refLang) {
        // Generate Unix timestamp
        const timestamp = Math.floor(Date.now() / 1000);

        const payload = { LoyaltyMemberId: loyaltyMemberId, CardNumber: cardNumber, CardStatus: cardStatus, CardNickName: cardNickName, DefaultCard: defaultCard, RefLang: refLang, Timestamp: timestamp };
        const encryptedPayload = encryptData(payload);

        const token = this.state.userData.token;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ Data: encryptedPayload })
        };

        try {
            // Add the wait cursor class to the body element
            document.body.classList.add('wait-cursor');

            const response = await fetch(`${API_URL}/api/CrmCardUpdateWFd`, requestOptions);
            const data = await response.json();
            if (data && data.responseCode === 'OK') {
                this.setState({
                    canSubmitState: false,
                    canSubmitMsg: 'Report Lost or Stolen success.',
                    actionSuccess:true
                });
            }
            else {
                //some error here
                this.setState({
                    canSubmitState: true,
                    canSubmitMsg: data.statusMsg,
                    actionSuccess: false
                });
            }
        } catch (error) {
            console.error('API call error:', error);
        }
        finally {
            document.body.classList.remove('wait-cursor'); // Remove the wait cursor class
        }
    }

    handleCardUpdateInfo(cardNumber) {
        this.callApiCardInfoUpdate(this.state.userData.loyaltyMemberId, cardNumber, "285560001", "", false, "en");
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    render() {
        const { userData, parentState, canSubmitState, canSubmitMsg, reason, actionSuccess } = this.state;
        const selectedCard = parentState.selectedCard;
        const selectedCardFromlist = parentState.selectedCardFromlist;

        let selectedCardNumber, selectedcardBalance, selectedCardStatus, selectedcardImg;
        if (selectedCard) {
            selectedcardBalance = selectedCard.cardBalance;
        }
        if (selectedCardFromlist) {
            selectedCardNumber = selectedCardFromlist.cardNumber;
            selectedCardStatus = selectedCardFromlist.cardStatusText;
            selectedcardImg = selectedCardFromlist.cardImg;
        }

        return (
            <>
                <EnNavHeader />                
                {/* Render the user data */}
                {userData && (
                    <main id="main">
                        <section id="profile_section" className="profileBanner keyboardFocus" aria-label="Profile" tabIndex={-1}>
                            <EnUserMenu />
                        </section>
                        <section className="section-dashboard overflow-hidden pb-5">
                            <div className="container mt-4">
                                <div className="row mb-5 px-2">
                                    <div className="col-md-12">
                                        <h2 className="h4 fw-semibold my-4">Report Lost or Stolen</h2>
                                        <form className="form-sr">
                                            <div className="row mb-4 g-3 align-items-center">
                                                <div className="col-md-4">
                                                    <img src={`media/rewards/cards/${selectedcardImg}`} alt={selectedCardNumber} className="img-fluid" />
                                                </div>
                                                {actionSuccess ? (
                                                    <>
                                                        <div className="col-md-6 ps-4">
                                                            <h4 className="fw-semibold">Card lost Success</h4>
                                                        </div>
                                                    </>
                                                ): (
                                                    <>
                                                            <div className="col-md-6 ps-4">
                                                                <h4 className="h5 fw-semibold">({selectedCardNumber})</h4>
                                                                <h4 className="h5 fw-semibold mb-3"><span className="text-green-1 fw-semibold">฿ {selectedcardBalance}</span></h4>
                                                                <p className="fw-semibold">Status : <span>{selectedCardStatus}</span></p>
                                                                <p className="text-alert-2 mb-2">Please transfer your balance to another card before reporting lost. <br />Otherwise this card will no longer be valid.</p>
                                                                <p>Any inquiries, please contact Starbucks Customer Care 02-339-0996 <br />Mon-Sun 9:00 AM - 6:00 PM.</p>
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <label className="form-label">Reason</label>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <select className="form-select" name="reason"
                                                                            value={reason}
                                                                            onChange={this.handleChange} required>
                                                                            <option value="Lost">Lost</option>
                                                                            <option value="Stolen">Stolen</option>
                                                                            <option value="Damaged">Damaged</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        {canSubmitState ? (
                                                                            <button type="button" className="btn btn-primary btn-slim rounded-pill" data-bs-toggle="modal" data-bs-target="#ReportLostStolen">Submit</button>
                                                                        ) : (
                                                                            <span>{canSubmitMsg}</span>
                                                                        )}
                                                                    </div>                                                                    
                                                                    {/* Modal */}
                                                                    <div className="modal fade" id="ReportLostStolen" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="ReportLostStolenLabel" aria-hidden="true">
                                                                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                                                            <div className="modal-content">
                                                                                <div className="modal-body">
                                                                                    <h3 className="mt-4">Report lost or stolen</h3>
                                                                                    <p>Confirm this card as lost?</p>
                                                                                </div>
                                                                                <div className="modal-footer">
                                                                                    <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>
                                                                                    <button type="button" className="btn btn-success text-white text-decoration-none" data-bs-dismiss="modal" onClick={() => this.handleCardUpdateInfo(selectedCardNumber)}>
                                                                                        Confirm
                                                                                </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    </>
                                                )}                                                
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>
                )}

                <EnNavFooter />
            </>
        );
    }
}

export const EnUserCardLost = withRouter(EnUserCardLostClass);