import React, { Component } from 'react';

import { ThNavHeader } from './ThNavHeader';
import { ThNavFooter } from './ThNavFooter';
import ThUserMenu from './ThUserMenu';

import { getClientId } from './utils/analyticsclientIdUtils';
import { encryptData } from './utils/encryptionUtils';
import { API_URL } from './utils/constants';

export class ThUserProfilePwd extends Component {
    static displayName = ThUserProfilePwd.name;

    constructor(props) {
        super(props);
        this.state = {
            userData: null, // Initialize userData state to null
            passwordcurrent: '',
            passwordnew: '',
            passwordnew2: '',
            statusText: '',
            statusClass: '',
            registerMsgPwd0: '',
            registerMsgPwd: '',
            registerMsgPwd2: '',
            showPasswordTips: false,
            submittingProfile: false
        };
    }

    componentDidMount() {
        // Retrieve data from session storage
        const userDataFromStorage = sessionStorage.getItem('userData');

        // Parse the data if it exists and set it to the component state
        if (userDataFromStorage) {
            //this.setState({ userData: JSON.parse(userDataFromStorage) });
            this.setState({ userData: JSON.parse(userDataFromStorage) }, () => {
                // Initialize this java after loaded
                window.$(".toggle-password").click(function () {
                    window.$(this).toggleClass("fa-eye fa-eye-slash");
                    var input = window.$(window.$(this).attr("toggle"));
                    if (input.attr("type") === "password") {
                        input.attr("type", "text");
                    } else {
                        input.attr("type", "password");
                    }
                });
            });
        }

        {
            // Google analytic
            const documentPath = "TH ProfilePWD"; // Gets the current URL path
            const clientId = getClientId(); // Use the getClientId function

            // Push data to the GTM dataLayer
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'pageview',
                page: documentPath,
                clientId: clientId,
            });
        }

        window.scrollTo(0, 0);
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleSubmitPwd = async e => {
        e.preventDefault();
        const { passwordcurrent, passwordnew, passwordnew2, userData } = this.state;
        this.setState({ registerMsgPwd0: '', registerMsgPwd: '', registerMsgPwd2: '' });

        let canSubmit = true;

        if (passwordcurrent === "") {
            this.setState({ registerMsgPwd0: "กรุณากรอกรหัสผ่านปัจจุบัน" });
            canSubmit = false;
        }        

        const errors = this.validatePassword(passwordnew);
        if (errors) {
            this.setState({ registerMsgPwd: errors });
            canSubmit = false;
        }

        if (passwordnew2 === "") {
            this.setState({ registerMsgPwd2: "กรุณากรอกยืนยันรหัสผ่านใหม่" });
            canSubmit = false;
        }

        if (passwordnew !== passwordnew2) {
            this.setState({ registerMsgPwd2: "รหัสผ่านที่คุณกรอกไม่ตรงกัน" });
            canSubmit = false;
        }

        if (canSubmit == false) {
            return;
        }

        // Generate Unix timestamp
        const timestamp = Math.floor(Date.now() / 1000);

        // Prepare and encrypt the payload
        const payload = {
            LoyaltyMemberId: userData.loyaltyMemberId,
            Email: userData.email,
            CurrentPassword: passwordcurrent,
            NewPassword: passwordnew,
            RefLang: 'th',
            Timestamp: timestamp
        };
        const encryptedPayload = encryptData(payload);

        // Prevent double-clicking by disabling the submit button and changing cursor to 'wait'
        this.setState({ submittingProfile: true });

        // Add the wait cursor class to the body element
        document.body.classList.add('wait-cursor');

        const token = this.state.userData.token;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ Data: encryptedPayload })
        };

        try {
            const response = await fetch(`${API_URL}/api/CrmMemberUpdatePwd`, requestOptions);
            const data = await response.json();
            if (data && data.responseCode == "OK") {
                //this.setState({ statusText: "เปลี่ยนรหัสผ่านสำเร็จ", statusClass: 'text-success' });
                window.location.replace("/thuserprofilepwdthx");
            }
            else {
                this.setState({ statusText: data.statusMsg, statusClass: 'text-alert-2' });
            }
        } catch (error) {
            console.error('Error during update profile:', error);
            // Handle error appropriately, e.g., show an error message to the user
        } finally {
            // Enable the submit button and change cursor back to 'auto' after form submission completes
            this.setState({ submittingProfile: false });
            document.body.classList.remove('wait-cursor'); // Remove the wait cursor class
        }
    };

    validatePassword = (password) => {
        const minLength = 9;
        const maxLength = 15;
        const numberRegex = /[0-9]/;
        const capitalLetterRegex = /[A-Z]/;
        const lowerCaseLetterRegex = /[a-z]/;
        const specialCharRegex = /[!@#$*;&]/;

        if (password.length === 0) {
            return 'กรุณากรอกรหัสผ่านใหม่';
        }
        if (password.length < minLength || password.length > maxLength) {
            return `ต้องมีความยาวรหัสตั้งแต่ ${minLength} ถึง ${maxLength} ตัวอักษร`;
        }
        if (!numberRegex.test(password)) {
            return 'ต้องประกอบด้วยตัวเลขอย่างน้อยหนึ่งตัว';
        }
        if (!capitalLetterRegex.test(password)) {
            return 'ต้องประกอบด้วยตัวอักษรพิมพ์ใหญ่อย่างน้อยหนึ่งตัวอักษร';
        }
        if (!lowerCaseLetterRegex.test(password)) {
            return 'ต้องประกอบด้วยตัวอักษรพิมพ์เล็กอย่างน้อยหนึ่งตัวอักษร';
        }
        if (!specialCharRegex.test(password)) {
            return 'ต้องประกอบด้วยตัวอักษรพิเศษอย่างน้อยหนึ่งตัวอักษร เช่น (e.g., !,@,#,$,*,;,&). ';
        }

        return ''; // Return an empty string if all checks pass
    };

    handleFocus = () => {
        this.setState({ showPasswordTips: true });
    };

    handleBlur = () => {
        this.setState({ showPasswordTips: false });
    };

    render() {
        const { userData, passwordcurrent, passwordnew, passwordnew2, statusText, statusClass, submittingProfile, showPasswordTips, registerMsgPwd0, registerMsgPwd, registerMsgPwd2 } = this.state;

    return (
        <>
            <ThNavHeader />            

            {/* Render the user data */}
            {userData && (
                <main id="main">
                    <section id="profile_section" className="profileBanner keyboardFocus" aria-label="Profile" tabIndex={-1}>
                        <ThUserMenu />
                    </section>
                    <section className="section-dashboard overflow-hidden pb-5">
                        <div className="container mt-4">
                            <div className="row mb-5 px-2">
                                <div className="col-md-8">
                                    <h2 className="h4 fw-semibold mt-4">เปลียนรหัสผ่าน</h2>
                                    <form className="form-sr">
                                        <div className="row">
                                            <div className="col-md-4 mb-3">
                                                <label className="form-label">รหัสผ่านปัจจุบัน *</label>
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="password"
                                                        className="form-control border-end-0"
                                                        name="passwordcurrent"
                                                        onChange={this.handleChange}
                                                        defaultValue={passwordcurrent}
                                                        required />
                                                    <span className="input-group-text"><i toggle="input[name='passwordcurrent']" className="fa fa-fw fa-eye-slash toggle-password" /></span>
                                                </div>                                                
                                                <div className="invalid-feedback" style={{ display: 'block' }}>{registerMsgPwd0}</div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4 mb-3">
                                                <label className="form-label">รหัสผ่านใหม่ *</label>
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="password"
                                                        className="form-control border-end-0"
                                                        name="passwordnew"
                                                        onChange={this.handleChange}
                                                        onFocus={this.handleFocus}
                                                        onBlur={this.handleBlur}
                                                        defaultValue={passwordnew}
                                                        required />
                                                    <span className="input-group-text"><i toggle="input[name='passwordnew']" className="fa fa-fw fa-eye-slash toggle-password" /></span>
                                                </div>                                                
                                                <div className="invalid-feedback" style={{ display: 'block' }}>{registerMsgPwd}</div>
                                            </div>                                            
                                            {showPasswordTips && (
                                                <div id="new-password-field-tips" className="passwordtips-box">
                                                    <h4 className="h6 text-decoration-underline">Password Tips</h4>
                                                    <p className="form-text">
                                                        ความยาวรหัสตั้งแต่ 9 ถึง 15 ตัวอักษร<br />
                                                        ประกอบด้วยตัวเลขอย่างน้อยหนึ่งตัว<br />
                                                        ประกอบด้วยตัวอักษรพิมพ์ใหญ่อย่างน้อยหนึ่งตัวอักษร<br />
                                                        ประกอบด้วยตัวอักษรพิมพ์เล็กอย่างน้อยหนึ่งตัวอักษร<br />
                                                        ประกอบด้วยตัวอักษรพิเศษอย่างน้อยหนึ่งตัวอักษร เช่น !,@,#,$,*,;,&
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4 mb-3">
                                                <label className="form-label">ยืนยันรหัสผ่านใหม่ *</label>
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="password"
                                                        className="form-control border-end-0"
                                                        name="passwordnew2"
                                                        onChange={this.handleChange}
                                                        defaultValue={passwordnew2}
                                                        required />
                                                    <span className="input-group-text"><i toggle="input[name='passwordnew2']" className="fa fa-fw fa-eye-slash toggle-password" /></span>
                                                </div>                                                
                                                <div className="invalid-feedback" style={{ display: 'block' }}>{registerMsgPwd2}</div>
                                            </div>
                                        </div>
                                        <div className="text-start mt-4">
                                            <button type="button" className="btn btn-primary btn-slim rounded-pill text-decoration-none" disabled={submittingProfile} onClick={this.handleSubmitPwd}>
                                                {submittingProfile ? 'ตกลง...' : 'ตกลง'}
                                            </button>
                                        </div>
                                        <div className={`text-start mt-1 ${statusClass}`}>
                                            {statusText}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>

            )}

            <ThNavFooter />
      </>
    );
  }
}
