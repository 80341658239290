import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { EnNavHeader } from './EnNavHeader';
import { EnNavFooter } from './EnNavFooter';

import { getClientId } from './utils/analyticsclientIdUtils';

export class EnFaq extends Component {
    static displayName = EnFaq.name;

    componentDidMount() {
        window.scrollTo(0, 0);

        {
            // Google analytic
            const documentPath = "faq"; // Gets the current URL path
            const clientId = getClientId(); // Use the getClientId function

            // Push data to the GTM dataLayer
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'pageview',
                page: documentPath,
                clientId: clientId,
            });
        }
    }

    render() {

    return (
        <>
            <EnNavHeader />

            <main id="main">
                <section className="section-dashboard overflow-hidden pb-5">
                    <div className="container mt-4">
                        <div className="row mb-5 px-2">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-5"><h2 className="h4 fw-semibold my-4">Frequently Asked Questions</h2></div>
                                    <div className="col text-center text-md-end py-3">
                                        <Link to="/termsofuse" className="btn btn-outline-green rounded-pill px-3 me-2">Term of use</Link>
                                        <Link to="/terms" className="btn btn-outline-green rounded-pill px-3">Term and Conditions</Link>
                                    </div>
                                </div>
                                <div className="row mt-3 mb-4">
                                    <div className="col-md-12 content-term">
                                        <h4>1. What are my benefits as a  Starbucks® Rewards member?</h4>
                                        <p>								  Upon joining, you will receive the  following benefits:</p>
                                        <ul>
                                            <li>Pay with your phone </li>
                                            <li>Balance protection for your registered Starbucks  Card if it is lost or stolen.</li>
                                        </ul>
                                        <p>Green Level:<br />
                                            The benefits for Green Level may include:&nbsp;&nbsp;&nbsp;&nbsp; </p>
                                        <ul>
                                            <li>20 Stars Get (1) Upsize free: Excluding drinks made from all kinds  of Starbucks Reserve™, juices, ready-to drink bottled beverages, drinks that are only available in one size, all types of ice cream drinks, and all types of alcoholic beverages. Available Starbucks stores, Drive Thru service, and purchases through Mobile Order-ahead &amp; Pick Up  at Store, Mobile Order to Table and Starbucks Delivery  services that are available on Starbucks Thailand mobile  app, except for orders through foodpanda, Grab, LINE MAN, Robinhood, ShopeeFood and other food delivery platforms. Cannot be used in conjunction with other promotions.<br />
                                                <br />
                                            </li>
                                            <li>60 Stars Get 25% off on a regular-priced  handcrafted: one glass of any drink (except for ready-to  drink bottled beverages that are not Starbucks brand, drinks made from super premium reserved coffee such as Jamaica Coffee and  Blue Mountain Coffee, all types of ice cream drinks, Coffee Press 8-Cup drink,  Flights drink, and all types of alcoholic beverages) or one piece of any type of food (except  for mooncake and pound cake). Applicable for Gold  &amp; Green members. Available at Starbucks stores, Drive  Thru service, and purchases through Mobile Order-ahead &amp; Pick Up at Store, Mobile Order to  Table and Starbucks Delivery services that are available  on Starbucks Thailand mobile app, except for orders through foodpanda,  Grab, LINE MAN, Robinhood, ShopeeFood and other food delivery platforms. Cannot be used in conjunction with other promotions. </li>
                                        </ul>
                                        <p>Gold Level:<br />
                                            The benefits for Gold Level will include:</p>
                                        <ul>
                                            <li>20 Stars Get (1) free Upsize(12oz./16oz.) of  your favorite drink:&nbsp; Excluding drinks made from all kinds  of Starbucks Reserve™, juices, ready-to drink bottled beverages, drinks that are only available in one size, all types of ice cream drinks, and all types of alcoholic beverages. Available Starbucks stores, Drive Thru service, and purchases through Mobile Order-ahead &amp; Pick Up  at Store, Mobile Order to Table and Starbucks Delivery  services that are available on Starbucks Thailand mobile  app, except for orders through foodpanda, Grab, LINE MAN, Robinhood, ShopeeFood and other food delivery platforms. Cannot be used in conjunction with other promotions.<br />
                                                <br />
                                            </li>
                                            <li>60 Stars Get 25% off on a regular-priced handcrafted drink or food       item: Applicable for Gold &amp; Green members. This special privilege is       for 1 beverage or 1 food item. Available at Starbucks stores,       Drive Thru service, and purchases through Mobile Order-ahead &amp; Pick Up at Store, Mobile Order to       Table and Starbucks Delivery services that are       available on Starbucks Thailand mobile app, except for orders through foodpanda, Grab, LINE MAN, Robinhood, ShopeeFood and other food delivery platforms. This Benefit does not include ready-to drink       bottled beverages that are not Starbucks brand, drinks       made from super premium reserved coffee such as Jamaica Coffee and Blue Mountain Coffee, all types of ice cream drinks,       Coffee Press 8-Cup drink, Flights drink, and all types of alcoholic beverages, Mooncakes,       and pound cakes. Cannot be used in conjunction with other promotions.                              <br />
                                                <br />
                                            </li>
                                            <li>120 Stars Get a free favorite handcrafted beverage: A free Starbucks       handcrafted beverage &nbsp;( any size )       with 2 customizations. A handcrafted beverage except drink /       bottled beverages that are not Starbucks brand, drinks made from super premium reserved coffee such as Jamaica Coffee and Blue       Mountain Coffee, all types of ice cream drinks, Coffee Press 8-Cup drink, Flights drink, and all types of alcoholic beverages / free       2 customizations except Reserve espresso shot. Applicable for Gold       members.&nbsp; Available at Starbucks stores, Drive Thru service, and purchases through Mobile Order-ahead &amp; Pick       Up at Store, Mobile Order to Table and Starbucks       Delivery services that are available on Starbucks       Thailand mobile app, except for orders through foodpanda, Grab, LINE MAN,       Robinhood, ShopeeFood and other food delivery platforms. Cannot be used in       conjunction with other promotions. <br />
                                                <br />
                                            </li>
                                            <li>160 Stars Get 200 Baht discount with minimum purchase of 500 Baht/receipt: Applicable for Gold members. Limit to 1       redemptions/receipt. This benefit can be used with all types of Starbucks       products having value of THB 500 or more per one receipt, except for all types of alcoholic beverages. Available at       Starbucks stores, Drive Thru service, and purchases through Mobile       Order-ahead &amp; Pick Up at Store, Mobile Order to Table and Starbucks Delivery services that are available on Starbucks Thailand mobile app, except for orders through foodpanda,       Grab, LINE MAN, Robinhood, ShopeeFood and other food delivery platforms.       Cannot be used in conjunction with 350 Star-based benefit on the same receipt and cannot be used in conjunction with other promotions.                              <br />
                                                <br />
                                            </li>
                                            <li>350 Stars Get 4 favorite handcrafted beverages for free/receipt: 350       Stars Get 4 favorite handcrafted beverages for free/ receipt:       Applicable for Gold members. 4 handcrafted beverages can be customized       with free 2 customizations per drink (except for       drink / bottled beverages that are not Starbucks brand, drinks       made from super premium reserved coffee such as Jamaica Coffee and Blue Mountain Coffee, all types of ice cream drinks,       Coffee Press 8-Cup drink, Flights drink, and all types of alcoholic beverages / free 2 customizations except Reserve espresso shot). Available at Starbucks stores,       Drive Thru service, and purchases through Mobile Order-ahead &amp; Pick Up at Store, Mobile Order to       Table and Starbucks Delivery services that are       available on Starbucks Thailand mobile app, except for orders through foodpanda, Grab, LINE MAN, Robinhood, ShopeeFood and other food delivery platforms. Cannot be used in conjunction with 160       Star-based benefit to redeem THB 200 discount or 350 Star-based benefit to redeem 50% discount on the same receipt and cannot be used in conjunction with other promotions.                              <br />
                                                <br />
                                            </li>
                                            <li>350 Stars Get 50% off up to 600 Baht/receipt: with no minimum       spending. Limit to 1 redemptions/receipt. Excluding all types of alcoholic beverages.       Available at Starbucks stores, Drive Thru service,       and purchases through Mobile Order-ahead &amp; Pick Up at Store, Mobile Order to       Table and Starbucks Delivery services that are       available on Starbucks Thailand mobile app, except for orders through foodpanda, Grab, LINE MAN, Robinhood, ShopeeFood and other food delivery platforms. Cannot be used in conjunction       with 160 Star-based benefit to redeem THB 200 discount or 350 Star-based benefit to redeem 4 free drinks       on the same receipt.       Cannot be used in conjunction with other promotions. <br />
                                                <br />
                                            </li>
                                            <li>A Birthday Reward just for you. A free Starbucks handcrafted beverage       in any size with 2 free customizations (except for drink / bottled       beverages that are not Starbucks brand, Flights drink, and all types of       alcoholic beverages) and a free food (maximum 150 Baht).</li>
                                            <li>New Year’s Gift<br />
                                            </li>
                                            <li>Digital Gold Card</li>
                                            <li>See more info (ไปที่หน้า <Link to="/termsofuse">TermsOfUse</Link> )</li>
                                        </ul>
                                        <h4>2. What is a Star and how can I  earn one?</h4>
                                        <p>                                You can earn Stars and receive  in-store benefits simply by paying for a transaction at or over THB 25 with  your Starbucks® TH app or your registered Starbucks Card at participating  Starbucks® stores in Thailand. On every purchase with Starbucks® TH app  or your registered Starbucks Card, you earn one Star for every THB 25. Your  change counts towards partial Stars. We’ll keep track of all your partial Stars  and apply them to your total Stars. For example, if you spend 155, you’ll earn  5.80 Stars. The 0.80 is not always visible (we like to keep things simple), but  you can always view your Stars and purchase history when you sign in here.</p>
                                        <h4>3. Can I earn Stars if I pay with  cash or a credit or debit card instead of my registered Starbucks Card?</h4>
                                        <p>                              To earn Stars on your purchases at  participating Starbucks® stores in Thailand, you must pay with your Starbucks®  TH app or your registered Starbucks Card - we can't award you Stars and reward  you for purchases made with another form of payment. You can use your debit or  credit card to reload your Starbucks Card.</p>
                                        <h4>4. How can I check my Stars and my  rewards?</h4>
                                        <p>                              You can check your account,  transaction, Stars and rewards on your Starbucks® TH app or at starbucks.co.th.                            </p>
                                        <h4>5. Do my Stars expire?</h4>
                                        <p>                                Yes. You have 12 months to  accumulate Star Balance to reach Gold Level or maintain your Gold level. After  registering, you need to accumulate 300 Stars in 12 months to reach Gold level.  Once you are Gold, you must re-qualify each subsequent 12 months by earning at  least 300 Stars and your Star count will be reset to zero on your anniversary  date (12 months from the date you reach the Gold level). When you are in Gold  level and you earn less than 300 Stars in 12 months, you will return to the  Green level and your Star count will be reset to zero on your anniversary date.<br />
                                            For Green Level / Gold Level  membership, the Stars you collect towards Reward Balance will expire 6 months  after the month you purchased. Starbucks will always apply the oldest Stars  towards your redeem. For example, if you earn 10 Stars on June 16 and 15 Stars  on June 24, your total Star balance for the month — 25 Stars — will expire on  December 31.</p>
                                        <h4>6. What can I redeem my Reward  Balance for?</h4>
                                        <ul type="disc">
                                            <li>20 Stars Get (1) free Upsize: Excluding drinks made from all kinds of Starbucks Reserve™,       juices, ready-to drink bottled beverages, drinks that       are only available in one size, all types of ice cream drinks, and all types of alcoholic beverages. Available Starbucks stores,       Drive Thru service, and purchases through Mobile Order-ahead &amp; Pick Up at Store, Mobile Order to       Table and Starbucks Delivery services that are       available on Starbucks Thailand mobile app, except for orders through foodpanda, Grab, LINE MAN, Robinhood, ShopeeFood and other food delivery platforms. Cannot be used in       conjunction with other promotions.<br />
                                                <br />
                                            </li>
                                            <li>60 Stars Get 25% off on a regular-priced handcrafted drink or food       item: Applicable for Gold &amp; Green members. This special privilege is       for 1 beverage or 1 food item. . This Benefit does not include ready-to       drink bottled beverages that are not Starbucks brand, drinks       made from super premium reserved coffee such as Jamaica Coffee and Blue Mountain Coffee, all types of ice cream drinks,       Coffee Press 8-Cup drink, Flights drink, and all types of alcoholic beverages, Mooncakes, and pound cakes. Available at       Starbucks stores, Drive Thru service, and purchases through Mobile       Order-ahead &amp; Pick Up at Store, Mobile Order to Table and Starbucks Delivery services that are available on Starbucks Thailand mobile app, except for orders through foodpanda,       Grab, LINE MAN, Robinhood, ShopeeFood and other food delivery platforms.       Cannot be used in conjunction with other promotions.                              <br />
                                                <br />
                                            </li>
                                            <li>120 Stars Get a free favorite handcrafted drink: Applicable for Gold       members. A free Starbucks handcrafted beverage (any size) with 2       customizations (except drink / bottled beverages that are not Starbucks brand, drinks       made from super premium reserved coffee       such as Jamaica Coffee and Blue Mountain Coffee, all types of ice cream drinks,       Coffee Press 8-Cup drink, Flights drink, and all types of alcoholic beverages / free 2 customizations except Reserve espresso shot.       Applicable for Gold members).&nbsp;       Available at Starbucks stores, Drive Thru       service, and purchases through Mobile Order-ahead &amp; Pick Up at Store, Mobile Order to       Table and Starbucks Delivery services that are       available on Starbucks Thailand mobile app, except for orders through foodpanda, Grab, LINE MAN, Robinhood, ShopeeFood and other food delivery platforms. Cannot be used in conjunction with other       promotions.                              <br />
                                                <br />
                                            </li>
                                            <li>160 Stars Get 200 Baht       discount with minimum purchase of 500 Baht/bill: Applicable for Gold       members. Limit to 1 redemptions/receipt. This benefit can be used with all       types of Starbucks products having value of THB 500 or more per one receipt,       except for all types of alcoholic       beverages. Available at Starbucks stores, Drive Thru       service, and purchases through Mobile Order-ahead &amp; Pick Up at Store, Mobile Order to       Table and Starbucks Delivery services that are       available on Starbucks Thailand mobile app, except for orders through foodpanda, Grab, LINE MAN, Robinhood, ShopeeFood and other food delivery platforms. Cannot be used in conjunction with 350 Star-based benefit on the same receipt and cannot be used in conjunction with other promotions. <br />
                                                <br />
                                            </li>
                                            <li>350 Stars Get 4       favorite handcrafted drinks for free/bill: Applicable for Gold members. 4       handcrafted beverages can be customized with free 2 customizations per       drink (except for drink / bottled beverages that are not Starbucks brand, drinks       made from super premium reserved coffee       such as Jamaica Coffee and Blue Mountain Coffee, all types of ice cream drinks,       Coffee Press 8-Cup drink, Flights drink, and all types of alcoholic beverages / free 2 customizations except Reserve espresso shot). Available at Starbucks stores, Drive Thru service, and purchases through Mobile       Order-ahead &amp; Pick Up at Store, Mobile Order to Table and Starbucks Delivery services that are available on Starbucks Thailand mobile app, except for orders through foodpanda,       Grab, LINE MAN, Robinhood, ShopeeFood and other food delivery platforms.       Cannot be used in conjunction with 160 Star-based benefit to redeem THB 200 discount or       350 Star-based benefit to redeem       50% discount on the same receipt and cannot be used in conjunction with other promotions. <br />
                                                <br />
                                            </li>
                                            <li>350 Stars Get 50% off up to 600 Baht/bill: with no minimum spending.       Limit to 1 redemptions/receipt. Excluding all types of alcoholic beverages.       Available at Starbucks stores, Drive Thru service,       and purchases through Mobile Order-ahead &amp; Pick Up at Store, Mobile Order to       Table and Starbucks Delivery services that are       available on Starbucks Thailand mobile app, except for orders through foodpanda, Grab, LINE MAN, Robinhood, ShopeeFood and other food delivery platforms. Cannot be used in conjunction       with 160 Star-based benefit to       redeem THB 200 discount or 350 Star-based benefit to redeem 4 free drinks on the same receipt. Cannot be used in conjunction with other promotions.</li>
                                        </ul>
                                        <p>See Terms of Use  for full details on tier eligibility and exclusions.</p>
                                        <h4>7. Can I redeem my Stars for  Rewards in other countries?</h4>
                                        <p>                                Your Stars can only be redeemed for  reward in participating Starbucks stores in Thailand.</p>
                                        <h4>8. Can I redeem more than 1 benefit  in the same bill?</h4>
                                        <p>                              Yes, except for the benefits that  discount all bills can use only 1 benefit/bill.</p>
                                        <h4>9. Can Green level redeem benefits  of Gold level?</h4>
                                        <p>                              No. Green level needs to accumulate  on three hundred (300) Stars in each subsequent 12 months from the date you  open the account in order to achieve the Gold Level and can redeem benefits of  Gold level.</p>
                                        <h4>10.Where can I redeem my benefits?</h4>
                                        <p>                                Available at all Starbucks stores,  Drive Thru, Mobile Order-ahead &amp; Pick Up at Store, Mobile Order to Table,  Starbucks Delivery services at Starbucks® TH app. Except Foodpanda, Grab, LINE  MAN, Robinhood and ShopeeFood.</p>
                                        <h4>11. What if my Star balance doesn’t  look right? Who do I contact?</h4>
                                        <p>                                We want to make sure the balance on  your account reflects your purchases accurately. If the total number of Stars  doesn’t appear to be correct, call 02-339-0996 (Monday-Sunday 9:00-18:00).</p>
                                        <h4>12. Can I track my progress in the  program and see how many Stars I have until I reach the next level?</h4>
                                        <p>                              Yes. Starbucks® TH app or at  starbucks.co.th you will be able to log into your account and see how many  Stars you've earned, your current benefits, how many Stars you have to go until  you reach the next level, your transaction history and all available rewards on  your account.</p>
                                        <h4>13. Can I get any drink and cake  item I want when I redeem my Birthday reward? What’s included?</h4>
                                        <p>                              Present your registered Starbucks  Card or Starbucks® TH app and redeem your free birthday reward at participating  Starbucks® stores in Thailand before the reward expires. The birthday reward is  typically loaded on the first day of your birthday month and expires in 30  days. Your birthday reward includes (i) one Starbucks handcrafted drink - any  size (with 2 free customizations) and (ii) Food of your choice (with maximum  150 Baht).</p>
                                        <h4>14. If I have multiple Starbucks  Cards registered to my account, does that mean I will receive a birthday reward  for each one?</h4>
                                        <p>                              No. You will only receive one  birthday reward per account.</p>
                                        <h4>15. Is there a membership fee to  join Starbucks® Rewards?</h4>
                                        <p>                                No. There is no membership fee at  any level of the program.</p>
                                        <h4>                                16. How soon will I be able to see  the Stars I’ve earned after using my registered Starbucks Card?</h4>
                                        <p>                              Stars earned from using your  registered Starbucks Card or Starbucks® TH app are typically available on your  account within 30 minutes.</p>
                                        <h4>17. I reported my registered Gold  Card as lost or stolen. How long will it take to arrive?</h4>
                                        <p>                              If you reported your registered  physical Gold Card as lost or stolen online or in your Mobile app, your  replacement digital Gold Card will appear in your Mobile app and website within  24 hrs. Note: Physical Gold cards will no longer be sent out to the members of  Starbucks Rewards.</p>
                                        <h4>18. Can the Anniversary date be  changed?</h4>
                                        <p>                              No. The Anniversary date (12-month  period) cannot be changed unless your level changes. A new Anniversary date  will apply when you move to the new level.</p>
                                        <h4>19. Why is registration necessary  in order to join the program?</h4>
                                        <p>                              When you create a Starbucks®  Rewards account, you provide information so we can reward you with benefits  like a free drink and food on your birthday, as well as special offers.  Additionally, after you register a Starbucks Rewards account, you can check your  balance when you pay with your registered Starbucks Cards. Also, with a  registered card, you can manage your account in a variety of ways online.</p>
                                        <h4>&nbsp;20. Do I have to use valid email to register a  Starbucks Card?</h4>
                                        <p>                              Yes.&nbsp; You need to use valid email in order to  receive verification email to complete your registration.&nbsp; Also we will send all information via your  email address.</p>
                                        <h4>21. How many Starbucks Cards can I  register as part of the program?</h4>
                                        <p>                              You can register an unlimited  number of cards to your Starbucks Card account on your Starbucks® TH app or at  starbucks.co.th. However, you may have only one account.</p>
                                        <h4>22. How can I check my Starbucks  Card balance and transaction?</h4>
                                        <p>                              You can also check your balance and  transaction by logging into your account on Starbucks® TH app or at  starbucks.co.th.</p>
                                        <h4>23. How does Starbucks manage  personal information?</h4>
                                        <p>                              Please read our Privacy Policy for  details.</p>
                                        <h4>24. Can I transfer my Starbucks®  Rewards membership to someone else?</h4>
                                        <p>                              No. Sorry, memberships and rewards  are non-transferable and cannot be shared with family members, friends and  others.</p>
                                        <h4>25. Can I use my Starbucks Card and  redeem my reward in other countries?</h4>
                                        <p>                              No. Starbucks Cards issued and  activated in Thailand may not be used and redeem any reward in other countries,  and Starbucks Cards issued and activated in other countries may not be used in  Thailand.</p>
                                        <h4>26. Why are rewards and Stars in  Thailand different from other countries?</h4>
                                        <p>                              Stars and rewards from Starbucks®  Rewards are different in each country. To participate in Starbucks® Rewards  program in Thailand simply register your Starbucks Card to your account at  starbucks.co.th.</p>
                                        <h4>27. How can I transfer funds  between cards in the same account?</h4>
                                        <p>                              You can transfer funds between  cards within the same account only through Starbucks® TH app or our website  starbucks.co.th.</p>
                                        <h4>28. Can I use multiple Starbucks  Cards for one transaction and to earn Stars?</h4>
                                        <p>                              Yes, for a single transaction,  payment can be made with multiple Starbucks Cards. Only registered Starbucks  Cards will earn Stars.</p>
                                        <h4>29. Can I receive a refund from my  Starbucks Cards?</h4>
                                        <p>                              Any amount/value stored in your  card cannot be refunded.</p>
                                        <h4>30. Is there an expiration date on  my Card?</h4>
                                        <p>                                No. There’s no expiration on the  Starbucks Card.</p>
                                        <h4>31. What if I lose my Starbucks  Card?</h4>
                                        <p>                                Sorry to hear that you’ve lost your  card, we are here to help, don’t worry. Only freeze your Starbucks Card through  Starbucks® TH app or our website starbucks.co.th.&nbsp; But first you need to transfer the remaining  balance to one of your other registered cards in your account before reporting  your lost card.</p>
                                        <h4>32. Can I cancel my redeem?</h4>
                                        <p>                              Yes, but only before the payment is  successful. If the payment is successful, you cannot cancel your redeem.</p>
                                        <h4>33. Can Green level use Stars that  were received before the new Starbucks® Rewards Program launch on August 29,  2024, to redeem new Rewards?</h4>
                                        <p>                              No, currently the Stars collected  by Green level are only for upgrading their level and cannot be used to redeem  Rewards. However, after August 29, 2024, all spending will accumulate Stars  that can be redeemed for new Rewards.</p>
                                        <h4>34. Under the new Starbucks®  Rewards Program launching on August 29, 2024, if a Gold level anniversary is on  August 28, 2024, and they have collected fewer than 300 Stars, they will be  downgraded to Green level. What will happen to the remaining Stars?                            </h4>
                                        <ul>
                                            <li>For Gold level who are downgraded on August 28,  2024, if they have collected Reward balance more than 120 Stars, they will  receive an adjusted reward of a free Starbucks handcrafted drink. Star balance  and Reward balance will be reduced to 0, and they will start collecting Stars  anew.</li>
                                            <li>For Gold level who are downgraded on August 28,  2024, if they have collected Reward balance less than 120 Stars, Star balance  and Reward balance will be reduced to 0, and they will start collecting Stars  anew.</li>
                                        </ul>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </section>
            </main>            

            <EnNavFooter />
      </>
    );
  }
}
