import React, { Component } from 'react';

export class EnSharedComponentBenefit01 extends Component {

    render() {        
        return (
            <>
                <section className="section-benefits pb-5">
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-md-12 mb-5 pt-3 text-center">
                                <h2 className="h3 mt-5 fw-bold text-dark-212121">More benefits right from the start</h2>
                            </div>
                        </div>
                        <nav>
                            <div className="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
                                <button className="nav-link active" id="nav-20-tab" data-bs-toggle="tab" data-bs-target="#nav-20" type="button" role="tab" aria-controls="nav-20" aria-selected="true">
                                    20 <img src={require("./media/rewards/star-green.png")} alt="Green" className="img-fluid star-green" /><span className="tabs-border-slider" /></button>
                                <button className="nav-link" id="nav-60-tab" data-bs-toggle="tab" data-bs-target="#nav-60" type="button" role="tab" aria-controls="nav-60" aria-selected="false">
                                    60 <img src={require("./media/rewards/star-green.png")} alt="Green" className="img-fluid star-green" /><span className="tabs-border-slider" /></button>
                                <button className="nav-link" id="nav-120-tab" data-bs-toggle="tab" data-bs-target="#nav-120" type="button" role="tab" aria-controls="nav-120" aria-selected="false">
                                    120 <img src={require("./media/rewards/star-gold.png")} alt="Gold" className="img-fluid star-gold" /><span className="tabs-border-slider" /></button>
                                <button className="nav-link" id="nav-160-tab" data-bs-toggle="tab" data-bs-target="#nav-160" type="button" role="tab" aria-controls="nav-160" aria-selected="false">
                                    160 <img src={require("./media/rewards/star-gold.png")} alt="Gold" className="img-fluid star-gold" /><span className="tabs-border-slider" /></button>
                                <button className="nav-link" id="nav-350-tab" data-bs-toggle="tab" data-bs-target="#nav-350" type="button" role="tab" aria-controls="nav-350" aria-selected="false">
                                    350 <img src={require("./media/rewards/star-gold.png")} alt="Gold" className="img-fluid star-gold" /><span className="tabs-border-slider" /></button>
                            </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-20" role="tabpanel" aria-labelledby="nav-20-tab" tabIndex={0}>
                                <div className="container pt-5 pb-4">
                                    <div className="row m-0 gx-5 text-dark-000000">
                                        <div className="col-sm-12 col-md-6 px-4 px-md-0 text-center">
                                            <div className="mb-3"><img src={require("./media/rewards/img-box2-1.png")} alt="Benefits 20" className="img-fluid" /></div>
                                            <div className="my-4"><img src={require("./media/rewards/badge-green-gold.png")} alt="Green Gold" className="img-fluid img-benefits-green-gold" /></div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 px-4 px-md-0">
                                            <h4 className="fs-5 fw-bold mt-4 mb-3">Get a free upsize on your favorite drink<br />
                                                ( Tall/Grande size )</h4>
                                            <ul>
                                                <li>Applicable for Gold &amp; Green members.</li>
                                                <li>A handcrafted drink (12oz./16oz.) except all Starbucks Reserve™ beverages, cold-pressed juices, bottled beverages, Ice cream beverages, alcoholic beverages and one-size offered beverage.</li>
                                                <li>Available at all Starbucks stores, Drive Thru, Mobile Order-ahead &amp; Pick Up at Store,
                                                    Mobile Order to Table and Starbucks Delivery services. Except foodpanda, Grab, LINE MAN, Robinhood and ShopeeFood.</li>
                                                <li>Cannot be used in conjunction with other promotions.</li>
                                                <li>T&amp;C apply.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="nav-60" role="tabpanel" aria-labelledby="nav-60-tab" tabIndex={0}>
                                <div className="container pt-5 pb-4">
                                    <div className="row m-0 gx-5 text-dark-000000">
                                        <div className="col-sm-12 col-md-6 px-4 px-md-0 text-center">
                                            <div className="mb-3"><img src={require("./media/rewards/img-box2-2.png")} alt="Benefits 60" className="img-fluid" /></div>
                                            <div className="my-4"><img src={require("./media/rewards/badge-green-gold.png")} alt="Green Gold" className="img-fluid img-benefits-green-gold" /></div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 px-4 px-md-0">
                                            <h4 className="fs-5 fw-bold mt-4 mb-3">Get 25% off on a regular-priced handcrafted <br />
                                                drink or food item</h4>
                                            <ul>
                                                <li>Applicable for Gold &amp; Green members.</li>
                                                <li>This Reward is for 1 drink or 1 food item.</li>
                                                <li>A handcrafted drink (any size) except bottled beverages, beverages made from super premium Reserve™ Coffee such as Jamaica Blue Mountain coffee, ice cream beverages, 8-cup Coffee Press beverages, Flights beverages and alcoholic beverages.</li>
                                                <li>Any food except mooncake and pound cake.</li>
                                                <li>Available at all Starbucks stores, Drive Thru, Mobile Order-ahead &amp; Pick Up at Store, Mobile Order to Table and Starbucks Delivery services. Except foodpanda, Grab, LINE MAN, Robinhood and ShopeeFood.</li>
                                                <li>Cannot be used in conjunction with other promotions.</li>
                                                <li>T&amp;C apply</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="nav-120" role="tabpanel" aria-labelledby="nav-120-tab" tabIndex={0}>
                                <div className="container pt-5 pb-4">
                                    <div className="row m-0 gx-5 text-dark-000000">
                                        <div className="col-sm-12 col-md-6 px-4 px-md-0 text-center">
                                            <div className="mb-3"><img src={require("./media/rewards/img-box2-3.png")} alt="Benefits 120" className="img-fluid" /></div>
                                            <div className="my-4"><img src={require("./media/rewards/badge-gold.png")} alt="Gold" className="img-fluid img-benefits-gold" /></div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 px-4 px-md-0">
                                            <h4 className="fs-5 fw-bold mt-4 mb-3">Get a free favorite handcrafted drink</h4>
                                            <ul>
                                                <li>Applicable for Gold members.</li>
                                                <li>A free Starbucks handcrafted drink ( any size ) with 2 customizations.</li>
                                                <li>A handcrafted drink (any size ) except for bottled beverages, beverages made from super premium Reserve™ Coffee such as Jamaica Blue Mountain coffee, ice cream beverages, 8-cup Coffee Press beverages, Flights beverages and of alcoholic beverages.</li>
                                                <li>Free 2 customizations except Reserve espresso shot.</li>
                                                <li>Available at all Starbucks stores, Drive Thru, Mobile Order-ahead &amp; Pick Up at Store, Mobile Order to Table and Starbucks Delivery services. Except foodpanda, Grab, LINE MAN, Robinhood and ShopeeFood.</li>
                                                <li>Cannot be used in conjunction with other promotions.</li>
                                                <li>T&amp;C apply.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="nav-160" role="tabpanel" aria-labelledby="nav-160-tab" tabIndex={0}>
                                <div className="container pt-5 pb-4">
                                    <div className="row m-0 gx-5 text-dark-000000">
                                        <div className="col-sm-12 col-md-6 px-4 px-md-0 text-center">
                                            <div className="mb-3"><img src={require("./media/rewards/img-box2-4.png")} alt="Benefits 160" className="img-fluid" /></div>
                                            <div className="my-4"><img src={require("./media/rewards/badge-gold.png")} alt="Gold" className="img-fluid img-benefits-gold" /></div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 px-4 px-md-0">
                                            <h4 className="fs-5 fw-bold mt-4 mb-3">Get 200 Baht discount <br />with minimum purchase of 500 Baht/bill</h4>
                                            <ul>
                                                <li>Applicable for Gold members.</li>
                                                <li>Limit to 1 Reward/bill.</li>
                                                <li>This Reward is to redeem 200 THB discount when purchasing any Starbucks products (excluding alcoholic beverages) having value of 500 THB or more per one bill.</li>
                                                <li>Available at all Starbucks stores, Drive Thru, Mobile Order-ahead &amp; Pick Up at Store, Mobile Order to Table and Starbucks Delivery services. Except foodpanda, Grab, LINE MAN, Robinhood and ShopeeFood.</li>
                                                <li>Cannot be used with 350 Stars Reward in the same bill.</li>
                                                <li>Cannot be used in conjunction with other promotions.</li>
                                                <li>T&amp;C apply.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="nav-350" role="tabpanel" aria-labelledby="nav-350-tab" tabIndex={0}>
                                <div className="container pt-5 pb-4">
                                    <div className="row m-0 gx-5 text-dark-000000">
                                        <div className="col-sm-12 col-md-6 px-4 px-md-0 text-center align-self-end">
                                            <div className="mb-3 accordion-image">
                                                <img id="img1" src="media/rewards/img-box2-5.png" alt="Get 4 favorite handcrafted drinks for free/bill" className="img-fluid" />
                                                <img id="img2" src="media/rewards/img-box2-5-2.png" alt="Get 50% off up to 600 Baht/bill" className="img-fluid" />
                                            </div>
                                            <div className="my-4"><img src={require("./media/rewards/badge-gold.png")} alt="Gold" className="img-fluid img-benefits-gold" /></div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 px-4 px-md-0">
                                            <div className="accordion accordion350tab" id="accordion350tab">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" data-image="img1">
                                                            Get 4 favorite handcrafted drinks for free/bill
                                                        </button>
                                                    </h2>
                                                    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordion350tab">
                                                        <div className="accordion-body">
                                                            <ul>
                                                                <li>Applicable for Gold members.</li>
                                                                <li>Redeem 4 free drinks (any size) with free 2 customizations per drink in a same bill.</li>
                                                                <li>A handcrafted drink ( any size ) except for bottled beverages, beverages made from super premium Reserve™ Coffee such as Jamaica Blue Mountain coffee, ice cream beverages, 8-cup Coffee Press beverages, Flights beverages and alcoholic beverages.</li>
                                                                <li>Free 2 customizations except Reserve espresso shot.</li>
                                                                <li>Available at all Starbucks stores, Drive Thru, Mobile Order-ahead &amp; Pick Up at Store, Mobile Order to Table and Starbucks Delivery services. Except foodpanda, Grab, LINE MAN, Robinhood and ShopeeFood.</li>
                                                                <li>Cannot be redeemed with 160 Stars and 350 Stars (for a 50% off) in the same bill.</li>
                                                                <li>Cannot be used in conjunction with other promotions.</li>
                                                                <li>T&amp;C apply.</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" data-image="img2">
                                                            Get 50% off up to 600 Baht/bill
                                                        </button>
                                                    </h2>
                                                    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordion350tab">
                                                        <div className="accordion-body">
                                                            <ul>
                                                                <li>Applicable for Gold members.</li>
                                                                <li>Limit to 1 Reward/bill.</li>
                                                                <li>Redeem 50% discount (up to 600 THB discount) per bill excluding alcoholic beverages.</li>
                                                                <li>Available at all Starbucks stores, Drive Thru, Mobile Order-ahead
                                                                    &amp; Pick Up at Store, Mobile Order to Table and Starbucks Delivery services. Except foodpanda, Grab, LINE MAN, Robinhood and ShopeeFood.</li>
                                                                <li>Cannot be redeemed with 160 Stars or 350 Stars (4 free drinks) on the same bill.</li>
                                                                <li>Cannot be used in conjunction with other promotions.</li>
                                                                <li>T&amp;C apply.</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}
