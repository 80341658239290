import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { ThNavHeader } from './ThNavHeader';
import { ThNavFooter } from './ThNavFooter';

import { getClientId } from './utils/analyticsclientIdUtils';
import { encryptData } from './utils/encryptionUtils';
import { API_URL, RECAPTCHAID } from './utils/constants';

class ThAuthJoinClass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            submitting: false, // Add a state variable to track form submission status
            submittingMsg: null
        };
    }

    componentDidMount() {
        // Load reCAPTCHA script
        const script = document.createElement("script");
        script.src = "https://www.google.com/recaptcha/api.js?render=" + RECAPTCHAID;
        script.async = true;
        document.body.appendChild(script);

        {
            // Google analytic
            const documentPath = "TH Join"; // Gets the current URL path
            const clientId = getClientId(); // Use the getClientId function

            // Push data to the GTM dataLayer
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'pageview',
                page: documentPath,
                clientId: clientId,
            });
        }
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    //handleSubmit = async e => {
    //    e.preventDefault();
    //    const { username } = this.state;

    //    let isthisvalid = "yes";

    //    const validateEmail = (email) => {
    //        return email.match(
    //            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    //        );
    //    };

    //    if (!username) {
    //        isthisvalid = "no";
    //        this.setState({ submittingMsg: 'กรุณากรอกอีเมล' });
    //        return;
    //    } else if (!validateEmail(username)) {
    //        isthisvalid = "no";
    //        this.setState({ submittingMsg: 'กรุณากรอกอีเมลที่ถูกต้อง' });
    //        return;
    //    }

    //    //if (isthisvalid === "no") {
    //    //    this.setState({ submittingMsg: 'Please enter valid Email' });
    //    //    return;
    //    //}

    //    // Generate Unix timestamp
    //    const timestamp = Math.floor(Date.now() / 1000);

    //    // Prepare and encrypt the payload
    //    const payload = { Email: username, RefLang: 'th', Timestamp: timestamp };
    //    const encryptedPayload = encryptData(payload);

    //    // Prevent double-clicking by disabling the submit button and changing cursor to 'wait'
    //    this.setState({ submitting: true });

    //    // Add the wait cursor class to the body element
    //    document.body.classList.add('wait-cursor');

    //    const requestOptions = {
    //        method: 'POST',
    //        headers: { 'Content-Type': 'application/json' },
    //        body: JSON.stringify({ Data: encryptedPayload })
    //    };

    //    try {
    //        const response = await fetch(`${API_URL}/api/CrmRegisterStep01`, requestOptions);
    //        const data = await response.json();
    //        document.body.classList.remove('wait-cursor'); // Remove the wait cursor class

    //        if (data.responseCode === "OK") {
    //            this.setState({ submittingMsg: null });

    //            const dataToSend = { responseData: data};
    //            this.props.history.replace('/thauthjoinnowthx', dataToSend);
    //        }
    //        else {
    //            this.setState({ submittingMsg: data.responseMessage });
    //        }
    //    } catch(error) {
    //        console.error('Error during register:', error);
    //        // Handle error appropriately, e.g., show an error message to the user

    //        // Enable the submit button and change cursor back to 'auto' after form submission completes
    //        this.setState({ submitting: false });
    //        document.body.classList.remove('wait-cursor'); // Remove the wait cursor class
    //    } finally {

    //    }
    //};

    handleSubmit = async e => {
        e.preventDefault();
        const { username } = this.state;

        const validateEmail = (email) => {
            return email.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        };

        if (!username) {
            this.setState({ submittingMsg: 'กรุณากรอกอีเมล' });
            return;
        } else if (!validateEmail(username)) {
            this.setState({ submittingMsg: 'กรุณากรอกอีเมลที่ถูกต้อง' });
            return;
        }

        //if (isthisvalid === "no") {
        //    this.setState({ submittingMsg: 'Please enter valid Email' });
        //    return;
        //}

        // Generate reCAPTCHA token
        window.grecaptcha.ready(async () => {
            try {
                const token = await window.grecaptcha.execute(RECAPTCHAID, { action: 'register' });

                // Generate Unix timestamp
                const timestamp = Math.floor(Date.now() / 1000);

                // Prepare and encrypt the payload
                const payload = { Email: username, RefLang: 'th', Timestamp: timestamp };
                const encryptedPayload = encryptData(payload);

                // Prevent double-clicking by disabling the submit button and changing cursor to 'wait'
                this.setState({ submitting: true });

                // Add the wait cursor class to the body element
                document.body.classList.add('wait-cursor');

                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ Data: encryptedPayload, Token: token })
                };

                const response = await fetch(`${API_URL}/api/CrmRegisterStep01`, requestOptions);
                const data = await response.json();
                document.body.classList.remove('wait-cursor'); // Remove the wait cursor class

                if (data.responseCode === "OK") {
                    this.setState({ submittingMsg: null });

                    const dataToSend = { responseData: data };
                    this.props.history.replace('/thauthjoinnowthx', dataToSend);
                }
                else {
                    let t01 = data.responseMessage.replace("not free email", "ข้อมูลไม่ถูกต้อง");

                    this.setState({ submittingMsg: t01 });
                    // Enable the submit button and change cursor back to 'auto' after form submission completes
                    this.setState({ submitting: false });
                }
            } catch (error) {
                console.error('Error during register:', error);
                // Handle error appropriately, e.g., show an error message to the user
            } finally {

            }
        });
    };

    render() {
        const { username, submitting, submittingMsg } = this.state;

        return (
            <>
                <ThNavHeader />

                <main id="main">
                    <section className="top-sr-bg bg-gray1">
                        <div className="container">
                            <div className="row justify-content-between">
                                <div className="col col-md-6">
                                    <h1 className="text-headpage text-white mb-0 pt-3 pb-3 "><span className="text-black">สตาร์บัคส์ รีวอร์ด</span></h1>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section-sign-in overflow-hidden pb-5">
                        <div className="container p-1 p-md-0">
                            <div className="row">
                                <div className="col-md-12 mb-3 pt-3 text-center">
                                    <h2 className="h3 mt-5 fw-bold text-dark-212121">ลงทะเบียน</h2>
                                    <h5 className="mt-3 text-gray">สตาร์บัคส์ รีวอร์ด</h5>
                                    <p className="mt-3 text-gray">ร่วมเป็นสมาชิก สตาร์บัคส์ รีวอร์ด เพื่อรับสิทธิประโยชน์สำหรับสมาชิกโดยเฉพาะ เพิ่มความสะดวกในการใช้จ่ายผ่าน Starbucks&reg; TH app และอื่นๆอีกมากมาย</p>
                                </div>
                            </div>
                            <div className="row justify-content-center px-1 px-md-5">
                                <div className="col-md-6 mb-3 pt-3">
                                    <div className="p-5 box-sign-in">
                                        <form className="px-1 px-md-4" onSubmit={this.handleSubmit}>
                                            <p><span className="text-alert">*</span> <span className="text-gray">ลิงก์ยืนยันจะถูกส่งไปยังอีเมลนี้</span></p>
                                            <div className="mb-5">
                                                <label htmlFor="username" className="form-label d-none">อีเมล</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="อีเมล"
                                                    name="username"
                                                    value={username}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                            <div className="text-center">
                                                <button type="submit" className="btn btn-primary rounded-pill px-4 text-decoration-none" disabled={submitting}>
                                                    {submitting ? 'ยืนยัน...' : 'ยืนยัน'}
                                                </button>
                                            </div>
                                            <div className="text-center text-alert-2">
                                                {submittingMsg}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
               
                <ThNavFooter />
            </>
        );
    }
}

export const ThAuthJoin = withRouter(ThAuthJoinClass);