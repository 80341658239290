import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { getClientId } from './utils/analyticsclientIdUtils';
import { encryptData } from './utils/encryptionUtils';
import { API_URL } from './utils/constants';

export class UtilDeleteSigninTh extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            loginstatus: '',
            requireMsgEmail: '',
            requireMsgPwd: '',
            submitting: false // Add a state variable to track form submission status
        };
    }

    componentDidMount() {
        // Use jQuery to toggle password visibility
        window.$(".toggle-password").click(function () {
            window.$(this).toggleClass("fa-eye fa-eye-slash");
            var input = window.$(window.$(this).attr("toggle"));
            if (input.attr("type") === "password") {
                input.attr("type", "text");
            } else {
                input.attr("type", "password");
            }
        });

        {
            // Google analytic
            const documentPath = "DeleteAccount-Signin"; // Gets the current URL path
            const clientId = getClientId(); // Use the getClientId function

            // Push data to the GTM dataLayer
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'pageview',
                page: documentPath,
                clientId: clientId,
            });
        }

    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleSubmit = async e => {
        e.preventDefault();
        const { username, password } = this.state;

        let isthisvalid = "yes";

        const validateEmail = (email) => {
            return email.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        };

        if (!username) {
            isthisvalid = "no";
            this.setState({ requireMsgEmail: 'กรุณากรอกอีเมล' });
            return;
        } else if (!validateEmail(username)) {
            isthisvalid = "no";
            this.setState({ requireMsgEmail: 'กรุณากรอกอีเมลที่ถูกต้อง' });
            return;
        }

        if (!password) {
            isthisvalid = "no";
            this.setState({ requireMsgPwd: 'กรุณากรอกรหัสผ่าน' });
            return;
        }

        // Generate Unix timestamp
        const timestamp = Math.floor(Date.now() / 1000);

        // Prepare and encrypt the payload
        const payload = { Email: username, Password: password, RefLang: 'th', Timestamp: timestamp };
        const encryptedPayload = encryptData(payload);

        // Prevent double-clicking by disabling the submit button and changing cursor to 'wait'
        this.setState({ submitting: true });

        // Add the wait cursor class to the body element
        document.body.classList.add('wait-cursor');

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ Data: encryptedPayload })
        };

        try {
            const response = await fetch(`${API_URL}/api/CrmAuthSignin`, requestOptions);
            const data = await response.json();
            if (data.responseCode === "00") {
                // Fetch additional data using data.loyaltyMemberId
                const srId = data.loyaltyMemberId;
                const token = data.token;

                // Get Member Info -> start
                const payloadCrmMemberGetInfo = { UserId: srId, RefLang: 'th', Timestamp: timestamp };
                const encryptedPayloadCrmMemberGetInfo = encryptData(payloadCrmMemberGetInfo);

                const requestOptionsCrmMemberGetInfo = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({ Data: encryptedPayloadCrmMemberGetInfo })
                };
                const dataResponseCrmMemberGetInfo = await fetch(`${API_URL}/api/CrmMemberGetInfo`, requestOptionsCrmMemberGetInfo);
                const additionalDataCrmMemberGetInfo = await dataResponseCrmMemberGetInfo.json();
                // Get Member Info -> end

                // CrmAuthGetRewardLevel -> start
                const payload2 = { LoyaltyMemberId: srId, RefLang: 'th', Timestamp: timestamp };
                const encryptedPayload2 = encryptData(payload2);
                const requestOptions2 = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({ Data: encryptedPayload2 })
                };

                const additionalDataResponse = await fetch(`${API_URL}/api/CrmAuthGetRewardLevel`, requestOptions2);
                const additionalData = await additionalDataResponse.json();
                // CrmAuthGetRewardLevel -> end

                additionalData.email = username;
                additionalData.firstName = additionalDataCrmMemberGetInfo.firstName;

                document.body.classList.remove('wait-cursor'); // Remove the wait cursor class

                data.rewardLevel = additionalData.rewardLevel;
                data.firstName = additionalDataCrmMemberGetInfo.firstName;
                data.email = username;
                sessionStorage.setItem('userData', JSON.stringify(data));

                if (additionalData.rewardLevel === "Green") {
                    window.location.replace("/thuserprofiledelete");
                }
                else {
                    window.location.replace("/thuserprofiledelete");
                }
            }
            else {
                this.setState({ loginstatus: data.responseMessage });

                document.body.classList.remove('wait-cursor'); // Remove the wait cursor class
                // Enable the submit button and change cursor back to 'auto' after form submission completes
                this.setState({ submitting: false });
            }
        } catch (error) {
            console.error('Error during api:', error);
            // Handle error appropriately, e.g., show an error message to the user

            document.body.classList.remove('wait-cursor'); // Remove the wait cursor class
            // Enable the submit button and change cursor back to 'auto' after form submission completes
            this.setState({ submitting: false });
        } finally {

        }
    };

    render() {
        const { username, password, submitting, loginstatus, requireMsgEmail, requireMsgPwd } = this.state;

        return (            
            <>
                <div className="container">
                    <main className="box-max-width shadow">
                        <img className="img-fluid" src={require("./media/banner-header-data-request-form.webp")} alt="Data Subject Request Form" />
                        <div className="row justify-content-center">
                            <div className="col-md-6 col-lg-6 pb-5">
                                <div className="text-center p-2">
                                    <h2 className="pt-5 pb-5" style={{ color: '#233c36' }}>คำร้องขอใช้สิทธิ <br /> ของเจ้าของข้อมูลส่วนบุคคล</h2>
                                    <form className="form-sr px-1 px-md-4" onSubmit={this.handleSubmit}>
                                        <div className="mb-4">
                                            <label htmlFor="username" className="form-label d-none">Email address</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="*อีเมล"
                                                name="username"
                                                value={username}
                                                onChange={this.handleChange}
                                            />
                                            <div className="invalid-feedback" style={{ display: 'block' }}>{requireMsgEmail}</div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="password" className="form-label d-none">Password</label>
                                            <div className="input-group mb-3">
                                                <input
                                                    type="password"
                                                    className="form-control border-end-0"
                                                    placeholder="*รหัสผ่าน"
                                                    name="password"
                                                    value={password}
                                                    onChange={this.handleChange}
                                                />
                                                <span className="input-group-text">
                                                    <i toggle="input[name='password']" className="fa fa-fw fa-eye-slash toggle-password" />
                                                </span>
                                                <div className="invalid-feedback" style={{ display: 'block' }}>{requireMsgPwd}</div>
                                            </div>
                                        </div>
                                        <div className="mb-5">
                                            <p>Forgot your <Link to="/forgotpwd" target="_blank">password</Link>?</p>
                                        </div>
                                        <div className="text-center">
                                            <button type="submit" className="btn btn-primary rounded-pill px-4" disabled={submitting}>
                                                {submitting ? 'Sign in...' : 'Sign in'}
                                            </button>
                                        </div>
                                        <div className="text-alert-2">
                                            {loginstatus}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>

            </>
        );
    }
}