import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { EnNavHeader } from './EnNavHeader';
import { EnNavFooter } from './EnNavFooter';
import EnUserMenu from './EnUserMenu';

import { getClientId } from './utils/analyticsclientIdUtils';
import { encryptData } from './utils/encryptionUtils';
import { API_URL, WEB_URL } from './utils/constants'

class EnUserCardReloadClass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: null, // Initialize userData state to null
            parentState: this.props.location.state,
            canSubmitState: true,
            canSubmitMsg: '',
            bblCardlist: [],
            payType: '0',
            amount: '0',
            billName: '',
            billAddress: '',
            reloadStep: '1', // declare reloadStep var to choose from to show
            step2StatusMsg: '',
            step2Obj: [],
            balanceAfter: 0,
            requireMsg01: '',
            requireMsg02: '',
            requireMsg03: ''
        };
    }

    componentDidMount() {
        // Retrieve data from session storage
        const userDataFromStorage = sessionStorage.getItem('userData');

        // Parse the data if it exists and set it to the component state
        if (userDataFromStorage) {
            const parsedUserData = JSON.parse(userDataFromStorage);
            this.setState({ userData: parsedUserData, billName: parsedUserData.firstName }, () => {
                if (this.state.userData && this.state.userData.loyaltyMemberId) {
                    this.callApiQueryBBL(this.state.userData.loyaltyMemberId);
                }
            });
        }

        {
            // Google analytic
            const documentPath = "Card reload"; // Gets the current URL path
            const clientId = getClientId(); // Use the getClientId function

            // Push data to the GTM dataLayer
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'pageview',
                page: documentPath,
                clientId: clientId,
            });
        }
        window.scrollTo(0, 0);
    }

    async callApiQueryBBL(userId) {
        // Generate Unix timestamp
        const timestamp = Math.floor(Date.now() / 1000);

        const payload = { LoyaltyMemberId: userId, RefLang: "en", Timestamp: timestamp };
        const encryptedPayload = encryptData(payload);

        const token = this.state.userData.token;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ Data: encryptedPayload })
        };

        try {
            const response = await fetch(`${API_URL}/api/CrmReloadCheckMemberPay`, requestOptions);
            const data = await response.json();
            if (data && Array.isArray(data.returnObj)) {
                this.setState({
                    bblCardlist: data.returnObj
                });
            }
            else {
                console.info('No data:');
            }
        } catch (error) {
            console.error('API call error :', error);
        }
    }

    async callApiCardreload(loyaltyMemberId, cardNumber, reloadAmount, payType, billName, billAddress, refLang) {
        // Generate Unix timestamp
        const timestamp = Math.floor(Date.now() / 1000);

        const payload = { LoyaltyMemberId: loyaltyMemberId, CardNumber: cardNumber, Amount: reloadAmount, PayType: payType, BillName: billName, BillAddress: billAddress, RefLang: refLang, Timestamp: timestamp };
        const encryptedPayload = encryptData(payload);

        const token = this.state.userData.token;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ Data: encryptedPayload })
        };

        try {
            // Add the wait cursor class to the body element
            document.body.classList.add('wait-cursor');

            const response = await fetch(`${API_URL}/api/CrmReloadPrepareVar`, requestOptions);
            const data = await response.json();
            if (data && data.responseCode === 'OK') {
                this.setState({
                    canSubmitState: false,
                    canSubmitMsg: '',
                    reloadStep: '2',
                    step2Obj: data.returnObj,
                    step2StatusMsg: data.statusMsg
                });
            }
            else {
                //some error here
                this.setState({
                    canSubmitState: true,
                    canSubmitMsg: data.statusMsg,
                    reloadStep: '1'
                });
            }
        } catch (error) {
            console.error('API call error:', error);
        }
        finally {
            document.body.classList.remove('wait-cursor'); // Remove the wait cursor class
        }
    }

    handleCardReload(card, amount, payType, billName, billAddress) {
        const card1Balance = parseFloat(card.cardBalance.replace(/,/g, ''));
        let reloadAmount = parseFloat(amount);
        if (reloadAmount === 0) {
            this.setState({
                requireMsg01: 'Enter amount'
            });
            return;
        } else if (reloadAmount < 300 || reloadAmount > 20000) {
            this.setState({
                requireMsg01: 'Please input a valid amount between ฿300-฿20,000'
            });
            return;
        }

        if (billName === "") {
            this.setState({
                requireMsg02: 'Enter Name'
            });
            return;
        }

        if (billAddress === "") {
            this.setState({
                requireMsg03: 'Enter Billing Address'
            });
            return;
        }

        if (reloadAmount === 0 || billName === "" || billAddress === "") {
            //some error here
            this.setState({
                canSubmitState: true,
                canSubmitMsg: ''
            });
            return;
        }
        else {
            this.setState({
                canSubmitMsg: ''
            });
        }

        if (card1Balance + reloadAmount > 20000) {
            this.setState({
                canSubmitMsg: 'Card balance will exceed ฿20,000!!'
            });
            return;
        }
        else {
            this.setState({ balanceAfter: card1Balance + reloadAmount });
            this.callApiCardreload(this.state.userData.loyaltyMemberId, card.cardNumber, reloadAmount, payType, billName, billAddress, "en");
        }
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleChangeRadio = e => {
        this.setState({
            amount: e.target.value
        });
    };

    render() {
        const { userData, parentState, canSubmitState, canSubmitMsg, bblCardlist, payType, amount, billName, billAddress, reloadStep, step2Obj, step2StatusMsg, balanceAfter, requireMsg01, requireMsg02, requireMsg03 } = this.state;
        const selectedCard = parentState.selectedCard;
        const selectedCardFromlist = parentState.selectedCardFromlist;

        let selectedCardNumber, selectedcardBalance, selectedCardStatus, selectedcardImg;
        if (selectedCard) {
            selectedcardBalance = selectedCard.cardBalance;
        }
        if (selectedCardFromlist) {
            selectedCardNumber = selectedCardFromlist.cardNumber;
            selectedCardStatus = selectedCardFromlist.cardStatusText;
            selectedcardImg = selectedCardFromlist.cardImg;
        }

        let cancelUrl, failUrl, successUrl, EnabledMemberPay;
        if (step2Obj) {
            if (step2StatusMsg === "") {
                EnabledMemberPay = "F";
            }
            else {
                EnabledMemberPay = "T";
            }

            cancelUrl = `${WEB_URL}/usercardreload_cancel/?CardNumber=${step2Obj.orderRef3}&tranid=${step2Obj.runid}`;
            failUrl = `${WEB_URL}/usercardreload_fail/?CardNumber=${step2Obj.orderRef3}&tranid=${step2Obj.runid}`;
            successUrl = `${WEB_URL}/usercardreload_success/?CardNumber=${step2Obj.orderRef3}&tranid=${step2Obj.runid}`;
        }

        return (
            <>
                <EnNavHeader />

                {/* Render the user data */}
                {userData && parentState && (
                    <main id="main">
                        <section id="profile_section" className="profileBanner keyboardFocus" aria-label="Profile" tabIndex={-1}>
                            <EnUserMenu />
                        </section>
                        <section className="section-dashboard overflow-hidden pb-5">
                            {reloadStep === '1' && (
                                /*reloadStep = 1 show form prepare*/
                                <div className="container mt-4">
                                    <div className="row mb-5 px-2">
                                        <div className="col-md-12">
                                            <h2 className="h4 fw-semibold my-4">Add money</h2>
                                            <form className="form-sr">
                                                <div className="row mb-4 g-3 align-items-start">
                                                    <div className="col-md-4">
                                                        <img src={`media/rewards/cards/${selectedcardImg}`} alt={selectedCardNumber} className="img-fluid" />
                                                    </div>
                                                    <div className="col-md-6 ps-4">
                                                        <h4 className="h5 fw-semibold">({selectedCardNumber})</h4>
                                                        <h4 className="h5 fw-semibold mb-3"><span className="text-green-1 fw-semibold">฿ {selectedcardBalance}</span></h4>
                                                        <p className="fw-semibold">Status : <span>{selectedCardStatus}</span></p>
                                                        <div className="row mb-4">
                                                            <div className="col-md-9 mb-3">
                                                                <p><span className="note">Amount</span><br />
                                                                    <input type="number" className="form-control" name="amount" onChange={this.handleChange} value={amount} /></p>
                                                                <p className="mb-2">The value of a card should not exceed ฿20,000</p>
                                                                <p className="box_selector mb-0">
                                                                    <label>
                                                                        <input type="radio" name="Amount01" value="300" onChange={this.handleChangeRadio} />
                                                                        <span className="reload_amount_num">300</span>
                                                                    </label>
                                                                    <label>
                                                                        <input type="radio" name="Amount01" value="500" onChange={this.handleChangeRadio} />
                                                                        <span className="reload_amount_num">500</span>
                                                                    </label>
                                                                    <label>
                                                                        <input type="radio" name="Amount01" value="1000" onChange={this.handleChangeRadio} />
                                                                        <span className="reload_amount_num">1,000</span>
                                                                    </label>
                                                                    <label>
                                                                        <input type="radio" name="Amount01" value="3000" onChange={this.handleChangeRadio} />
                                                                        <span className="reload_amount_num">3,000</span>
                                                                    </label>
                                                                    <label>
                                                                        <input type="radio" name="Amount01" value="5000" onChange={this.handleChangeRadio} />
                                                                        <span className="reload_amount_num">5,000</span>
                                                                    </label>
                                                                    <label>
                                                                        <input type="radio" name="Amount01" value="10000" onChange={this.handleChangeRadio} />
                                                                        <span className="reload_amount_num">10,000</span>
                                                                    </label>
                                                                </p>
                                                                <div className="text-alert-2">
                                                                    {requireMsg01}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 mb-3">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="radio" name="payType" defaultValue="0" defaultChecked onClick={this.handleChange} />
                                                                    <label className="form-check-label">
                                                                        <img src="media/rewards/Icon core-credit-card.svg" alt="Use other credit / debit cards" className="img-fluid me-2" style={{ height: '18px' }} />Use other credit / debit cards
                                                                    </label>
                                                                </div>
                                                                {bblCardlist.length > 0 && (
                                                                    <>
                                                                        {bblCardlist.map((card, index) => (
                                                                            <div className="form-check" key={index}>
                                                                                <input className="form-check-input" type="radio" name="payType" defaultValue={card.statictoken} onClick={this.handleChange} />
                                                                                <label className="form-check-label">
                                                                                    <img src="media/rewards/Icon core-credit-card.svg" alt="Use other credit / debit cards" className="img-fluid me-2" style={{ height: '18px' }} /> {card.account}
                                                                                </label>
                                                                            </div>
                                                                        ))}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col">
                                                                <span className="note">
                                                                    Address for Receipt (This is not a tax invoice)<br />
                                                                    Name:
                                                                </span><br />
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    name="billName"
                                                                    value={billName}
                                                                    onChange={this.handleChange}
                                                                />
                                                            </div>
                                                            <div className="text-alert-2">
                                                                {requireMsg02}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <span className="note">
                                                                    Billing Address:
                                                                </span><br />
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    name="billAddress"
                                                                    value={billAddress}
                                                                    onChange={this.handleChange}
                                                                />
                                                            </div>
                                                            <div className="text-alert-2">
                                                                {requireMsg03}
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col">
                                                                {canSubmitState && (
                                                                    <button type="button" className="btn btn-primary btn-slim rounded-pill" onClick={() => this.handleCardReload(selectedCard, amount, payType, billName, billAddress)}>
                                                                        Add money
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col text-alert-2">
                                                                <span>{canSubmitMsg}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {reloadStep === '2' && (
                                /*reloadStep = 1 show form bbl*/
                                <div className="container mt-4">
                                    <div className="row mb-5 px-2">
                                        <div className="col-md-12">
                                            <h2 className="h4 fw-semibold my-4">Add money</h2>
                                            <form className="form-sr" method="post" action="https://ipay.bangkokbank.com/b2c/eng/payment/payForm.jsp">
                                                <input type="hidden" name="orderRef" value={step2Obj.runid} />
                                                <input type="hidden" name="amount" value={step2Obj.transactionamount} />
                                                <input type="hidden" name="currCode" value="764" />
                                                <input type="hidden" name="lang" value="E" />
                                                <input type="hidden" name="cancelUrl" value={cancelUrl} />
                                                <input type="hidden" name="failUrl" value={failUrl} />
                                                <input type="hidden" name="successUrl" value={successUrl} />
                                                <input type="hidden" name="merchantId" value="2793" />
                                                <input type="hidden" name="payType" value="N" />
                                                <input type="hidden" name="payMethod" value="ALL" />
                                                <input type="hidden" name="remark" value="Reload by customer" />
                                                <input type="hidden" name="orderRef1" value={step2Obj.orderRef1} />
                                                <input type="hidden" name="orderRef2" value={step2Obj.orderRef2} />
                                                <input type="hidden" name="orderRef3" value={step2Obj.orderRef3} />
                                                <input type="hidden" name="orderRef4" value={step2Obj.orderRef4} />
                                                <input type="hidden" name="orderRef5" value={step2Obj.orderRef5} />
                                                <input type="hidden" name="deviceMode" value="pc" />
                                                <input type="hidden" name="memberPay_service" value={EnabledMemberPay} />
                                                <input type="hidden" name="memberPay_memberId" value={step2Obj.orderRef5} />
                                                {EnabledMemberPay === "T" && (
                                                    <input type="hidden" name="memberPay_token" value={step2Obj.statusMsg} />
                                                )}

                                                <div className="row mb-4 g-3 align-items-start">
                                                    <div className="col-md-4">
                                                        <img src={`media/rewards/cards/${selectedcardImg}`} alt={selectedCardNumber} className="img-fluid" />
                                                    </div>
                                                    <div className="col-md-6 ps-4">
                                                        <h4 className="h5 fw-semibold">({selectedCardNumber})</h4>
                                                        <h4 className="h5 fw-semibold mb-3"><span className="text-green-1 fw-semibold">฿ {selectedcardBalance}</span></h4>
                                                        <p className="fw-semibold">Status : <span>{selectedCardStatus}</span></p>
                                                        <p className="fw-semibold">Reload Amount : <span>{amount}</span></p>
                                                        <p className="text-green-1 fw-semibold">Balance after Reload : <span>฿ {balanceAfter}</span></p>
                                                        <div className="row">
                                                            <div className="col">
                                                                <button type="submit" className="btn btn-primary btn-slim rounded-pill">
                                                                    Next
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </section>
                    </main>

                )}

                <EnNavFooter />
            </>
        );
    }
}

export const EnUserCardReload = withRouter(EnUserCardReloadClass);