import React, { Component } from 'react';

import { ThNavHeader } from './ThNavHeader';
import { ThNavFooter } from './ThNavFooter';
import ThUserMenu from './ThUserMenu';

import { getClientId } from './utils/analyticsclientIdUtils';

export class ThUserProfileEmailThx extends Component {
    static displayName = ThUserProfileEmailThx.name;

    constructor(props) {
        super(props);
        this.state = {
            userData: null // Initialize userData state to null
        };
    }

    componentDidMount() {
        // Retrieve data from session storage
        const userDataFromStorage = sessionStorage.getItem('userData');

        // Parse the data if it exists and set it to the component state
        if (userDataFromStorage) {
            this.setState({ userData: JSON.parse(userDataFromStorage) });
        }

        {
            // Google analytic
            const documentPath = "Blank"; // Gets the current URL path
            const clientId = getClientId(); // Use the getClientId function

            // Push data to the GTM dataLayer
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'pageview',
                page: documentPath,
                clientId: clientId,
            });
        }
    }

    render() {
        const { userData } = this.state;

    return (
        <>
            <ThNavHeader />

            {/* Render the user data */}
            {userData && (
                <main id="main">
                    <section id="profile_section" className="profileBanner keyboardFocus" aria-label="Profile" tabIndex={-1}>
                        <ThUserMenu />
                    </section>
                    <section className="section-dashboard overflow-hidden pb-5">
                        <div className="container mt-4">
                            <div className="row mb-5 px-2">
                                <div className="col-md-8">
                                    <h2 className="h4 fw-semibold mt-4">เปลี่ยนชื่อผู้ใช้</h2>
                                    <p className="mb-4">ข้อมูลบัญชีและข้อมูลส่วนตัว</p>
                                    <form className="form-sr">
                                        <h5>โปรดตรวจสอบอีเมลของคุณและคลิกลิงก์ยืนยัน</h5>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>                
            )}

            <ThNavFooter />
      </>
    );
  }
}
