import React, { Component } from 'react';

import { EnNavHeader } from './EnNavHeader';
import { EnNavFooter } from './EnNavFooter';
import EnUserMenu from './EnUserMenu';

import { getClientId } from './utils/analyticsclientIdUtils';

export class EnUserProfileEmailThx extends Component {
    static displayName = EnUserProfileEmailThx.name;

    constructor(props) {
        super(props);
        this.state = {
            userData: null // Initialize userData state to null
        };
    }

    componentDidMount() {
        // Retrieve data from session storage
        const userDataFromStorage = sessionStorage.getItem('userData');

        // Parse the data if it exists and set it to the component state
        if (userDataFromStorage) {
            this.setState({ userData: JSON.parse(userDataFromStorage) });
        }

        {
            // Google analytic
            const documentPath = "Blank"; // Gets the current URL path
            const clientId = getClientId(); // Use the getClientId function

            // Push data to the GTM dataLayer
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'pageview',
                page: documentPath,
                clientId: clientId,
            });
        }
    }

    render() {
        const { userData } = this.state;

    return (
        <>
            <EnNavHeader />

            {/* Render the user data */}
            {userData && (
                <main id="main">
                    <section id="profile_section" className="profileBanner keyboardFocus" aria-label="Profile" tabIndex={-1}>
                        <EnUserMenu />
                    </section>
                    <section className="section-dashboard overflow-hidden pb-5">
                        <div className="container mt-4">
                            <div className="row mb-5 px-2">
                                <div className="col-md-8">
                                    <h2 className="h4 fw-semibold mt-4">Change My Username</h2>
                                    <p className="mb-4">Account and personal information</p>
                                    <form className="form-sr">
                                        <h5>Please check your email and click on <b>verification</b> link.</h5>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>                
            )}

            <EnNavFooter />
      </>
    );
  }
}
