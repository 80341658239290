import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

class ThUserMenu extends Component {
    static displayName = ThUserMenu.name;

    render() {
        const { location } = this.props;
        const pathname = location ? location.pathname : ''; // Handling if location is undefined

        const userData = JSON.parse(sessionStorage.getItem('userData'));
        const userLevel = userData ? userData.rewardLevel : null;
        var css01 = "";
        if (userLevel === "Green") {
            css01 = "profileBanner__links green-bl mt-2";
        }
        else {
            css01 = "profileBanner__links gold-bl mt-2";
        }

        return (
            <div className="container">
                <div className="col-topbar-profile">
                    <div className="profileBanner__container">
                        <ul className={css01}>
                            {userLevel === "Green" && (
                                <li><Link to="/thuserdashboard" className={pathname === '/thuserdashboard' ? 'profileBanner--activeLink' : ''}>บัญชีของคุณ</Link></li>
                            )}
                            {userLevel === "Gold" && (
                                <li><Link to="/thuserdashboardgold" className={pathname === '/thuserdashboardgold' ? 'profileBanner--activeLink' : ''}>บัญชีของคุณ</Link></li>
                            )}

                            <li><Link to="/thuserstartransaction" className={pathname === '/thuserstartransaction' ? 'profileBanner--activeLink' : ''}>ประวัติดาวสะสมและสิทธิประโยชน์ของคุณ</Link></li>
                            <li><Link to="/thusercard" className={pathname.startsWith('/thusercard') ? 'profileBanner--activeLink' : ''}>บัตรสตาร์บัคส์</Link></li>

                            {userLevel === "Green" && (
                                <li><Link to="/thuserreward" className={pathname === '/thuserreward' ? 'profileBanner--activeLink' : ''}>สิทธิประโยชน์</Link></li>
                            )}
                            {userLevel === "Gold" && (
                                <li><Link to="/thuserrewardgold" className={pathname === '/thuserrewardgold' ? 'profileBanner--activeLink' : ''}>สิทธิประโยชน์</Link></li>
                            )}

                            <li><Link to="/thuserprofile" className={pathname.startsWith('/thuserprofile') ? 'profileBanner--activeLink' : ''}>ข้อมูลส่วนตัว</Link></li>
                            <li><Link to="/logout">ออกจากระบบ</Link></li>
                        </ul>
                    </div>
                    <div className="msr3_logo msr3_usen_logo" />
                </div>
            </div>
        );
    }
}

export default withRouter(ThUserMenu); // Wrapping with withRouter
