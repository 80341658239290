import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { ThNavHeader } from './ThNavHeader';
import { ThNavFooter } from './ThNavFooter';
import ThUserMenu from './ThUserMenu';

import { getClientId } from './utils/analyticsclientIdUtils';
import { encryptData } from './utils/encryptionUtils';
import { API_URL } from './utils/constants';

export class ThUserProfile extends Component {
    static displayName = ThUserProfile.name;

    constructor(props) {
        super(props);
        this.state = {
            userData: null, // Initialize userData state to null
            userInfo: null,
            birthdayd: '',
            birthdaym: '',
            submitting: false,
            salutation: '',
            firstname: '',
            lastname: '',
            firstnameth: '',
            lastnameth: '',
            mobilephone: '',
            submittingProfile: false,
            successMsg: '',
            failMsg: '',
            failMsg2: '',
            registerMsgFname: '',
            registerMsgLname: '',
            actionSuccess: false
        };
    }

    componentDidMount() {
        // Retrieve data from session storage
        const userDataFromStorage = sessionStorage.getItem('userData');

        // Parse the data if it exists and set it to the component state
        if (userDataFromStorage) {
            const parsedUserData = JSON.parse(userDataFromStorage);
            this.setState({ userData: parsedUserData }, () => {
                // After setting the state, make the API call if userData is available
                if (this.state.userData && this.state.userData.loyaltyMemberId) {
                    this.callApiWithUserId(this.state.userData.loyaltyMemberId);
                }
            });
        }

        {
            // Google analytic
            const documentPath = "TH Profile"; // Gets the current URL path
            const clientId = getClientId(); // Use the getClientId function

            // Push data to the GTM dataLayer
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'pageview',
                page: documentPath,
                clientId: clientId,
            });
        }
    }

    async callApiWithUserId(userId) {
        // Add the wait cursor class to the body element
        document.body.classList.add('wait-cursor');

        // Generate Unix timestamp
        const timestamp = Math.floor(Date.now() / 1000);

        // Prepare and encrypt the payload
        const payload = { UserId: userId, RefLang: 'th', Timestamp: timestamp };
        const encryptedPayload = encryptData(payload);

        const token = this.state.userData.token;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ Data: encryptedPayload })
        };

        try {
            const response = await fetch(`${API_URL}/api/CrmMemberGetInfo`, requestOptions);
            const data = await response.json();
            if (data) {
                data.salutation = data.salutation == null ? "" : data.salutation;
                this.setState({ userInfo: data, salutation: data.salutation, firstname: data.firstName, lastname: data.lastName, firstnameth: data.firstNameTH, lastnameth: data.lastNameTH, mobilephone: data.mobilePhone });
            }
            else {
                console.error('Error data:');
            }
        } catch (error) {
            console.error('API call error :', error);
        } finally {
            document.body.classList.remove('wait-cursor'); // Remove the wait cursor class
        }
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });

        if (e.target.name === "birthdaym") {
            this.setState({
                birthdayd: ''
            });
        }
    };

    handleSubmitProfile = async e => {
        e.preventDefault();
        const { salutation, firstname, lastname, firstnameth, lastnameth, mobilephone, userInfo } = this.state;
        const birthday = userInfo.birthday;

        let canSubmit = true;

        if (firstname === "") {
            this.setState({ registerMsgFname: "กรุณากรอก ชื่อ (อังกฤษ)" });
            canSubmit = false;
        }

        if (lastname === "") {
            this.setState({ registerMsgLname: "กรุณากรอก นามสกุล (อังกฤษ)" });
            canSubmit = false;
        }

        if (canSubmit == false) {
            return;
        }

        // Generate Unix timestamp
        const timestamp = Math.floor(Date.now() / 1000);

        // Prepare and encrypt the payload
        const payload = {
            LoyaltyMemberId: userInfo.loyaltyMemberId,
            Salutation: salutation,
            FirstName: firstname,
            LastName: lastname,
            FirstNameTH: firstnameth,
            LastNameTH: lastnameth,
            THNationalID: userInfo.thNationalID,
            Passport: userInfo.passport,
            MobilePhone: mobilephone,
            Birthday: birthday,
            Address1: userInfo.address1,
            Address2: userInfo.address2,
            Address3: userInfo.address3,
            City: userInfo.city,
            State: userInfo.state,
            PostalCode: userInfo.postalCode,
            Country: userInfo.country,
            OptInEmail: userInfo.optInEmail,
            RefLang: 'th',
            Timestamp: timestamp
        };
        const encryptedPayload = encryptData(payload);

        // Prevent double-clicking by disabling the submit button and changing cursor to 'wait'
        this.setState({ submittingProfile: true });

        // Add the wait cursor class to the body element
        document.body.classList.add('wait-cursor');

        const token = this.state.userData.token;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ Data: encryptedPayload })
        };

        try {
            const response = await fetch(`${API_URL}/api/CrmMemberUpdateProfile`, requestOptions);
            const data = await response.json();
            if (data && data.statusCode === 'OK') {
                this.setState({
                    successMsg: 'ข้อมูลส่วนตัวของคุณถูกแก้ไขเรียบร้อยแล้ว',
                    actionSuccess: true
                });
            }
            else {
                //some error here
                this.setState({
                    failMsg: data.statusMsg,
                    actionSuccess: false
                });
            }
        } catch (error) {
            console.error('Error during update profile:', error);
            // Handle error appropriately, e.g., show an error message to the user
        } finally {
            // Enable the submit button and change cursor back to 'auto' after form submission completes
            this.setState({ submittingProfile: false });
            document.body.classList.remove('wait-cursor'); // Remove the wait cursor class
        }
    };

    handleSubmit = async e => {
        e.preventDefault();
        const { birthdayd, birthdaym, userInfo } = this.state;
        const birthday = "1900-" + birthdaym + "-" + birthdayd;

        if (!birthdaym || birthdaym === "") {
            this.setState({
                failMsg2: 'กรุณาระบุวันเกิดที่ถูกต้อง'
            });
            return;
        }

        if (!birthdayd || birthdayd === "") {
            this.setState({
                failMsg2: 'กรุณาระบุวันเกิดที่ถูกต้อง'
            });
            return;
        }

        // Generate Unix timestamp
        const timestamp = Math.floor(Date.now() / 1000);

        // Prepare and encrypt the payload
        const payload = {
            LoyaltyMemberId: userInfo.loyaltyMemberId,
            Salutation: userInfo.salutation,
            FirstName: userInfo.firstName,
            LastName: userInfo.lastName,
            FirstNameTH: userInfo.firstNameTH,
            LastNameTH: userInfo.lastNameTH,
            THNationalID: userInfo.thNationalID,
            Passport: userInfo.passport,
            MobilePhone: userInfo.mobilePhone,
            Birthday: birthday,
            Address1: userInfo.address1,
            Address2: userInfo.address2,
            Address3: userInfo.address3,
            City: userInfo.city,
            State: userInfo.state,
            PostalCode: userInfo.postalCode,
            Country: userInfo.country,
            OptInEmail: userInfo.optInEmail,
            RefLang: 'th',
            Timestamp: timestamp
        };
        const encryptedPayload = encryptData(payload);

        // Prevent double-clicking by disabling the submit button and changing cursor to 'wait'
        this.setState({ submitting: true });

        // Add the wait cursor class to the body element
        document.body.classList.add('wait-cursor');

        const token = this.state.userData.token;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ Data: encryptedPayload })
        };

        try {
            const response = await fetch(`${API_URL}/api/CrmMemberUpdateProfile`, requestOptions);
            const data = await response.json();
            if (data) {
                window.location.replace("/userprofile");
            }
        } catch (error) {
            console.error('Error during update profile:', error);
            // Handle error appropriately, e.g., show an error message to the user
        } finally {
            // Enable the submit button and change cursor back to 'auto' after form submission completes
            this.setState({ submitting: false });
            document.body.classList.remove('wait-cursor'); // Remove the wait cursor class
        }
    };

    formatDate(dateString) {
        const date = new Date(dateString);
        return date.toLocaleDateString('th-TH', {
            day: '2-digit',
            month: 'short',
            year: 'numeric'
        });
    }

    render() {
        const { userInfo, submitting, birthdayd, birthdaym, submittingProfile, salutation, firstname, lastname, firstnameth, lastnameth, mobilephone, successMsg, failMsg, registerMsgFname, registerMsgLname, failMsg2 } = this.state;

        const getDaysInMonth = (month) => {
            const daysInMonth = {
                '01': 31, // January
                '02': 29, // February
                '03': 31, // March
                '04': 30, // April
                '05': 31, // May
                '06': 30, // June
                '07': 31, // July
                '08': 31, // August
                '09': 30, // September
                '10': 31, // October
                '11': 30, // November
                '12': 31, // December
            };

            return daysInMonth[month] || 31; // Default to 31 if month is not selected
        };

        const days = Array.from({ length: getDaysInMonth(birthdaym) }, (_, i) => i + 1);

        return (
            <>
                <ThNavHeader />

                {/* Render the user data */}
                {userInfo && (
                    <main id="main">
                        <section id="profile_section" className="profileBanner keyboardFocus" aria-label="Profile" tabIndex={-1}>
                            <ThUserMenu />
                        </section>
                        <section className="section-dashboard overflow-hidden pb-5">
                            <div className="container mt-4">
                                <div className="row mb-5 px-2">
                                    <div className="col-md-12">
                                        <h3 className="fw-semibold mt-4">ข้อมูลส่วนตัว</h3>
                                        <form className="form-sr">
                                            <div className="row">
                                                <div className="col-md-4 mt-3 mb-3">
                                                    <label className="form-label">คำนำหน้าชื่อ (ไม่จำเป็นต้องระบุ)</label>
                                                    <select className="form-select" name="salutation"
                                                        onChange={this.handleChange}
                                                        value={salutation}>
                                                        <option value="">กรุณาเลือก</option>
                                                        <option value="Mr.">นาย</option>
                                                        <option value="Mrs.">นาง</option>
                                                        <option value="Ms">นางสาว</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4 mb-3">
                                                    <label className="form-label">ชื่อ (อังกฤษ)</label>
                                                    <input type="text" className="form-control" placeholder="ชื่อ (ภาษาอังกฤษ)" name="firstname" onChange={this.handleChange} defaultValue={firstname} />
                                                    <div className="text-start mt-1 text-alert-2">
                                                        {registerMsgFname}
                                                    </div>
                                                </div>
                                                <div className="col-md-4 mb-3">
                                                    <label className="form-label">นามสกุล (อังกฤษ)</label>
                                                    <input type="text" className="form-control" placeholder="นามสกุล (อังกฤษ)" name="lastname" onChange={this.handleChange} defaultValue={lastname} />
                                                    <div className="text-start mt-1 text-alert-2">
                                                        {registerMsgLname}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4 mb-3">
                                                    <label className="form-label">ชื่อ (ไม่จำเป็นต้องระบุ)</label>
                                                    <input type="text" className="form-control" placeholder="ชื่อ (ไม่จำเป็นต้องระบุ)" name="firstnameth" onChange={this.handleChange} defaultValue={firstnameth} />
                                                </div>
                                                <div className="col-md-4 mb-3">
                                                    <label className="form-label">นามสกุล (ไม่จำเป็นต้องระบุ)</label>
                                                    <input type="text" className="form-control" placeholder="นามสกุล (ไม่จำเป็นต้องระบุ)" name="lastnameth" onChange={this.handleChange} defaultValue={lastnameth} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4 mb-3">
                                                    <label className="form-label">เบอร์โทรศัพท์ (ไม่จำเป็นต้องระบุ)</label>
                                                    <input type="text" className="form-control" placeholder="เบอร์โทรศัพท์ (ไม่จำเป็นต้องระบุ)" name="mobilephone" onChange={this.handleChange} defaultValue={mobilephone} />
                                                </div>
                                            </div>
                                            <div>
                                                <button type="button" className="btn btn-primary rounded-pill px-3" disabled={submittingProfile} onClick={this.handleSubmitProfile}>
                                                    {submittingProfile ? 'ยืนยันข้อมูล...' : 'ยืนยันข้อมูล'}
                                                </button>
                                                <div className="text-start text-green-1 pt-2">
                                                    {successMsg}
                                                </div>
                                                <div className="text-start text-alert-2">
                                                    {failMsg}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 mb-3">
                                                    <hr className="my-3" />
                                                </div>
                                            </div>
                                            <p className="text-black">คุณสามารถคลิกเพื่อเปลี่ยนชื่อบัญชีและรหัสผ่านของคุณ</p>
                                            <div className="row">
                                                <div className="col-md-8 mb-3">
                                                    <label className="form-label">ชื่อบัญชี</label>
                                                    <div className="row">
                                                        <div className="col-md-7 mb-2">
                                                            <input type="text" className="form-control" placeholder="Username" defaultValue={userInfo.email} disabled />
                                                            <Link to="/thuserprofileemail" className="btn btn-outline-green rounded-pill px-4 text-decoration-none mt-3">เปลี่ยนชื่อผู้ใช้</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-8 mb-3">
                                                    <label className="form-label">รหัสผ่าน</label>
                                                    <div className="row">
                                                        <div className="col-md-7 mb-2">
                                                            <Link to="/thuserprofilepwd" className="btn btn-outline-green rounded-pill px-4 text-decoration-none">เปลี่ยนรหัสผ่าน</Link>
                                                        </div>
                                                        <div className="col-md-4 mb-2">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <p>คุณจะได้รับสิทธิพิเศษในวันเกิดเมื่อคุณปรับระดับเป็น โกลด์</p>
                                            <p className="h6"><strong>วันเกิดของฉัน</strong></p>
                                            <div className="row g-3">
                                                {userInfo.displayBirthday ? (
                                                    <div className="col-md-3 mb-3">
                                                        {this.formatDate(userInfo.displayBirthday).slice(0, -5)}
                                                    </div>
                                                ) : (
                                                    <>
                                                        <div className="col-md-3 mb-3">
                                                            <select name="birthdaym" value={birthdaym} onChange={this.handleChange}>
                                                                <option value="">เดือน</option>
                                                                <option value="01">ม.ค.</option>
                                                                <option value="02">ก.พ.</option>
                                                                <option value="03">มี.ค.</option>
                                                                <option value="04">เม.ย.</option>
                                                                <option value="05">พ.ค.</option>
                                                                <option value="06">มิ.ย.</option>
                                                                <option value="07">ก.ค.</option>
                                                                <option value="08">ส.ค.</option>
                                                                <option value="09">ก.ย.</option>
                                                                <option value="10">ต.ค.</option>
                                                                <option value="11">พ.ย.</option>
                                                                <option value="12">ธ.ค.</option>
                                                            </select>&nbsp;
                                                            <select name="birthdayd" value={birthdayd} onChange={this.handleChange}>
                                                                <option value="">วัน</option>
                                                                {days.map((day) => (
                                                                    <option key={day} value={day}>
                                                                        {day}
                                                                    </option>
                                                                ))}
                                                            </select>&nbsp;
                                                            <button type="button" className="btn btn-primary rounded-pill px-3" disabled={submitting} onClick={this.handleSubmit}>
                                                                {submitting ? 'Update...' : 'Update'}
                                                            </button>
                                                            <div className="text-start text-alert-2">
                                                                {failMsg2}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            <p className="small text-black">ความเป็นส่วนตัวของคุณถือเป็นสิ่งสำคัญของเรา เราทำงานอย่างหนักเพื่อให้ได้ความไว้วางใจในสิ่งนี้ หากคุณมีคำถามเกี่ยวกับความเป็นส่วนตัว ตรวจสอบได้ที่ คำชี้แจงความเป็นส่วนตัว</p>
                                            <div className="row">
                                                <div className="col-md-12 mb-3">
                                                    <hr className="mt-2 mb-3" />
                                                </div>
                                            </div>
                                            <div className="row form-check-term">
                                                <div className="col-md-12 mb-3">
                                                    <div className="text-start mt-4"><Link to="/thuserprofiledelete" className="btn btn-outline-green rounded-pill px-4 text-decoration-none">ยกเลิกบัญชีสมาชิก</Link></div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>
                )}

                <ThNavFooter />
            </>
        );
    }
}
